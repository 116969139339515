import { useState, useEffect, useContext } from "react";
import DatePicker from "#newUiComponents/commons/DatePicker";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import AddButton from "#components/utils/AddButton";
import { truncate } from "#utils";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import Autocomplete from "#components/utils/Autocomplete";
import ReactTooltip from "react-tooltip";
import { AppStateContext } from "#contexts/appState";
import { v4 as uuidv4 } from "uuid";
import CustomAlert from "#newUiComponents/commons/CustomAlert";

const WizardProductSelector = ({
  warehouses,
  selectedEntity,
  removeItem,
  onChangeInventorySearch,
  availableInventory,
  submitAddedProduct,
  getMarketplaceProducts,
  isMultiFormFactor,
  addingProductCallback,
  multipleFormFactors = true,
  productSkuBinMappings,
  lotIdSelectionEnabled,
  expiryDateSelectionEnabled,
  productSkuBinMappingsWithExpiryDate,
  lpnSelectionEnabled,
  multiAccountSupportEnabled,
  productSkuBinMappingsWithLpn,
  productSkuBinMappingsWithSerialNumber,
  productSkuBinMappingsWithPoId,
  productSkuBinMappingsWithPalletId,
  tenant,
}) => {
  const softAllocationEnabled = tenant?.settings?.softAllocationOnBatching;
  const appState = useContext(AppStateContext);
  const [inventorySearchKeyword, setInventorySearchKeyword] = useState("");
  const [addingProduct, setAddingProduct] = useState(null);

  const renderFormFactorList = (product) => {
    return appState.masterData.uomLabels
      .filter((label) => label.isActive)
      .filter(
        (item) =>
          item.name === product.baseUOM ||
          product.uomConfiguration?.map((i) => i.targetUom).includes(item.name),
      );
  };

  const renderQuantityBasedOnWarehouses = (inventory, bucket) => {
    if (selectedEntity?.warehouse && inventory.quantities?.warehouses) {
      const allQuantitiesOfFilteredWarehouses =
        inventory.quantities.warehouses.find(
          (i) => i.warehouseId === selectedEntity.warehouse,
        );
      return allQuantitiesOfFilteredWarehouses?.[bucket] || 0;
    } else {
      return inventory.quantities?.[bucket] || 0;
    }
  };

  useEffect(() => {
    addingProductCallback(addingProduct);
  }, [addingProduct]);

  useEffect(() => {
    if (addingProduct?.productId && !addingProduct?.formFactors?.length > 0) {
      setAddingProduct({
        ...addingProduct,
        formFactors: [
          {
            name: addingProduct.baseUOM ?? "Each",
            quantity: 1,
          },
        ],
      });
    }
  }, [addingProduct?.productId]);

  const changeFormFactorQuantity = (idx, e) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors[idx].quantity =
      isNaN(parseInt(e.target.value)) === false
        ? parseInt(e.target.value)
        : null;
    setAddingProduct({ ...clonedProduct });
  };

  const changeFormFactor = (idx, name) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors[idx].name = name;
    setAddingProduct({ ...clonedProduct });
  };

  const changeLotId = (idx, lotId) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors[idx].lotId = lotId;
    setAddingProduct({ ...clonedProduct });
  };

  const changeExpiryDate = (idx, expiryDate) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors[idx].expiryDate = expiryDate;
    setAddingProduct({ ...clonedProduct });
  };

  const changePalletId = (idx, palletId) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors[idx].palletId = palletId;
    setAddingProduct({ ...clonedProduct });
  };

  const changeSerialNumber = (idx, serialNumber) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors[idx].serialNumber = serialNumber;
    clonedProduct.formFactors[idx].quantity = 1;
    setAddingProduct({ ...clonedProduct });
  };

  const changePoId = (idx, poId) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors[idx].poId = poId;
    setAddingProduct({ ...clonedProduct });
  };

  const changeNestedFormFactorId = (idx, nestedFormFactorId) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors[idx].nestedFormFactorId = nestedFormFactorId;
    setAddingProduct({ ...clonedProduct });
  };

  const removeFormFactor = (idx) => {
    const clonedProduct = { ...addingProduct };
    clonedProduct.formFactors = clonedProduct.formFactors.filter(
      (item, index) => idx !== index,
    );
    setAddingProduct({ ...clonedProduct });
  };

  const getProductPrice = (productItem) => {
    const salesPrice = productItem?.salesPrice?.find(
      (i) =>
        i?.salesChannel?.toString() === selectedEntity?.customer?.toString(),
    );
    if (!salesPrice) {
      return null;
    }
    return salesPrice.salesChannelTotal / 100;
  };

  return !addingProduct ? (
    <>
      <div className="overflow-x-auto">
        {tenant?.settings?.isOutboundPlanningEnabled && (
          <div>
            <CustomAlert
              message={
                "Changing order items will reset the pack plan. Please review before proceeding."
              }
              type="info"
              id={"CustomAlertForPackPlan"}
            />
          </div>
        )}
        <table className="w-full table-auto items-start text-left">
          <thead className="bg-gray-50">
            <tr>
              <th className="text-md px-6 py-2 text-gray-500">SKU</th>
              {warehouses &&
                warehouses.findIndex(
                  (i) =>
                    i.typeOfWarehouse &&
                    i.typeOfWarehouse.includes("PREP CENTER"),
                ) !== -1 && (
                  <th className="text-md px-6 py-2 text-gray-500">ASIN</th>
                )}
              {!isMultiFormFactor && (
                <>
                  <th className="text-md px-6 py-2 text-gray-500">
                    Form Factor
                  </th>
                  <th className="text-md px-6 py-2 text-gray-500">Quantity</th>
                </>
              )}
              {isMultiFormFactor && (
                <>
                  <th className="text-md px-6 py-2 text-gray-500">
                    Quantity(Each)
                  </th>
                  <th className="text-md px-6 py-2 text-gray-500">
                    Quantity(Case)
                  </th>
                  <th className="text-md px-6 py-2 text-gray-500">
                    Quantity(Carton)
                  </th>
                  <th className="text-md px-6 py-2 text-gray-500">
                    Quantity(Pallet)
                  </th>
                </>
              )}
              {!softAllocationEnabled && lotIdSelectionEnabled && (
                <th className="text-md px-6 py-2 text-gray-500">
                  Lot/Batch ID
                </th>
              )}
              {!softAllocationEnabled && expiryDateSelectionEnabled && (
                <th className="text-md px-6 py-2 text-gray-500">Expiry Date</th>
              )}
              {
                <th className="text-md px-6 py-2 text-gray-500">
                  Serial Number
                </th>
              }
              {!softAllocationEnabled && productSkuBinMappingsWithPoId && (
                <th className="text-md px-6 py-2 text-gray-500">PO ID</th>
              )}
              {!softAllocationEnabled && lpnSelectionEnabled && (
                <th className="text-md px-6 py-2 text-gray-500">Pallet ID</th>
              )}
              {!softAllocationEnabled && lpnSelectionEnabled && (
                <th className="text-md px-6 py-2 text-gray-500">LPN</th>
              )}

              {multiAccountSupportEnabled && (
                <th className="text-md px-6 py-2 text-gray-500">Marketplace</th>
              )}
              {multiAccountSupportEnabled && (
                <th className="text-md px-6 py-2 text-gray-500">Seller</th>
              )}
              <th className="text-md px-6 py-2 text-gray-500">Action</th>
            </tr>
          </thead>
          <tbody>
            {selectedEntity.items &&
              selectedEntity.items.map((item, idx) => (
                <tr className="whitespace-nowrap">
                  <td className="text-md px-6 py-4 text-gray-500">
                    {item.sku}
                  </td>
                  {warehouses &&
                    warehouses.findIndex(
                      (i) =>
                        i.typeOfWarehouse &&
                        i.typeOfWarehouse.includes("PREP CENTER"),
                    ) !== -1 && (
                      <td className="text-md px-6 py-4 text-gray-500">
                        {buildMarketplaceHyperlink(
                          item.asin,
                          "ASIN",
                          item.source,
                        )}
                      </td>
                    )}
                  {!isMultiFormFactor && (
                    <>
                      <td className="text-md px-6 py-4 text-gray-500">
                        {item?.formFactors?.length &&
                          item?.formFactors[0]?.name}
                      </td>
                      <td className="text-md px-6 py-4 text-gray-500">
                        {item?.formFactors?.length &&
                          item?.formFactors[0]?.quantity}
                      </td>
                      {!softAllocationEnabled && lotIdSelectionEnabled && (
                        <td className="text-md px-6 py-4 text-gray-500">
                          {item?.formFactors[0]?.lotId}
                        </td>
                      )}
                      {!softAllocationEnabled && expiryDateSelectionEnabled && (
                        <td className="text-md px-6 py-4 text-gray-500">
                          {item?.formFactors[0]?.expiryDate}
                        </td>
                      )}
                      {
                        <td className="text-md px-6 py-4 text-gray-500">
                          {item?.formFactors[0]?.serialNumber}
                        </td>
                      }
                      {!softAllocationEnabled &&
                        productSkuBinMappingsWithPoId && (
                          <td className="text-md px-6 py-4 text-gray-500">
                            {item?.formFactors[0]?.poId}
                          </td>
                        )}
                      {!softAllocationEnabled && lpnSelectionEnabled && (
                        <td className="text-md px-6 py-4 text-gray-500">
                          {item?.formFactors[0]?.palletId}
                        </td>
                      )}
                      {!softAllocationEnabled && lpnSelectionEnabled && (
                        <td className="text-md px-6 py-4 text-gray-500">
                          {item?.formFactors[0]?.nestedFormFactorId}
                        </td>
                      )}
                    </>
                  )}
                  {multiAccountSupportEnabled && (
                    <td className="text-md px-6 py-4 text-gray-500">
                      {item?.marketplace}
                    </td>
                  )}
                  {multiAccountSupportEnabled && (
                    <td className="text-md px-6 py-4 text-gray-500">
                      {item?.seller}
                    </td>
                  )}
                  {isMultiFormFactor && (
                    <>
                      <td className="text-md px-6 py-4 text-gray-500">
                        {
                          item?.formFactors?.find((i) => i.name === "Each")
                            ?.quantity
                        }
                      </td>
                      <td className="text-md px-6 py-4 text-gray-500">
                        {
                          item?.formFactors?.find((i) => i.name === "Case")
                            ?.quantity
                        }
                      </td>
                      <td className="text-md px-6 py-4 text-gray-500">
                        {
                          item?.formFactors?.find((i) => i.name === "Carton")
                            ?.quantity
                        }
                      </td>
                      <td className="text-md px-6 py-4 text-gray-500">
                        {
                          item?.formFactors?.find((i) => i.name === "Pallet")
                            ?.quantity
                        }
                      </td>
                    </>
                  )}

                  <td className="text-md px-6 py-4 text-gray-500">
                    <div className="flex space-x-2">
                      <span
                        className="cursor-pointer pl-2 text-lg font-bold text-blue-500"
                        onClick={() =>
                          setAddingProduct({
                            ...item,
                            addingNew: true,
                            editing: true,
                            idx,
                          })
                        }>
                        Edit
                      </span>
                      <span
                        className="cursor-pointer pl-2 text-lg font-bold text-red-500"
                        onClick={() => {
                          removeItem(idx);
                        }}>
                        Remove
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <AddButton
        text="Add Item"
        onClick={() =>
          setAddingProduct({
            addingNew: true,
          })
        }
      />
    </>
  ) : (
    <>
      <div className="items-center space-y-4" key={0}>
        <div className="space-y-4">
          <label
            htmlFor="default-search"
            className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            Search
          </label>
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <svg
                aria-hidden="true"
                className="h-5 w-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="text-md block w-full rounded-lg border border-gray-300 bg-gray-50 p-4 pl-10 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              placeholder="Search by SKU/UPC/LPN/Name"
              onChange={(e) => {
                setInventorySearchKeyword(e.target.value);
                onChangeInventorySearch(e.target.value);
              }}
            />
          </div>
        </div>
        {inventorySearchKeyword && inventorySearchKeyword.trim() !== "" && (
          <div className="max-h-96 overflow-scroll">
            <table className="table-auto items-start text-left">
              <thead className="bg-gray-50">
                <tr>
                  <th className="text-md px-6 py-2 text-gray-500">ASIN</th>
                  <th className="text-md px-6 py-2 text-gray-500">SKU</th>
                  <th className="text-md px-6 py-2 text-gray-500">Name</th>
                  <th className="text-md px-6 py-2 text-gray-500">
                    Available Qty
                  </th>
                  {multiAccountSupportEnabled && (
                    <th className="text-md px-6 py-2 text-gray-500">
                      Marketplace
                    </th>
                  )}
                  {multiAccountSupportEnabled && (
                    <th className="text-md px-6 py-2 text-gray-500">
                      Seller Account
                    </th>
                  )}
                  <th className="text-md px-6 py-2 text-gray-500">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {availableInventory.length === 0 && (
                  <tr className="whitespace-nowrap">
                    <td className="text-md px-6 py-4 text-gray-500">
                      No Results
                    </td>
                    <td className="text-md px-6 py-4 text-gray-500"></td>
                    <td className="text-md px-6 py-4 text-gray-500"></td>
                    <td className="text-md px-6 py-4 text-gray-500"></td>
                    {multiAccountSupportEnabled && (
                      <td className="text-md px-6 py-4 text-gray-500"></td>
                    )}
                    {multiAccountSupportEnabled && (
                      <td className="text-md px-6 py-4 text-gray-500"></td>
                    )}
                  </tr>
                )}

                {availableInventory.length > 0 &&
                  availableInventory.map((item, idx) => (
                    <tr className="whitespace-nowrap">
                      <td className="text-md px-6 py-4 text-gray-500">
                        {buildMarketplaceHyperlink(
                          item.asin,
                          "ASIN",
                          item.source,
                        )}
                      </td>
                      <td className="text-md px-6 py-4 text-gray-500">
                        {item.sku}
                      </td>
                      <td
                        className="text-md px-6 py-4 text-gray-500"
                        title={item.name}>
                        {truncate(item.name)}
                      </td>
                      <td className="text-md px-6 py-4 text-gray-500">
                        {renderQuantityBasedOnWarehouses(
                          item,
                          "availableToShip",
                        )}
                      </td>
                      {multiAccountSupportEnabled && (
                        <td
                          className="text-md px-6 py-4 text-gray-500"
                          title={item.name}>
                          {
                            item.marketplaceAttributes?.attributes
                              ?.marketplaceCountryCode
                          }
                        </td>
                      )}
                      {multiAccountSupportEnabled && (
                        <td className="text-md px-6 py-4 text-gray-500">
                          {item.marketplaceAttributes?.attributes?.sellerId}
                        </td>
                      )}
                      <td className="text-md px-6 py-4 text-gray-500">
                        <div
                          onClick={() => {
                            setAddingProduct({
                              ...addingProduct,
                              productId: item.id,
                              asin: item.asin,
                              sku: item.sku,
                              sellerSku: item.sellerSku,
                              productName: item.name,
                              availableQty:
                                item.quantities?.availableToShip ?? 0,
                              baseUOM: item.baseUom ?? "Each",
                              marketplaceAttributes:
                                item.marketplaceAttributes ?? null,
                              attributes: item.attributes ?? null,
                              fnSku: item.fnSku,
                              name: item.name,
                              marketplace:
                                item.marketplaceAttributes?.attributes
                                  ?.marketplaceCountryCode,
                              seller:
                                item.marketplaceAttributes?.attributes
                                  ?.sellerId,
                              uomConfiguration: item.uomConfiguration,
                              ...(getProductPrice(item) && {
                                unitPrice: getProductPrice(item),
                              }),
                            });
                            setInventorySearchKeyword(null);
                          }}
                          className="text-md cursor-pointer rounded-md bg-2C7695 p-2 text-center text-white">
                          Select
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {(!inventorySearchKeyword || inventorySearchKeyword.trim() === "") &&
          addingProduct && (
            <>
              {addingProduct.sku && (
                <>
                  <div className="max-w-2xl rounded-lg border border-gray-200 bg-white p-6 shadow-md dark:border-gray-700 dark:bg-gray-800">
                    <a href="#">
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {addingProduct.productName}
                      </h5>
                    </a>
                    {addingProduct.asin && (
                      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        ASIN:
                        <span className="font-bold">
                          {buildMarketplaceHyperlink(
                            addingProduct.asin,
                            "ASIN",
                            addingProduct.source,
                          )}
                        </span>
                      </p>
                    )}
                    {addingProduct.sku && (
                      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        SKU:
                        <span className="px-2 font-bold">
                          {addingProduct.sku}
                        </span>
                      </p>
                    )}

                    <div
                      className="inline-flex cursor-pointer items-center rounded-lg bg-red-700 px-3 py-2 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:bg-blue-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                      onClick={() =>
                        setAddingProduct({
                          asin: null,
                          sku: null,
                          name: null,
                          formFactors: [],
                        })
                      }>
                      Remove
                    </div>
                  </div>
                </>
              )}
            </>
          )}

        {addingProduct?.formFactors &&
          addingProduct.formFactors.map((form, idx) => (
            <div className="flex items-center space-x-4">
              <div
                data-for={"quantity"} // Showing hover message for serial number when the serialNumberRequired flag is true
                data-tip={true}>
                <TextField
                  type="number"
                  id="quantity"
                  label="Quantity"
                  placeholder=" "
                  disabled={!!form.serialNumber}
                  onChange={(e) => changeFormFactorQuantity(idx, e)}
                  value={parseInt(form.quantity)}
                  name="quantity"
                  min="0"
                />
              </div>
              {/* Below message to shown upon hovering on quantity field */}
              {!!form.serialNumber && (
                <ReactTooltip
                  id="quantity"
                  place="top"
                  type="info"
                  effect="solid">
                  <div className="max-w-xl whitespace-normal text-xl">
                    Each item is associated with a unique serial number.
                    Therefore, when a serial number is required or added, the
                    quantity cannot exceed 1
                  </div>
                </ReactTooltip>
              )}
              <div>
                <Dropdown
                  placeholder={"Form Factor"}
                  list={renderFormFactorList(addingProduct)}
                  labelKey="name"
                  valueKey="name"
                  name="formFactor"
                  setSelected={(e) => changeFormFactor(idx, e)}
                  selectedValue={form.name}
                />
              </div>
              {!softAllocationEnabled &&
                lotIdSelectionEnabled &&
                productSkuBinMappings && (
                  <div className="w-96">
                    <Autocomplete
                      options={productSkuBinMappings}
                      labelKey="lotId"
                      secondaryLabelKey="unallocatedQuantity"
                      valueKey="lotId"
                      onChange={(e) => changeLotId(idx, e)}
                      value={form.lotId}
                      placeholder={"Lot/Batch ID"}
                      id="LOT_ID"
                    />
                  </div>
                )}
              {!softAllocationEnabled &&
                expiryDateSelectionEnabled &&
                productSkuBinMappingsWithExpiryDate && (
                  <div className="flex w-96 items-center gap-0">
                    <DatePicker
                      label=""
                      placeholder=""
                      onChange={(e) => changeExpiryDate(idx, e)}
                      parentClasses="w-14"
                    />
                    <Autocomplete
                      options={productSkuBinMappingsWithExpiryDate}
                      labelKey="expiryDate"
                      useValueDirectly={true}
                      secondaryLabelKey="unallocatedQuantity"
                      valueKey="expiryDate"
                      onChange={(e) => changeExpiryDate(idx, e)}
                      value={form.expiryDate}
                      placeholder={"Expiry Date"}
                      id="EXPIRY_DATE"
                    />
                  </div>
                )}
              {!softAllocationEnabled &&
                productSkuBinMappingsWithSerialNumber && (
                  <div className="w-96">
                    <Autocomplete
                      options={productSkuBinMappingsWithSerialNumber}
                      labelKey="serialNumber"
                      secondaryLabelKey="unallocatedQuantity"
                      valueKey="serialNumber"
                      onChange={(e) => changeSerialNumber(idx, e)}
                      value={form.serialNumber}
                      placeholder={"Serial Number"}
                      id="SERIAL_NUMBER"
                    />
                  </div>
                )}
              {!softAllocationEnabled && productSkuBinMappingsWithPoId && (
                <div className="w-96">
                  <Autocomplete
                    options={productSkuBinMappingsWithPoId}
                    labelKey="poId"
                    secondaryLabelKey="unallocatedQuantity"
                    valueKey="poId"
                    onChange={(e) => changePoId(idx, e)}
                    value={form.poId}
                    placeholder={"PO ID"}
                    id="PO_ID"
                  />
                </div>
              )}
              {!softAllocationEnabled &&
              lpnSelectionEnabled &&
              productSkuBinMappingsWithPalletId?.length > 0 ? (
                <div className="w-96">
                  <Autocomplete
                    options={productSkuBinMappingsWithPalletId}
                    labelKey="palletId"
                    secondaryLabelKey="unallocatedQuantity"
                    valueKey="palletId"
                    onChange={(e) => changePalletId(idx, e)}
                    value={form.palletId}
                    placeholder={"Pallet ID"}
                    id="PALLET_ID"
                  />
                </div>
              ) : null}
              {!softAllocationEnabled &&
                lpnSelectionEnabled &&
                productSkuBinMappingsWithLpn && (
                  <div className="w-96">
                    <Autocomplete
                      options={productSkuBinMappingsWithLpn}
                      labelKey="nestedFormFactorId"
                      secondaryLabelKey="unallocatedQuantity"
                      valueKey="nestedFormFactorId"
                      onChange={(e) => changeNestedFormFactorId(idx, e)}
                      value={form.nestedFormFactorId}
                      placeholder={"LPN"}
                      id="NESTED_FORM_FACTOR_ID"
                    />
                  </div>
                )}
            </div>
          ))}
        {multipleFormFactors && (
          <AddButton
            text="Add Form Factor"
            onClick={() =>
              // Only 1 formFactor can be added if multiFormFactor is disabled
              (isMultiFormFactor && addingProduct?.formFactors.length < 4) ||
              (!isMultiFormFactor && addingProduct?.formFactors.length < 1)
                ? setAddingProduct({
                    ...addingProduct,
                    formFactors: [
                      ...(addingProduct?.formFactors ?? []),
                      {
                        name: addingProduct.baseUOM ?? "Each",
                        quantity: 1,
                      },
                    ],
                  })
                : {}
            }
          />
        )}
      </div>

      {selectedEntity.customer && (
        <div className="flex items-center space-x-2 p-2">
          <div>Unable to find product?</div>
          <div
            onClick={getMarketplaceProducts}
            className={`C2D2DF-300 inline-flex cursor-pointer items-center justify-center rounded rounded-full border bg-FC8862 px-2 py-2 font-montserrat text-sm font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0`}>
            {"Sync Products"}
          </div>
        </div>
      )}
      <nav
        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination">
        <div className="flex flex-1 justify-between sm:justify-end">
          <div className="flex flex-1 justify-between space-x-2 sm:justify-end">
            <div
              onClick={() => setAddingProduct(null)}
              className="text-md C2D2DF-300 inline-flex cursor-pointer items-center justify-center rounded rounded-md border bg-red-600 px-2 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 hover:bg-red-700 focus:outline-none focus:ring-0">
              Cancel
            </div>
            <div
              onClick={
                addingProduct.sku
                  ? () => {
                      submitAddedProduct({
                        ...addingProduct,
                      });
                      setAddingProduct(null);
                    }
                  : () => {}
              }
              className={`rounded-md ${
                addingProduct.sku ? "cursor-pointer bg-224E73" : "bg-gray-400"
              } text-md C2D2DF-300 inline-flex items-center justify-center rounded border px-2 py-2 font-montserrat text-lg font-medium text-white outline-none ring-0 focus:outline-none focus:ring-0`}>
              Add Product
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default WizardProductSelector;
