import { Link } from "react-router-dom";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowRightIcon,
} from "@heroicons/react/outline";
import noFilterIcon from "#static/images/nofilter.png";
import { getPercentageDetail, getValue } from "#pages/AnalyticsDashboard";

const AnalyticsDataGrid = ({
  title = "",
  redirect = "",
  linkText = "View More",
  ViewMoreIcon = ArrowRightIcon,
  columns = [],
  data = [],
  compareData = [],
  selectColumnHandler = () => {},
  selectedColumn = "",
  toggle = false,
  toggleButtonHandler = () => {},
  selectedComparePeriods,
  isLoading = false,
  noDataFoundMsg = "No Data Available",
}) => {
  return (
    <div className="rounded-lg bg-white p-6 shadow">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex flex-wrap items-center gap-3">
          <h2 className="text-[17px] font-semibold">{title}</h2>
        </div>
        {redirect && (
          <Link
            to={redirect}
            className="flex items-center gap-1 text-sm font-semibold text-primaryAccent hover:text-primaryAccent">
            {linkText}
            <ViewMoreIcon className="h-4 w-4" style={{ strokeWidth: 3 }} />
          </Link>
        )}
      </div>
      <div className="overflow-x-auto border bg-F8F8F8 p-2">
        <table className="min-w-full">
          <thead>
            <tr className="border-b border-textWhite">
              {columns.length > 0 &&
                columns?.map((column, index) => {
                  const isCategoryOrProductColumn =
                    column === "product" || column === "category";
                  return (
                    <th
                      key={`analytics-grid-${index}`}
                      className={`${data.length > 0 ? "px-6" : "px-[19px]"} py-3 text-left text-base font-normal ${selectedColumn === column ? "font-semibold text-primaryAccent" : "text-[#717679]"} capitalize tracking-wider`}>
                      {isCategoryOrProductColumn ? (
                        column
                      ) : (
                        <div className="flex items-center">
                          <button
                            className="group inline-flex items-center space-x-1 capitalize"
                            onClick={() => selectColumnHandler(column)}>
                            {column}
                          </button>
                          <div className="">
                            <ChevronUpIcon
                              className={`ml-1 h-3 w-3 cursor-pointer ${selectedColumn === column && toggle === true ? "text-primaryAccent" : "text-[#717679]"}`}
                              onClick={() => toggleButtonHandler(column, true)}
                              style={{
                                strokeWidth:
                                  selectedColumn === column && toggle === true
                                    ? 3
                                    : 2,
                              }}
                            />
                            <ChevronDownIcon
                              className={`ml-1 h-3 w-3 cursor-pointer ${selectedColumn === column && toggle === false ? "text-primaryAccent" : "text-[#717679]"}`}
                              onClick={() => toggleButtonHandler(column, false)}
                              style={{
                                strokeWidth:
                                  selectedColumn === column && toggle === false
                                    ? 3
                                    : 2,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {data.length > 0 ? (
              data.map((dataObject, index) => {
                const totalRevenue = getValue(dataObject?.totalRevenue);
                const orderVolume = getValue(dataObject?.orderVolume);
                const orderFrequency = getValue(dataObject?.orderFrequency);
                let comparedTotalRevenue = 0;
                let comparedOrderVolume = 0;
                let comparedOrderFrequency = 0;
                const getPercentageDetailObject = {
                  totalRevenue: {
                    profit: 0.0,
                    isLoss: false,
                  },
                  orderVolume: {
                    profit: 0.0,
                    isLoss: false,
                  },
                  orderFrequency: {
                    profit: 0.0,
                    isLoss: false,
                  },
                };
                if (selectedComparePeriods) {
                  let compareProductObject = null;
                  if (compareData.length > 0) {
                    compareProductObject = compareData.find(
                      (compareDataObject) =>
                        compareDataObject?.productId === dataObject?.productId,
                    );
                  }
                  comparedTotalRevenue = getValue(
                    compareProductObject?.totalRevenue,
                  );
                  getPercentageDetailObject.totalRevenue = getPercentageDetail(
                    totalRevenue,
                    comparedTotalRevenue,
                    true,
                  );
                  comparedOrderVolume = getValue(
                    compareProductObject?.orderVolume,
                  );
                  getPercentageDetailObject.orderVolume = getPercentageDetail(
                    orderVolume,
                    comparedOrderVolume,
                    true,
                  );
                  comparedOrderFrequency = getValue(
                    compareProductObject?.orderFrequency,
                  );
                  getPercentageDetailObject.orderFrequency =
                    getPercentageDetail(
                      orderFrequency,
                      comparedOrderFrequency,
                      true,
                    );
                }
                const totalRevenueProfit =
                  getPercentageDetailObject?.totalRevenue?.profit;
                const totalOrderVolume =
                  getPercentageDetailObject?.orderVolume?.profit;
                const totalOrderFrequency =
                  getPercentageDetailObject?.orderFrequency?.profit;
                return (
                  <tr key={`product row-${dataObject?.productId}-${index}`}>
                    <td className="whitespace-nowrap px-6 py-4 text-base">
                      <div className="font-semibold text-gray-600">
                        {dataObject?.productName}
                      </div>
                      {dataObject?.sku && (
                        <Link
                          className="mt-1 block text-xs font-semibold text-primaryAccent hover:text-primaryAccent"
                          to={`/productAnalytics?sku=${dataObject?.sku}`}>
                          {`SKU: ${dataObject?.sku}`}
                        </Link>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-base text-gray-600">
                      <div className="text-gray-600">{`$${totalRevenue}`}</div>
                      {selectedComparePeriods && (
                        <div className="mt-1 flex items-center gap-2">
                          <span className="text-xs text-gray-600">{`vs $${comparedTotalRevenue}`}</span>
                          <span
                            className={`text-xs font-medium ${
                              getPercentageDetailObject?.totalRevenue?.isLoss
                                ? "text-red-600"
                                : totalRevenueProfit === "NA"
                                  ? "text-gray-600"
                                  : "text-green-600"
                            }`}>{`${getPercentageDetailObject?.totalRevenue?.isLoss || totalRevenueProfit === "NA" ? "" : "+"}${totalRevenueProfit}${totalRevenueProfit !== "NA" ? "%" : ""}`}</span>
                        </div>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-base text-gray-600">
                      <div className="text-gray-600">{`${orderVolume}`}</div>
                      {selectedComparePeriods && (
                        <div className="mt-1 flex items-center gap-2">
                          <span className="text-xs text-gray-600">{`vs ${comparedOrderVolume}`}</span>
                          <span
                            className={`text-xs font-medium ${
                              getPercentageDetailObject?.orderVolume?.isLoss
                                ? "text-red-600"
                                : totalOrderVolume === "NA"
                                  ? "text-gray-600"
                                  : "text-green-600"
                            }`}>{`${getPercentageDetailObject?.orderVolume?.isLoss || totalOrderVolume === "NA" ? "" : "+"}${totalOrderVolume}${totalOrderVolume !== "NA" ? "%" : ""}`}</span>
                        </div>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-base text-gray-600">
                      <div className="text-gray-600">{`${orderFrequency}`}</div>
                      {selectedComparePeriods && (
                        <div className="mt-1 flex items-center gap-2">
                          <span className="text-xs text-gray-600">{`vs ${comparedOrderFrequency}`}</span>
                          <span
                            className={`text-xs font-medium ${
                              getPercentageDetailObject?.orderFrequency?.isLoss
                                ? "text-red-600"
                                : totalOrderFrequency === "NA"
                                  ? "text-gray-600"
                                  : "text-green-600"
                            }`}>{`${getPercentageDetailObject?.orderFrequency?.isLoss || totalOrderFrequency === "NA" ? "" : "+"}${totalOrderFrequency}${totalOrderFrequency !== "NA" ? "%" : ""}`}</span>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : !isLoading ? (
              <tr>
                <td colSpan={"100%"}>
                  <div className="flex-col items-center justify-center p-2 text-center">
                    <img src={noFilterIcon} className="mx-auto w-32" />
                    <div className="mt-4 text-center text-xl font-semibold text-gray-600">
                      {noDataFoundMsg}
                    </div>
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={"100%"}>
                  <div className="px-6 py-3 text-left text-sm font-medium tracking-wider text-gray-600">
                    Loading...
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AnalyticsDataGrid;
