export const analyticsProductsColumns = [
  "product",
  "revenue",
  "order volume",
  "order frequency",
];
export const analyticsCategoriescolumns = [
  "category",
  "revenue",
  "order volume",
  "order frequency",
];

export const comparePeriods = ["Previous Period", "Previous Year"];
export const customeDateRange = "customeRange";
