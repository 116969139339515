import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useQuery } from "#hooks/useQuery";
import { GET_CATALOGS_ANALYTICS_PRODUCT_LEVEL_PERFORMANCE } from "#queries/index";
import {
  ANALYTICS_DATE_RANGES,
  getDateInDDMMYYYYFormate,
  getStartDateWithHhMmSsFormate,
  getEndDateWithHhMmSsFormate,
} from "#utils/dateRanges";
import { getUnixTimeStamp } from "#utils/catalogsAndAnalyticsUtils";
import {
  analyticsProductsColumns,
  customeDateRange,
} from "#constants/analytics";
import ProductsAnalyticsTable from "#components/catalogs/ProductsAnalyticsTable";
import CustomPagination from "#newUiComponents/commons/CustomPagination";

const pageSize = 25;
const ProductsPerformance = () => {
  const infiniteLoaderForAnalyticsProducts = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(
    analyticsProductsColumns[1],
  );
  const [toggle, setToggle] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [analyticsProductPerformanceData, setAnalyticsProductPerformanceData] =
    useState(null);
  const getTotalRecords =
    analyticsProductPerformanceData?.[0]?.totalRecords || 0;
  const getAnalyticsProductLevelPerformanceQuery = useQuery(
    GET_CATALOGS_ANALYTICS_PRODUCT_LEVEL_PERFORMANCE,
  );
  const timePeriods = Object.keys(ANALYTICS_DATE_RANGES);
  const defaultSelectedDate = timePeriods[3];
  const getStartEndDate = getDateInDDMMYYYYFormate(
    ANALYTICS_DATE_RANGES[defaultSelectedDate],
  );
  const analyticsData = JSON.parse(
    localStorage.getItem("analyticsData") || null,
  );
  const getStartAndEndDate = analyticsData?.selectedTimePeriod
    ? analyticsData?.selectedTimePeriod !== customeDateRange
      ? getDateInDDMMYYYYFormate(
          ANALYTICS_DATE_RANGES[analyticsData?.selectedTimePeriod],
        )
      : analyticsData?.startEndDate
    : getStartEndDate;
  const getSelectedCustomer = analyticsData?.selectedCustomer || "all customer";

  const redirectBack = "/analytics";
  const redirect = "/productAnalytics";

  const selectColumnHandler = (column) => {
    if (getTotalRecords > 0) {
      if (column !== selectedColumn) {
        setSelectedColumn(column);
        setToggle(false);
      } else {
        setToggle(!toggle);
      }
      setAnalyticsProductPerformanceData(null);
      setPageNumber(1);
    }
  };

  const toggleButtonHandler = (column, toggleValue) => {
    if (getTotalRecords > 0) {
      if (column !== selectedColumn) {
        setSelectedColumn(column);
      }
      setToggle(toggleValue);
      setAnalyticsProductPerformanceData(null);
      setPageNumber(1);
    }
  };

  const onPageChangeHandler = (page) => {
    setPageNumber(page);
  };

  const getAnalyticsProductPerformanceInfo = async () => {
    const customers = [];
    const selectedStartEndDate = [];
    const comparePeriodData = {
      comparePeriod: false,
    };
    if (getSelectedCustomer) {
      if (getSelectedCustomer !== "all customer") {
        customers[0] = getSelectedCustomer;
      }
      if (getStartAndEndDate?.length > 0) {
        selectedStartEndDate[0] = getUnixTimeStamp(
          getStartDateWithHhMmSsFormate(getStartAndEndDate[0]),
        );
        selectedStartEndDate[1] = getUnixTimeStamp(
          getEndDateWithHhMmSsFormate(getStartAndEndDate[1]),
        );
      }
      setIsLoading(true);
      try {
        const response =
          await getAnalyticsProductLevelPerformanceQuery.fetchData({
            perPage: pageSize,
            pageNumber: pageNumber,
            filters: {
              customer: customers,
              startEndDate: selectedStartEndDate,
              sortType: !toggle ? "top" : "bottom",
              performanceType: selectedColumn,
              ...comparePeriodData,
            },
          });
        if (
          response?.data?.catalogAnalyticsProductLevelPerformance
            ?.primaryProductLevelPerformance &&
          response?.data?.catalogAnalyticsProductLevelPerformance
            ?.primaryProductLevelPerformance?.length > 0
        ) {
          setAnalyticsProductPerformanceData((previousData) => {
            if (previousData) {
              return [
                ...previousData,
                ...response.data.catalogAnalyticsProductLevelPerformance
                  .primaryProductLevelPerformance,
              ];
            } else
              return response.data.catalogAnalyticsProductLevelPerformance
                .primaryProductLevelPerformance;
          });
        } else {
          setAnalyticsProductPerformanceData(null);
        }
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const attachObserver = () => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          getTotalRecords > pageNumber * pageSize
        ) {
          setPageNumber(pageNumber + 1);
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (infiniteLoaderForAnalyticsProducts.current) {
      observer.observe(infiniteLoaderForAnalyticsProducts.current);
    }

    return observer;
  };

  useEffect(() => {
    const observer = attachObserver();

    return () => {
      if (infiniteLoaderForAnalyticsProducts.current) {
        observer.unobserve(infiniteLoaderForAnalyticsProducts.current);
      }
    };
  }, [analyticsProductPerformanceData, getTotalRecords]);

  useEffect(() => {
    getAnalyticsProductPerformanceInfo();
  }, [selectedColumn, pageNumber, toggle]);

  useEffect(() => {
    return () => {
      if (
        !window.location.pathname.includes("/productPerformance") &&
        !window.location.pathname.includes("/productAnalytics") &&
        !window.location.pathname.includes("/analytics")
      ) {
        localStorage.removeItem("analyticsData");
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-white p-8 font-inter">
      <div className="w-full">
        <div className="mb-4 flex items-center gap-4">
          <Link
            className="flex items-center gap-1 text-gray-600 hover:text-gray-900"
            to={redirectBack}>
            <ArrowLeftIcon className="h-5 w-5" />
            {`Back to Dashboard`}
          </Link>
          <h2 className="text-2xl font-semibold">All Products</h2>
        </div>
        <div className="h-70v w-full">
          <div className="mb-4 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <h2 className="text-[17px] font-semibold">
                Product Level Performance
              </h2>
            </div>
          </div>
          <ProductsAnalyticsTable
            data={analyticsProductPerformanceData || []}
            selectColumnHandler={selectColumnHandler}
            redirect={redirect}
            isLoading={isLoading}
            selectedColumn={selectedColumn}
            toggle={toggle}
            toggleButtonHandler={toggleButtonHandler}
            isInfiniteLoading={true}
            infiniteLoaderForAnalyticsProducts={
              infiniteLoaderForAnalyticsProducts
            }
            pageNumber={pageNumber}
            pageSize={pageSize}
            totalDataCount={getTotalRecords}
          />
          {!isLoading && (
            <div className="mt-4 flex justify-end border-t border-gray-200 bg-white">
              <CustomPagination
                showDataCountOnly={true}
                currentPage={pageNumber}
                pageSize={pageSize}
                totalDataCount={getTotalRecords}
                onPageChange={(page) => onPageChangeHandler(page)}
                currentDataCount={analyticsProductPerformanceData?.length || 0}
                showSizeChanger={false}
                onPageSizeChange={() => {}}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductsPerformance;
