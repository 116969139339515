import _ from "lodash";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { v4 as uuidv4 } from "uuid";

// Add the plugin for custom parsing
dayjs.extend(customParseFormat);

export const pluralize = (count, noun, suffix = "s") => {
  if (count === 1) return `${count} ${noun}`;
  return `${count} ${noun}${suffix}`;
};

export const handlePositiveValue = (value, type = "int") => {
  if (isNaN(value) || parseFloat(value) < 0) {
    return 0;
  }

  return type === "int" ? parseInt(value) : parseFloat(value);
};

export const getBulkUploadTemplateURL = (fileName) => {
  let templateEnvironmentBucket = "uat";
  if (process.env.NODE_ENV === "uat") {
    templateEnvironmentBucket = "uat";
  } else if (process.env.NODE_ENV === "development") {
    templateEnvironmentBucket = "uat";
  } else if (process.env.NODE_ENV === "production") {
    templateEnvironmentBucket = "prod";
  }
  return `https://templates-onboarding.s3.amazonaws.com/${templateEnvironmentBucket}/${fileName}.csv`;
};

export const generatePlantingZoneCodes = () => {
  return Array.from({ length: 13 }, (_, i) => {
    const baseHz = i + 1;
    return [
      { label: `${baseHz}a`, value: `${baseHz}a`, hz: baseHz + 0.1 },
      { label: `${baseHz}b`, value: `${baseHz}b`, hz: baseHz + 0.2 },
    ];
  }).flat();
};

export const capitalizeLabel = (field) => {
  if (!field) return "";
  return field
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const formatTwoDigits = (num) => (num < 10 ? `0${num}` : num);

export const formateInitials = (inputString) => {
  return inputString
    ? inputString
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase()
    : "NA";
};

export const formatCount = (count) => {
  if (count) {
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}k`;
    }
    return count.toString();
  }
  return 0;
};

export const notificationPermissionAccess = (auth) => {
  if (auth) {
    return (
      auth?.role?.toLowerCase() === "admin" &&
      auth?.permissions?.some(
        (permission) =>
          permission.route === "/notifications" && permission.readable,
      )
    );
  }
  return false;
};

export const updateNotificationsRoutes = (
  routes,
  notificationEnabled,
  user,
) => {
  const hasAdminAccess = notificationPermissionAccess(user);
  return routes
    .map((route) => {
      if (route.name === "Notifications") {
        if (!hasAdminAccess || notificationEnabled) {
          return null;
        }
      }
      if (route.name === "Setup" && route.children) {
        route.children = route.children
          .map((child) => {
            if (child.name === "Notification Management") {
              if (hasAdminAccess && notificationEnabled) {
                return {
                  ...child,
                  href: "/notifications-management",
                  isCurrent: (pathname) =>
                    pathname === "/notifications-management",
                };
              }
              return null;
            }
            return child;
          })
          .filter(Boolean);
      }
      return route;
    })
    .filter(Boolean);
};

export const transformCostPrice = (
  costPrice,
  transform,
  pastAddedCostPrice,
) => {
  if (transform) {
    let selectedCostPrice = costPrice;
    let isCpModified = false;
    if (selectedCostPrice) {
      if (
        _.values(_.omit(selectedCostPrice, ["currency"])).filter((val) => !!val)
          .length > 0
      ) {
        let breakdownCost = _.omit(selectedCostPrice, ["total", "currency"]);
        if (_.keys(breakdownCost).length > 0) {
          _.keys(breakdownCost).forEach((key) => {
            if (_.isObject(breakdownCost[key])) {
              if (selectedCostPrice.currency)
                breakdownCost[key].currency = selectedCostPrice.currency;
              breakdownCost[key].quantity = Number(
                breakdownCost[key].selectedQuantity,
              );
              breakdownCost[key] = _.omit(breakdownCost[key], [
                "selectedQuantity",
                "selectedPrice",
              ]);
            } else breakdownCost[key] = Number(breakdownCost[key]) * 100;
          });
          if (!_.isEmpty(_.omit(breakdownCost, ["discount"]))) {
            selectedCostPrice = [
              {
                ..._.omit(selectedCostPrice, ["total"]),
                ...breakdownCost,
              },
            ];
          } else
            selectedCostPrice = [
              {
                ...selectedCostPrice,
                ...breakdownCost,
                total: Number(selectedCostPrice.total) * 100,
              },
            ];
        } else {
          const total = Number(selectedCostPrice.total) * 100;
          selectedCostPrice = [{ ...selectedCostPrice, total }];
        }
        if (_.isEqual(selectedCostPrice, pastAddedCostPrice))
          isCpModified = false;
        else isCpModified = true;
      } else {
        if (pastAddedCostPrice && pastAddedCostPrice?.length > 0) {
          selectedCostPrice = [];
          isCpModified = false;
        } else {
          const { costPrice, ...restObjects } = selectedCostPrice;
          selectedCostPrice = restObjects;
        }
      }
    }
    selectedCostPrice =
      (selectedCostPrice?.length &&
        selectedCostPrice?.map((item) => {
          const componentDetails = Object.values(item).filter(
            (value) => typeof value === "object" && value.id,
          );
          const { currency, ...rest } = item;
          const filteredRest = Object.keys(rest).reduce((acc, key) => {
            if (!item[key]?.id) {
              acc[key] = rest[key];
            }
            return acc;
          }, {});
          return {
            currency,
            componentDetails,
            ...filteredRest,
          };
        })) ||
      [];

    return {
      costPrice: selectedCostPrice,
      isCpModified: isCpModified,
    };
  }
  if (!transform) {
    return (
      costPrice?.map((item) => {
        const { currency, componentDetails, ...rest } = item;

        const components = componentDetails?.reduce((acc, component) => {
          acc[component.id] = component;
          return acc;
        }, {});

        return {
          currency,
          ...components,
          ...rest,
        };
      }) || []
    );
  }
};

export const formatToISODate = (dateString) => {
  if (!dateString) return null;

  if (dayjs(dateString, "YYYY-MM-DD", true).isValid()) {
    return dateString.split("T")[0];
  }

  const formats = [
    "MM-DD-YYYY",
    "DD-MM-YYYY",
    "MM/DD/YYYY",
    "DD/MM/YYYY",
    "YYYY/MM/DD",
    "YYYY-MM-DD",
  ];

  for (const format of formats) {
    const parsed = dayjs(dateString, format);
    if (parsed.isValid()) {
      return parsed.format("YYYY-MM-DD");
    }
  }

  const date = new Date(dateString);
  if (!isNaN(date.getTime())) {
    return dayjs(date).format("YYYY-MM-DD");
  }

  return null;
};

// Pack Plan helper functions
export const packPlanMethods = {
  // to be used for pack plan

  formatOrderItems: (data) => {
    const formattedItems = [];
    const itemMap = new Map();

    data.forEach((item) => {
      item.formFactors.forEach((ff) => {
        const uniqueKey = `${item.productId}-${ff.name}-${ff.expiryDate || ""}-${ff.lotId || ""}-${ff.serialNumber || ""}-${ff.poId || ""}-${ff.nestedFormFactorId || ""}`;

        if (itemMap.has(uniqueKey)) {
          itemMap.get(uniqueKey).quantity += ff.quantity;
        } else {
          itemMap.set(uniqueKey, {
            productId: item.productId,
            formFactor: ff.name,
            quantity: ff.quantity,
            lotId: ff.lotId?.trim() !== "" ? ff.lotId : null,
            expiryDate: ff.expiryDate?.trim() !== "" ? ff.expiryDate : null,
            serialNumber:
              ff.serialNumber?.trim() !== "" ? ff.serialNumber : null,
            poId: ff.poId?.trim() !== "" ? ff.poId : null,
            nestedFormFactorId:
              ff.nestedFormFactorId?.trim() !== ""
                ? ff.nestedFormFactorId
                : null,
            palletId: ff.palletId?.trim() !== "" ? ff.palletId : null,
            marketplaceAttributes: item.marketplaceAttributes ?? null,
            attributes: item.attributes ?? null,
            orderDetailUid: item.orderDetailUid,
            fnSku: item.fnSku ?? null,
            sku: item.sku,
            name: item.name,
          });
        }
      });
    });

    return Array.from(itemMap.values());
  },
  hasDuplicateProducts: (data) => {
    const productSet = new Set();
    for (const item of data) {
      if (productSet.has(item.productId)) {
        return true; // Duplicate found
      }
      productSet.add(item.productId);
    }
    return false; // No duplicates
  },
  /**
   * Converts order boxes from PackPlan format to the selectedBoxes API format
   * @param {string} orderId - The ID of the order
   * @param {Object} orderBoxesState - The orderBoxes state from PackPlan
   * @param {string} dimensionUnit - The dimension unit to use (e.g., "inches")
   * @param {string} weightUnit - The weight unit to use (e.g., "pounds")
   * @returns {Array} - Array of boxes in the selectedBoxes format
   */
  convertToSelectedBoxes: (
    orderId,
    orderBoxesState,
    dimensionUnit = "inches",
    weightUnit = "pounds",
  ) => {
    if (orderId && orderId.startsWith("temp_order")) {
      // Get the boxes for this temporary order
      const boxes = orderBoxesState[orderId] || [];

      if (boxes.length > 0) {
        // Process these boxes but fix the naming
        return boxes.map((box) => {
          // Extract the box number from the displayName (e.g., "Box-1" from "temp_order_123_Box-1")
          let boxName = box.displayName;
          if (box.name && box.name.includes("_")) {
            // Try to extract just the Box-N part
            const parts = box.name.split("_");
            boxName = parts[parts.length - 1];
          }

          return {
            boxName: boxName || "Custom Box",
            boxDimension: {
              length: box.length || 0,
              width: box.width || 0,
              height: box.height || 0,
              unit: dimensionUnit,
            },
            items:
              box.items?.map((item) => ({
                name: item.name,
                sku: item.sku,
                quantity: item.packQuantity,
                orderDetailUid: item.orderDetailUid ?? null,
              })) || [],
            totalWeight: box.totalWeight || 0,
            weightUnit: weightUnit,
          };
        });
      }
      return [];
    }

    // If no order boxes found, return empty array
    if (
      !orderBoxesState ||
      !orderBoxesState[orderId] ||
      !orderBoxesState[orderId].length
    ) {
      return [];
    }

    // Get the boxes for this order
    const boxes = orderBoxesState[orderId];

    // Convert each box to the selectedBoxes format
    return boxes.map((box) => ({
      boxName: box.name || box.displayName,
      boxDimension: {
        length: box.length || 0,
        width: box.width || 0,
        height: box.height || 0,
        unit: dimensionUnit,
      },
      items:
        box.items?.map((item) => ({
          name: item.name,
          sku: item.sku,
          quantity: item.packQuantity,
          orderDetailUid: item.orderDetailUid ?? uuidv4(),
        })) || [],
      totalWeight: box.totalWeight || 0,
      weightUnit: weightUnit,
    }));
  },

  convertSelectedBoxesToPackPlan: (
    orderDetails = [],
    orderBoxes = {},
    packedQuantities = {},
    assignedQuantities = {},
  ) => {
    if (!orderDetails || orderDetails.length === 0)
      return {
        newOrderBoxes: {},
        newPackedQuantities: {},
        newAssignedQuantities: {},
      };

    const newOrderBoxes = { ...orderBoxes };
    const newPackedQuantities = { ...packedQuantities };
    const newAssignedQuantities = { ...assignedQuantities };

    orderDetails.forEach((order) => {
      const orderId = order.orderId;

      // Skip if no selectedBoxes
      if (!order.selectedBoxes || order.selectedBoxes.length === 0) return;

      // Create a clean slate for this order's quantities
      newPackedQuantities[orderId] = {};
      newAssignedQuantities[orderId] = {};

      // Create mapping for order items by orderDetailUid for direct lookup
      const orderItemsByUid = {};
      const orderItemsBySku = {}; // Fallback in case orderDetailUid isn't present

      // Create a tracking map for remaining quantities
      const remainingQuantities = {};

      order.orderDetails.forEach((item, index) => {
        const uniqueId = `${item.productId}_${index}`;
        remainingQuantities[uniqueId] = item.quantity || 0;

        // Primary index by orderDetailUid if available
        if (item.orderDetailUid) {
          orderItemsByUid[item.orderDetailUid] = {
            ...item,
            itemIndex: index,
            uniqueId,
          };
        }

        // Secondary index by SKU as fallback
        if (!orderItemsBySku[item.sku]) {
          orderItemsBySku[item.sku] = [];
        }
        orderItemsBySku[item.sku].push({
          ...item,
          itemIndex: index,
          uniqueId,
        });
      });

      // Process each box in selectedBoxes
      const processedBoxes = order.selectedBoxes.map((box, boxIndex) => {
        // Create a proper box structure matching the PackPlan component expectations
        const displayName = `Box-${boxIndex + 1}`;
        const name = `${orderId}_${displayName}`;

        // Create a formatted box object
        const formattedBox = {
          name,
          displayName,
          type: box.boxName || "Custom Box",
          length: box.boxDimension?.length || 0,
          width: box.boxDimension?.width || 0,
          height: box.boxDimension?.height || 0,
          weight: 0, // Will be calculated below
          totalWeight: box.totalWeight || 0,
          items: [],
        };

        // Process box items and calculate total items weight
        let totalItemsWeight = 0;

        if (box.items && box.items.length > 0) {
          // Process each box item
          box.items.forEach((boxItem) => {
            let orderItem;
            let uniqueId;
            let toAllocate = 0;

            // First try to find the order item by orderDetailUid (exact match)
            if (
              boxItem.orderDetailUid &&
              orderItemsByUid[boxItem.orderDetailUid]
            ) {
              orderItem = orderItemsByUid[boxItem.orderDetailUid];
              uniqueId = orderItem.uniqueId;

              // Calculate allocation (limited by available quantity)
              toAllocate = Math.min(
                boxItem.quantity,
                remainingQuantities[uniqueId] || 0,
              );

              if (toAllocate <= 0) return; // Skip if nothing to allocate

              remainingQuantities[uniqueId] -= toAllocate;

              // Track packed quantities
              newPackedQuantities[orderId][uniqueId] =
                (newPackedQuantities[orderId][uniqueId] || 0) + toAllocate;
              newAssignedQuantities[orderId][uniqueId] =
                (newAssignedQuantities[orderId][uniqueId] || 0) + toAllocate;

              // Calculate weight contribution
              const itemWeight = orderItem.attributes?.weight || 0;
              totalItemsWeight += itemWeight * toAllocate;

              // Add to formatted box items
              formattedBox.items.push({
                ...orderItem,
                uniqueId,
                packQuantity: toAllocate,
              });
            }
            // Fallback to SKU-based matching if orderDetailUid isn't present or match wasn't found
            else if (
              orderItemsBySku[boxItem.sku] &&
              orderItemsBySku[boxItem.sku].length > 0
            ) {
              let remainingToAllocate = boxItem.quantity;
              const allocatedItems = [];

              // Try to allocate to order items with this SKU
              for (
                let i = 0;
                i < orderItemsBySku[boxItem.sku].length &&
                remainingToAllocate > 0;
                i++
              ) {
                orderItem = orderItemsBySku[boxItem.sku][i];
                uniqueId = orderItem.uniqueId;

                // Skip if no remaining quantity for this item
                if ((remainingQuantities[uniqueId] || 0) <= 0) continue;

                // Calculate how much to allocate to this order item
                const itemToAllocate = Math.min(
                  remainingToAllocate,
                  remainingQuantities[uniqueId] || 0,
                );

                if (itemToAllocate <= 0) continue; // Skip if nothing to allocate

                // Update remaining quantities
                remainingQuantities[uniqueId] -= itemToAllocate;
                remainingToAllocate -= itemToAllocate;

                // Track packed quantities
                newPackedQuantities[orderId][uniqueId] =
                  (newPackedQuantities[orderId][uniqueId] || 0) +
                  itemToAllocate;
                newAssignedQuantities[orderId][uniqueId] =
                  (newAssignedQuantities[orderId][uniqueId] || 0) +
                  itemToAllocate;

                // Calculate weight contribution
                const itemWeight = orderItem.attributes?.weight || 0;
                totalItemsWeight += itemWeight * itemToAllocate;

                // Track for adding to box
                allocatedItems.push({
                  ...orderItem,
                  packQuantity: itemToAllocate,
                });
              }

              // Add allocated items to the box
              allocatedItems.forEach((item) => {
                const existingItemIndex = formattedBox.items.findIndex(
                  (boxItem) => boxItem.uniqueId === item.uniqueId,
                );

                if (existingItemIndex >= 0) {
                  // Update existing item quantity
                  formattedBox.items[existingItemIndex].packQuantity +=
                    item.packQuantity;
                } else {
                  // Add as new item
                  formattedBox.items.push(item);
                }
              });
            }
          });
        }

        // Calculate box weight (total weight minus items weight)
        // If calculation results in negative or very small weight, use 0 as fallback
        const calculatedBoxWeight = Math.max(
          0,
          (box.totalWeight || 0) - totalItemsWeight,
        );
        formattedBox.weight =
          calculatedBoxWeight > 0.001 ? calculatedBoxWeight : 0;

        return formattedBox;
      });

      // Add processed boxes to newOrderBoxes
      newOrderBoxes[orderId] = processedBoxes;
    });

    return {
      newOrderBoxes,
      newPackedQuantities,
      newAssignedQuantities,
    };
  },
};
