import moment from "moment-timezone";

export const getUnixTimeStamp = (date) =>
  moment.tz(moment(date), "YYYY-MM-DD HH:mm:ss", "UTC").valueOf();

export const storeInLocalStorage = (value, key, childkey = "") => {
  let setValue = {};
  if (!childkey) {
    setValue = {
      [key]: value,
    };
  } else {
    setValue = {
      [childkey]: {
        [key]: value,
      },
    };
  }
  const analyticsData = JSON.parse(
    localStorage.getItem("analyticsData") || null,
  );
  if (analyticsData) {
    if (analyticsData[childkey]) {
      setValue = {
        ...analyticsData,
        [childkey]: {
          ...analyticsData[childkey],
          ...setValue[childkey],
        },
      };
    } else {
      setValue = {
        ...analyticsData,
        ...setValue,
      };
    }
  }
  localStorage.setItem("analyticsData", JSON.stringify(setValue));
};
