import { useState, useEffect } from "react";
import dayjs from "dayjs";
import _ from "lodash";
import { Dropdown, Menu, Button } from "antd";
import { CalendarIcon } from "@heroicons/react/outline";
import {
  ANALYTICS_DATE_RANGES,
  getDateInDDMMYYYYFormate,
} from "#utils/dateRanges";
import { comparePeriods, customeDateRange } from "#constants/analytics";
import { storeInLocalStorage } from "#utils/catalogsAndAnalyticsUtils";
import DatePicker from "#newUiComponents/commons/DatePicker";

const DateRangeDropDown = ({
  startEndDate = [],
  setStartEndDate = () => {},
  defaultSelectedDate = "",
  selectedComparePeriods = null,
  setSelectComparePeriods = () => {},
  daysDifference,
}) => {
  const showComparePeriod = [...comparePeriods];
  if (daysDifference > 365) {
    showComparePeriod.splice(showComparePeriod.indexOf("Previous Year"), 1);
  }
  const showSelectedDate = `${dayjs(startEndDate[0], "DD-MM-YYYY").format("MMM D, YYYY")} - ${dayjs(startEndDate[1], "DD-MM-YYYY").format("MMM D, YYYY")}`;
  const analyticsData = JSON.parse(
    localStorage.getItem("analyticsData") || null,
  );
  const timePeriods = Object.keys(ANALYTICS_DATE_RANGES);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(
    defaultSelectedDate || "",
  );

  const setStartEndDateHandler = (timePeriodInDate) => {
    setStartEndDate(timePeriodInDate);
    storeInLocalStorage(timePeriodInDate, "startEndDate");
  };

  const setDateFromPickerHandler = (timePeriodInDate) => {
    setStartEndDateHandler(timePeriodInDate);
    setSelectedTimePeriod(customeDateRange);
    storeInLocalStorage(customeDateRange, "selectedTimePeriod");
  };

  const selectTimePeriodHandler = (timePeriod) => {
    setStartEndDateHandler(
      getDateInDDMMYYYYFormate(ANALYTICS_DATE_RANGES[timePeriod]),
    );
    setSelectedTimePeriod(timePeriod);
    storeInLocalStorage(timePeriod, "selectedTimePeriod");
  };

  const setComparePeriodHandler = (period) => {
    let selectedPeriod = period;
    if (period === selectedComparePeriods) {
      selectedPeriod = null;
    }
    setSelectComparePeriods(selectedPeriod);
    storeInLocalStorage(selectedPeriod, "selectedComparePeriods");
  };

  useEffect(() => {
    if (daysDifference > 365 && selectedComparePeriods) {
      if (
        selectedComparePeriods &&
        selectedComparePeriods === "Previous Year"
      ) {
        setSelectComparePeriods(null);
        storeInLocalStorage(null, "selectedComparePeriods");
      }
    }
  }, [daysDifference, selectedComparePeriods]);

  useEffect(() => {
    if (analyticsData) {
      if (analyticsData?.selectedTimePeriod) {
        setSelectedTimePeriod(analyticsData?.selectedTimePeriod);
        if (analyticsData?.selectedTimePeriod !== customeDateRange) {
          setStartEndDate(
            getDateInDDMMYYYYFormate(
              ANALYTICS_DATE_RANGES[analyticsData?.selectedTimePeriod],
            ),
          );
        } else if (
          analyticsData?.startEndDate &&
          !_.isEqual(analyticsData?.startEndDate, startEndDate)
        ) {
          setStartEndDate(analyticsData?.startEndDate);
        }
      }
    }
  }, []);

  const menu = (
    <div className="absolute z-10 mt-1 w-full rounded-md border border-gray-200 bg-white shadow-lg">
      <Menu className="grid grid-cols-2 divide-x">
        <div className="p-4">
          <h3 className="mb-3 text-sm font-medium text-gray-900">
            Select Date Range
          </h3>
          <div className="space-y-2">
            {timePeriods?.map((timePeriod, index) => (
              <Menu.Item
                key={index}
                onClick={() => selectTimePeriodHandler(timePeriod)}
                className="dateRangeMenu"
                style={{
                  width: "100%",
                  padding: "8px 16px",
                  textAlign: "left",
                  borderRadius: "6px",
                  background: "transparent",
                  alignItems: "center",
                  fontSize: "16px",
                }}>
                {timePeriod}
              </Menu.Item>
            ))}
            <div
              className="mt-2 border-t border-gray-200 pt-2"
              key="custome-range">
              <div className="mb-2 text-sm font-medium text-gray-900">
                Custom Range
              </div>
              <div className="space-y-2">
                <div key="start-date">
                  <DatePicker
                    label=""
                    infoText="Select Start Date"
                    value={startEndDate[0]}
                    onChange={(date, _) => {
                      setDateFromPickerHandler([date, startEndDate[1]]);
                    }}
                    format="DD-MM-YYYY"
                    disabledDate={(current) => {
                      const currentDate = dayjs(
                        current.format("DD-MM-YYYY"),
                        "DD-MM-YYYY",
                      );
                      const startDate = dayjs(startEndDate[1], "DD-MM-YYYY");
                      return currentDate.isAfter(startDate, "day");
                    }}
                    error=""
                    parentClasses="mb-4"
                    labelClasses="text-sm font-medium text-gray-700 lock-in-date-picker"
                  />
                </div>
                <div key="to-date">
                  <DatePicker
                    label=""
                    infoText="Select To Date"
                    value={startEndDate[1]}
                    onChange={(date, _) => {
                      setDateFromPickerHandler([startEndDate[0], date]);
                    }}
                    format="DD-MM-YYYY"
                    disabledDate={(current) => {
                      const currentDate = dayjs(
                        current.format("DD-MM-YYYY"),
                        "DD-MM-YYYY",
                      );
                      const startDate = dayjs(startEndDate[0], "DD-MM-YYYY");
                      return currentDate.isBefore(startDate, "day");
                    }}
                    error=""
                    parentClasses="mb-4"
                    labelClasses="text-sm font-medium text-gray-700 lock-in-date-picker"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <h3 className="mb-3 text-sm font-medium text-gray-900">
            Compare Periods
          </h3>
          <div className="space-y-2">
            {showComparePeriod.map((period, index) => {
              return (
                <Menu.Item
                  key={`${period} ${index}`}
                  onClick={() => setComparePeriodHandler(period)}
                  className="comparePeriodMenu"
                  style={{
                    width: "100%",
                    padding: "8px 16px",
                    textAlign: "left",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    fontSize: "16px",
                    color:
                      selectedComparePeriods === period ? "#1d4ed8" : "#1a202c",
                    background:
                      selectedComparePeriods === period
                        ? "#eff6ff"
                        : "transparent",
                  }}>
                  <svg
                    style={{
                      marginTop: "4px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-git-compare h-4 w-4">
                    <circle cx="18" cy="18" r="3"></circle>
                    <circle cx="6" cy="6" r="3"></circle>
                    <path d="M13 6h3a2 2 0 0 1 2 2v7"></path>
                    <path d="M11 18H8a2 2 0 0 1-2-2V9"></path>
                  </svg>
                  <span>{period}</span>
                </Menu.Item>
              );
            })}
          </div>
        </div>
      </Menu>
    </div>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button className="dateRangeBtn flex h-[37.5px] w-full justify-between rounded-md border border-gray-300 bg-transparent p-2 text-base text-gray-900 shadow-sm hover:border-gray-300 hover:bg-gray-50 focus:border-black">
          {selectedTimePeriod !== customeDateRange
            ? selectedTimePeriod
            : showSelectedDate}{" "}
          <CalendarIcon className="h-5 w-5 text-gray-400" />
        </Button>
      </Dropdown>
    </>
  );
};

export default DateRangeDropDown;
