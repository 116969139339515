import React, { useState, useEffect, useContext } from "react";
import { WalletOutlined } from "@ant-design/icons";
import { ShoppingCartIcon, ChartSquareBarIcon } from "@heroicons/react/outline";
import { GET_CATALOGS_ANALYTICS_ORDER_INFO } from "#queries/index";
import { useQuery } from "#hooks/useQuery";
import { AuthContext } from "#contexts/auth";
import {
  ANALYTICS_DATE_RANGES,
  getComparePeriodDate,
  getDateInDDMMYYYYFormate,
  getNumberOfDaysDifference,
  getStartDateWithHhMmSsFormate,
  getEndDateWithHhMmSsFormate,
} from "#utils/dateRanges";
import {
  getUnixTimeStamp,
  storeInLocalStorage,
} from "#utils/catalogsAndAnalyticsUtils";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import DateRangeDropDown from "#components/catalogs/DateRangeDropDown";
import OrderCards from "#components/catalogs/OrderCards";
import AnalyticsReChart from "#components/catalogs/AnalyticsReChart";
import ProductPerformanceGrid from "#components/catalogs/ProductPerformanceGrid";
import CategoryPerformanceGrid from "#components/catalogs/CategoryPerformanceGrid";

export const getPercentageDetail = (
  total,
  previousTotal,
  selectedComparePeriods,
) => {
  if (selectedComparePeriods && !isNaN(total) && !isNaN(previousTotal)) {
    let isLoss = false;
    let profit = "NA";
    if (Number(previousTotal) > 0) {
      profit =
        ((Number(total) - Number(previousTotal)) / Number(previousTotal)) * 100;
    }
    if (!isNaN(profit) && profit < 0) isLoss = true;
    return {
      profit: !isNaN(profit) ? profit.toFixed(1) : profit,
      isLoss,
    };
  }
  return null;
};

export const getValue = (value) => {
  if (value !== undefined && value !== null) {
    if (!Number.isInteger(value)) {
      return Math.floor(value * 100) / 100;
    }
    return value;
  }
  return 0;
};

const AnalyticsDashboard = () => {
  const getAnalyticsOrderInfoQuery = useQuery(
    GET_CATALOGS_ANALYTICS_ORDER_INFO,
  );
  const auth = useContext(AuthContext);
  const timePeriods = Object.keys(ANALYTICS_DATE_RANGES);
  const defaultSelectedDate = timePeriods[3];
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const getStartEndDate = getDateInDDMMYYYYFormate(
    ANALYTICS_DATE_RANGES[defaultSelectedDate],
  );
  const [startEndDate, setStartEndDate] = useState(getStartEndDate || []);
  const [selectedComparePeriods, setSelectComparePeriods] = useState(null);
  const [analyticsCardsData, setAnalyticsCardsData] = useState(null);
  const [isCardDataLoading, setIsCardDataLoading] = useState(false);
  const daysDifference = getNumberOfDaysDifference(
    startEndDate[0],
    startEndDate[1],
  );
  let comparePeriodDate = [];
  if (selectedComparePeriods) {
    comparePeriodDate = getComparePeriodDate(
      startEndDate,
      selectedComparePeriods,
    );
  }

  const analyticsData = JSON.parse(
    localStorage.getItem("analyticsData") || null,
  );
  const customerSelectHandler = (e) => {
    setSelectedCustomer(e);
    storeInLocalStorage(e, "selectedCustomer");
  };

  const orderCards = [
    {
      class: "bg-blue-50",
      title: "Total Sales",
      IconComponent: WalletOutlined,
      iconClass: "h-6 w-6 text-blue-600",
      total: `$${getValue(analyticsCardsData?.primaryAnalyticsCard?.[0]?.totalSales)}`,
      previousTotal: `$${getValue(analyticsCardsData?.comparedAnalyticsCard?.[0]?.totalSales)}`,
      previousTotalDetail: getPercentageDetail(
        getValue(analyticsCardsData?.primaryAnalyticsCard?.[0]?.totalSales),
        getValue(analyticsCardsData?.comparedAnalyticsCard?.[0]?.totalSales),
        selectedComparePeriods,
      ),
    },
    {
      class: "bg-green-50",
      title: "Total Orders",
      IconComponent: ShoppingCartIcon,
      iconClass: "h-6 w-6 text-green-600",
      total: getValue(
        analyticsCardsData?.primaryAnalyticsCard?.[0]?.totalOrders,
      ),
      previousTotal: getValue(
        analyticsCardsData?.comparedAnalyticsCard?.[0]?.totalOrders,
      ),
      previousTotalDetail: getPercentageDetail(
        getValue(analyticsCardsData?.primaryAnalyticsCard?.[0]?.totalOrders),
        getValue(analyticsCardsData?.comparedAnalyticsCard?.[0]?.totalOrders),
        selectedComparePeriods,
      ),
    },
    {
      class: "bg-purple-50",
      title: "Average Order Value",
      IconComponent: ChartSquareBarIcon,
      iconClass: "h-6 w-6 text-purple-600",
      total: `$${getValue(analyticsCardsData?.primaryAnalyticsCard?.[0]?.averageOrderValue)}`,
      previousTotal: `$${getValue(analyticsCardsData?.comparedAnalyticsCard?.[0]?.averageOrderValue)}`,
      previousTotalDetail: getPercentageDetail(
        getValue(
          analyticsCardsData?.primaryAnalyticsCard?.[0]?.averageOrderValue,
        ),
        getValue(
          analyticsCardsData?.comparedAnalyticsCard?.[0]?.averageOrderValue,
        ),
        selectedComparePeriods,
      ),
    },
  ];

  const getAnalyticsOrderInfo = async () => {
    const customers = [];
    const selectedStartEndDate = [];
    const comparePeriodData = {
      comparePeriod: false,
    };
    if (selectedCustomer) {
      if (selectedCustomer !== "all customer") customers.push(selectedCustomer);
      if (startEndDate?.length > 0) {
        selectedStartEndDate[0] = getUnixTimeStamp(
          getStartDateWithHhMmSsFormate(startEndDate[0]),
        );
        selectedStartEndDate[1] = getUnixTimeStamp(
          getEndDateWithHhMmSsFormate(startEndDate[1]),
        );
      }
      if (selectedComparePeriods) {
        comparePeriodData.comparePeriod = true;
        comparePeriodData["compareStartEndDate"] = [
          getUnixTimeStamp(getStartDateWithHhMmSsFormate(comparePeriodDate[0])),
          getUnixTimeStamp(getEndDateWithHhMmSsFormate(comparePeriodDate[1])),
        ];
      }
      setIsCardDataLoading(true);
      try {
        const response = await getAnalyticsOrderInfoQuery.fetchData({
          filters: {
            customer: customers,
            startEndDate: selectedStartEndDate,
            ...comparePeriodData,
          },
        });
        if (response?.data?.catalogAnalyticsCard) {
          setAnalyticsCardsData({ ...response.data.catalogAnalyticsCard });
        } else {
          setAnalyticsCardsData(null);
        }
      } catch (e) {
      } finally {
        setIsCardDataLoading(false);
      }
    }
  };

  useEffect(() => {
    if (auth?.user?.customersList?.length > 0) {
      const customerList =
        auth?.user?.customersList.map(({ name, id }) => {
          return {
            id,
            name,
          };
        }) ?? [];
      if (customerList.length > 0) {
        const defaultSelectedCustomer = {
          id: "all customer",
          name: "All Customers",
        };
        customerList.unshift(defaultSelectedCustomer);
        setCustomerList(customerList);
        if (!selectedCustomer) {
          setSelectedCustomer(defaultSelectedCustomer?.id);
        }
      }
    }
  }, [auth, selectedCustomer]);

  useEffect(() => {
    if (
      (selectedCustomer && startEndDate?.length > 0) ||
      selectedComparePeriods
    ) {
      getAnalyticsOrderInfo();
    }
  }, [selectedCustomer, startEndDate, selectedComparePeriods]);

  useEffect(() => {
    if (analyticsData) {
      if (
        analyticsData?.selectedCustomer &&
        analyticsData?.selectedCustomer !== selectedCustomer
      ) {
        setSelectedCustomer(analyticsData?.selectedCustomer);
      }
      if (
        analyticsData?.selectedComparePeriods &&
        analyticsData?.selectedComparePeriods !== selectedComparePeriods
      ) {
        setSelectComparePeriods(analyticsData?.selectedComparePeriods);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      if (
        !window.location.pathname.includes("/productPerformance") &&
        !window.location.pathname.includes("/productAnalytics") &&
        !window.location.pathname.includes("/categoriesPerformance")
      ) {
        localStorage.removeItem("analyticsData");
      }
    };
  }, []);

  return (
    <>
      <div className="min-h-screen flex-1 flex-col bg-gray-100 p-6 font-inter">
        <div className="max-w-7xl">
          <div className="mb-6 flex items-center justify-between">
            <h2 className="text-2xl font-semibold">Analytics Dashboard</h2>
          </div>
        </div>
        <div className="mb-6 grid grid-cols-2 gap-4 rounded-lg bg-white p-4 shadow">
          <div>
            <label className="mb-1 block text-sm font-medium text-gray-600">
              Customer
            </label>
            <AutoCompleteSingleSelect
              options={customerList}
              value={selectedCustomer}
              onChange={(e) => {
                customerSelectHandler(e);
              }}
              labelKey="name"
              valueKey="id"
              placeholder="Select an option"
              shouldDeselect={false}
              error=""
              infoText="Select a currency for cost price"
              showNotSelectedError={true}
              inputClasses="border border-gray-300 rounded-md shadow-sm focus:border-black p-2 text-sm"
            />
          </div>
          <div>
            <div className="relative">
              <label className="mb-1 block text-sm font-medium text-gray-600">
                Date Range
              </label>
              <DateRangeDropDown
                startEndDate={startEndDate}
                setStartEndDate={setStartEndDate}
                defaultSelectedDate={defaultSelectedDate}
                selectedComparePeriods={selectedComparePeriods}
                setSelectComparePeriods={setSelectComparePeriods}
                daysDifference={daysDifference}
              />
            </div>
          </div>
        </div>
        <div className="mb-6 grid grid-cols-3 gap-5">
          {orderCards?.length > 0 &&
            orderCards.map((orderInfo, index) => {
              return (
                <div key={`orderInfor-${index}`}>
                  <OrderCards
                    classes={orderInfo?.class}
                    IconComponent={orderInfo?.IconComponent}
                    iconClass={orderInfo?.iconClass}
                    title={orderInfo?.title}
                    total={orderInfo?.total}
                    previousTotal={orderInfo?.previousTotal}
                    previousTotalDetail={orderInfo?.previousTotalDetail}
                    selectedComparePeriods={selectedComparePeriods}
                    isCardDataLoading={isCardDataLoading}
                  />
                </div>
              );
            })}
        </div>
        <div className="mb-6 grid grid-cols-2 gap-6">
          <ProductPerformanceGrid
            startEndDate={startEndDate}
            comparePeriodDate={comparePeriodDate}
            selectedComparePeriods={selectedComparePeriods}
            selectedCustomer={selectedCustomer}
          />
          <CategoryPerformanceGrid
            startEndDate={startEndDate}
            comparePeriodDate={comparePeriodDate}
            selectedComparePeriods={selectedComparePeriods}
            selectedCustomer={selectedCustomer}
          />
        </div>
        <div className="tremor-Card-root rounded-tremor-default ring-tremor-ring shadow-tremor-card dark:ring-dark-tremor-ring dark:shadow-dark-tremor-card border-tremor-brand dark:border-dark-tremor-brand relative mb-6 w-full rounded-lg bg-white p-6 text-left shadow dark:bg-white">
          <p className="text-tremor-title text-tremor-content-strong dark:text-dark-tremor-content-strong text-[17px] font-semibold">
            Revenue Trend
          </p>
          <AnalyticsReChart
            startEndDate={startEndDate}
            comparePeriodDate={comparePeriodDate}
            selectedComparePeriods={selectedComparePeriods}
            selectedCustomer={selectedCustomer}
            daysDifference={daysDifference}
          />
        </div>
      </div>
    </>
  );
};

export default AnalyticsDashboard;
