import React, { useState, useEffect } from "react";
import { useQuery } from "#hooks/useQuery";
import PropTypes from "prop-types";
import _ from "lodash";
import { getCurrencySymbol } from "../../utils/getCurrencySymbol";
import { getPreferredMetricsUnit } from "../../utils/Metrics";
import RateShoppingLtlPallet from "#components/rateShopping/RateShoppingLtlPallet";
import { GET_SHIPMENT_RATES } from "#queries/index";
import { twMerge } from "tailwind-merge";
import dayjs from "dayjs";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";
import NewRateShoppingLtlPallets from "./NewRateShoppingLtlPallets";
import CustomAlert from "#newUiComponents/commons/CustomAlert";
import noResultsImage from "../../static/images/nofilter.png";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import RateShoppingLoader from "#components/rateShopping/RateShoppingLoader";

const ALERT_VISIBILITY_IN_MS = 3000;

const labelClass = {
  Default: "text-blue-800 bg-blue-100",
  "Best Rate": "text-green-800 bg-green-100",
  Fastest: "text-blue-800 bg-blue-100",
  "Best Value": "text-purple-800 bg-purple-100",
  Other: "text-yellow-800 bg-yellow-100",
};

/**
 * Finds the cheapest rate from an array of shipment rates.
 */
const getCheapestRate = (shipmentRates) => {
  if (shipmentRates.length === 0) return null;
  return shipmentRates.reduce((cheapestRate, currentRate) => {
    if (!currentRate.price) return cheapestRate;
    return currentRate.price < (cheapestRate?.price || Infinity)
      ? currentRate
      : cheapestRate;
  }, null);
};

/**
 * Finds the fastest rate (based on estimated delivery days).
 */
const getFastestRate = (shipmentRates) => {
  if (shipmentRates.length === 0) return null;
  return shipmentRates.reduce((fastestRate, currentRate) => {
    if (!currentRate.estimated_delivery_days) return fastestRate;
    if (!fastestRate) return currentRate;
    return currentRate.estimated_delivery_days <
      fastestRate.estimated_delivery_days
      ? currentRate
      : fastestRate;
  }, null);
};

/**
 * Determines the best value rates by combining low price and fast delivery.
 */
const getBestValueRates = (shipmentRates) => {
  const sortedRates = shipmentRates.sort(
    (a, b) =>
      a.price - b.price ||
      a.estimated_delivery_days - b.estimated_delivery_days,
  );
  return sortedRates.slice(0, 3);
};

/**
 * Format and categorize shipment rates
 */
const formatRates = (shipmentRates, preSelectedRate) => {
  if (!shipmentRates.length) return {};

  const cheapestRate = getCheapestRate(shipmentRates);
  const fastestRate = getFastestRate(shipmentRates);
  const bestValueRates = getBestValueRates(shipmentRates);

  const usedRateIds = new Set();

  const defaultRate = preSelectedRate || null;
  if (defaultRate) {
    usedRateIds.add(defaultRate.id);
  }

  const bestValue = bestValueRates.filter((rate) => !usedRateIds.has(rate.id));
  bestValue.forEach((rate) => usedRateIds.add(rate.id));

  const bestRate = !usedRateIds.has(cheapestRate?.id) ? cheapestRate : null;
  if (bestRate) {
    usedRateIds.add(bestRate.id);
  }

  const fastestDelivery = !usedRateIds.has(fastestRate?.id)
    ? fastestRate
    : null;
  if (fastestDelivery) {
    usedRateIds.add(fastestDelivery.id);
  }

  const restRates = shipmentRates.filter((rate) => !usedRateIds.has(rate.id));

  return {
    defaultRate: defaultRate || null,
    bestRate: bestRate || null,
    fastestDelivery: fastestDelivery || null,
    bestValue: bestValue || [],
    restRates: restRates || [],
  };
};

const NewRateShopping = ({
  order,
  preSelectedCarrierRate,
  customer,
  warehouse,
  shippingAddress,
  boxes = [],
  pallets,
  orderSource,
  storedTransportMode,
  shipmentReference,
  onPalletSubmit,
  onRateSelect,
  rate,
  validateAddress,
  transportMode,
  carrierIntegration,
  selectedIncoterms,
  selectedInsuranceProvider,
  insuredValue,
  setShowRateShoppingSlideOver,
}) => {
  const shipmentRatesQuery = useQuery(GET_SHIPMENT_RATES);
  const [formattedRates, setFormattedRates] = useState({});
  const [palletInfo, setPalletInfo] = useState([]);
  const [errors, setErrors] = useState([]);
  const [ratesApiError, setRatesApiError] = useState(null);
  const [selectedRate, setSelectedRate] = useState(rate);
  const { preferredDimensionUnit, preferredWeightUnit } =
    getPreferredMetricsUnit();
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortedRates = (rates) => {
    if (!sortConfig.key) return rates;

    const sortedRates = { ...rates };

    let allRates = [];
    if (sortedRates.defaultRate)
      allRates.push({ ...sortedRates.defaultRate, category: "Default" });
    if (sortedRates.bestRate)
      allRates.push({ ...sortedRates.bestRate, category: "Best Rate" });
    if (sortedRates.fastestDelivery)
      allRates.push({ ...sortedRates.fastestDelivery, category: "Fastest" });
    if (sortedRates.bestValue)
      allRates.push(
        ...sortedRates.bestValue.map((rate) => ({
          ...rate,
          category: "Best Value",
        })),
      );
    if (sortedRates.restRates)
      allRates.push(
        ...sortedRates.restRates.map((rate) => ({
          ...rate,
          category: "Other",
        })),
      );

    allRates.sort((a, b) => {
      let aValue, bValue;

      switch (sortConfig.key) {
        case "price":
          aValue = parseFloat(a.price) || 0;
          bValue = parseFloat(b.price) || 0;
          break;
        case "estimated_delivery_days":
          aValue = a.estimated_delivery_days
            ? parseInt(a.estimated_delivery_days)
            : Infinity;
          bValue = b.estimated_delivery_days
            ? parseInt(b.estimated_delivery_days)
            : Infinity;
          break;
        case "estimated_delivery_date":
          aValue = a.estimated_delivery_date
            ? dayjs(a.estimated_delivery_date)
            : dayjs("9999-12-31");
          bValue = b.estimated_delivery_date
            ? dayjs(b.estimated_delivery_date)
            : dayjs("9999-12-31");
          return sortConfig.direction === "asc"
            ? aValue.diff(bValue)
            : bValue.diff(aValue);
        default:
          aValue = (a[sortConfig.key] || "").toLowerCase();
          bValue = (b[sortConfig.key] || "").toLowerCase();
      }

      // For numeric comparisons (price and delivery days)
      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortConfig.direction === "asc"
          ? aValue - bValue
          : bValue - aValue;
      }

      // For string comparisons
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });

    // Reconstruct the categories
    return {
      defaultRate: allRates.find((rate) => rate.category === "Default"),
      bestRate: allRates.find((rate) => rate.category === "Best Rate"),
      fastestDelivery: allRates.find((rate) => rate.category === "Fastest"),
      bestValue: allRates.filter((rate) => rate.category === "Best Value"),
      restRates: allRates.filter((rate) => rate.category === "Other"),
    };
  };
  const renderSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className="ml-1 h-6 w-6 cursor-pointer"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 9L12 5L16 9M16 15L12 19L8 15"
            stroke="#717679"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    return sortConfig.direction === "asc" ? (
      <ChevronUpIcon className="ml-1 inline h-5 w-5" />
    ) : (
      <ChevronDownIcon className="ml-1 inline h-5 w-5" />
    );
  };

  const segregateAndFormatRates = (rates, preSelectedCarrierRateObj) => {
    const formatted = formatRates(rates, preSelectedCarrierRateObj);
    setFormattedRates(formatted);

    const defaultSelectedRate =
      formatted?.defaultRate ||
      (formatted?.bestValue && formatted.bestValue[0]) ||
      formatted?.bestRate ||
      formatted?.fastestDelivery ||
      (formatted?.restRates && formatted?.restRates[0]) ||
      null;

    setSelectedRate({
      selectedShipmentRateId: defaultSelectedRate?.id || null,
      selectedShipmentSource: defaultSelectedRate?.source || null,
      transportMode,
      selectedCarrierRate: defaultSelectedRate,
      carrier:
        `${defaultSelectedRate?.carrier} - ${defaultSelectedRate?.service}` ||
        null,
    });
  };

  const fetchShipmentRates = async () => {
    setRatesApiError(null);
    setFormattedRates({});
    setErrors([]);

    if (transportMode === "LTL" && palletInfo.length === 0) {
      segregateAndFormatRates([], preSelectedCarrierRate);
      return;
    }

    if (boxes?.length === 0) {
      return;
    }

    const shipmentRatesQueryResponse = await shipmentRatesQuery.fetchData({
      customer,
      warehouse,
      toAddress: shippingAddress,
      boxes: boxes.map((box) => ({
        ...box,
        dimensionUnit: preferredDimensionUnit,
        weightUnit: preferredWeightUnit,
      })),
      pallets: palletInfo.map((pallet) => ({
        ...pallet,
        dimensionUnit: preferredDimensionUnit,
        weightUnit: preferredWeightUnit,
      })),
      orderSource,
      transportMode,
      shipmentReference,
      validateAddress,
      incoterms: selectedIncoterms,
      insuranceProvider: selectedInsuranceProvider,
      insuredValue: insuredValue,
    });
    return shipmentRatesQueryResponse;
  };

  useEffect(() => {
    setPalletInfo(pallets || []);
    fetchShipmentRates();
  }, [pallets]);

  useEffect(() => {
    if (shipmentRatesQuery.data) setRatesApiError(null);
  }, [shipmentRatesQuery.data]);

  useEffect(() => {
    if (shipmentRatesQuery.error) {
      setRatesApiError(shipmentRatesQuery.error);
    }

    if (shipmentRatesQuery.data) {
      segregateAndFormatRates(
        shipmentRatesQuery.data.getShipmentRates.rates,
        preSelectedCarrierRate,
      );
      setErrors(shipmentRatesQuery.data.getShipmentRates.errors);
      setRatesApiError(null);
    }
  }, [
    shipmentRatesQuery.data,
    shipmentRatesQuery.loading,
    shipmentRatesQuery.error,
  ]);

  const onPalletSubmitEvent = (pallets) => {
    const validatedPallets = pallets.map((pallet) => {
      return {
        ...pallet,
        length: parseFloat(pallet.length),
        width: parseFloat(pallet.width),
        height: parseFloat(pallet.height),
        weight: parseFloat(pallet.weight),
      };
    });
    setPalletInfo(validatedPallets);
    onPalletSubmit && onPalletSubmit(validatedPallets, fetchShipmentRates);
  };

  const hasValidRates = (rates) => {
    return (
      (rates.defaultRate && Object.keys(rates.defaultRate).length > 0) ||
      (rates.bestRate && Object.keys(rates.bestRate).length > 0) ||
      (rates.restRates && rates.restRates.length > 0) ||
      (rates.bestValue && rates.bestValue.length > 0) ||
      (rates.fastestDelivery && Object.keys(rates.fastestDelivery).length > 0)
    );
  };

  const renderRateRow = (rate, label) => (
    <tr key={rate.id} className="hover:bg-gray-50">
      <td className="whitespace-nowrap px-6 py-4">
        <div className="flex items-center">
          <input
            type="radio"
            name="rate"
            className="mr-4 text-2C7695"
            checked={selectedRate?.selectedShipmentRateId === rate.id}
            onChange={() =>
              setSelectedRate({
                selectedShipmentRateId: rate.id,
                selectedShipmentSource: rate.source,
                transportMode: storedTransportMode,
                selectedCarrierRate: rate,
                carrier: `${rate?.carrier} - ${rate?.service}`,
              })
            }
          />
          <div className="text-sm font-medium text-gray-900">
            {rate.carrier}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <div className="text-sm font-medium text-gray-900">{rate.service}</div>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <div className="text-sm text-gray-900">
          {getCurrencySymbol(rate.currency)}
          {rate.price}
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <div className="text-sm text-gray-500">
          {rate.estimated_delivery_days || "N/A"} Days
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <div className="text-sm text-gray-500">
          {rate.estimated_delivery_date &&
            dayjs(rate.estimated_delivery_date).format("YYYY-MM-DD")}
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <div className="text-sm text-gray-500">{rate.source || ""}</div>
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <span
          style={label === "Best Value" ? { backgroundColor: "#E9D5FF" } : {}}
          className={twMerge(
            `ml-4 inline-block px-3 py-1 text-sm font-light leading-none ${labelClass[label]} rounded-full`,
          )}>
          {label}
        </span>
      </td>
    </tr>
  );

  return (
    <div className="flex h-full flex-col px-10 font-inter">
      {carrierIntegration && carrierIntegration.carrier && (
        <div>
          <h1 className="mb-4 text-xl font-bold">
            Carrier Details From {orderSource}
          </h1>
          <div className="border-1 mb-4 flex items-center space-x-3 rounded-md border-2 border-[#417492] bg-[#eff6ff] p-3">
            <span className="text-2xl text-blue-400">&#9432;</span>
            <div>
              <p>
                {carrierIntegration.carrier &&
                  carrierIntegration.carrier.length > 0 && (
                    <>
                      <span className="font-bold">Carrier:</span>{" "}
                      <span>{carrierIntegration.carrier}</span>
                    </>
                  )}
                {carrierIntegration.carrier &&
                  carrierIntegration.carrier.length > 0 &&
                  carrierIntegration.carrierService &&
                  carrierIntegration.carrierService.length > 0 &&
                  ", "}
                {carrierIntegration.carrierService &&
                  carrierIntegration.carrierService.length > 0 && (
                    <>
                      <span className="font-bold">Service:</span>{" "}
                      <span>{carrierIntegration.carrierService}</span>
                    </>
                  )}
              </p>
              {carrierIntegration?.notes && (
                <p className="text-gray-500">{carrierIntegration.notes}</p>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col">
        {ratesApiError && transportMode !== "LTL" && (
          <div className="flex w-full flex-col items-center justify-center py-20">
            <img
              src={noResultsImage}
              alt="No results"
              className="mb-4"
              style={{ width: "40%" }}
            />
            <p className="text-center text-base font-semibold text-gray-600">
              {ratesApiError.message}
            </p>
          </div>
        )}
        {ratesApiError && transportMode === "LTL" && (
          <CustomAlert
            id="rateShoppingLtlError"
            type="error"
            message={ratesApiError.message}
          />
        )}
        {errors.map((error, index) => (
          <CustomAlert
            key={index}
            id={"alertRateShoppingErrors" + index}
            type="error"
            message={
              <div>
                <span className="ml-3">{error.source}</span>
                <div className="block">{error?.message}</div>
                <span className="block">
                  <ul className="list-disc pl-10">
                    {error?.errors.map((errorMessage) => (
                      <li key={errorMessage}>{errorMessage}</li>
                    ))}
                  </ul>
                </span>
              </div>
            }
            options={{
              defaultColors: true,
            }}
          />
        ))}
      </div>

      {transportMode === "LTL" && (
        <div>
          <h2 className="mb-4 text-xl font-semibold">Pallet Information</h2>
          <NewRateShoppingLtlPallets
            onPalletSubmit={onPalletSubmitEvent}
            palletInfo={palletInfo}
          />
        </div>
      )}

      {hasValidRates(formattedRates) ? (
        <div>
          <h2 className="mb-4 text-xl font-semibold text-gray-900">
            Carriers & Rates
          </h2>
          <div
            style={{ height: "600px" }}
            className="overflow-x-auto overflow-y-auto">
            <table className="min-w-full table-auto">
              <thead className="sticky top-0 z-5 bg-gray-50">
                <tr>
                  <th className="px-3 py-6 text-left text-sm font-medium text-gray-500">
                    <button
                      className="flex items-center focus:outline-none"
                      onClick={() => handleSort("carrier")}>
                      Carrier Option
                      {renderSortIcon("carrier")}
                    </button>
                  </th>
                  <th className="px-3 py-6 text-left text-sm font-medium text-gray-500">
                    <button
                      className="flex items-center focus:outline-none"
                      onClick={() => handleSort("service")}>
                      Service
                      {renderSortIcon("service")}
                    </button>
                  </th>
                  <th className="px-3 py-6 text-left text-sm font-medium text-gray-500">
                    <button
                      className="flex items-center focus:outline-none"
                      onClick={() => handleSort("price")}>
                      Rate
                      {renderSortIcon("price")}
                    </button>
                  </th>
                  <th className="px-3 py-6 text-left text-sm font-medium text-gray-500">
                    <button
                      className="flex items-center focus:outline-none"
                      onClick={() => handleSort("estimated_delivery_days")}>
                      Delivery ETA
                      {renderSortIcon("estimated_delivery_days")}
                    </button>
                  </th>
                  <th className="px-3 py-6 text-left text-sm font-medium text-gray-500">
                    <button
                      className="flex items-center focus:outline-none"
                      onClick={() => handleSort("estimated_delivery_date")}>
                      Estimated Delivery Date
                      {renderSortIcon("estimated_delivery_date")}
                    </button>
                  </th>
                  <th className="px-3 py-6 text-left text-sm font-medium text-gray-500">
                    <button
                      className="flex items-center focus:outline-none"
                      onClick={() => handleSort("source")}>
                      Provided By
                      {renderSortIcon("source")}
                    </button>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {/* Get sorted rates */}
                {(() => {
                  const sortedRates = getSortedRates(formattedRates);
                  return (
                    <>
                      {sortedRates.defaultRate &&
                        renderRateRow(sortedRates.defaultRate, "Default")}
                      {sortedRates.bestRate &&
                        renderRateRow(sortedRates.bestRate, "Best Rate")}
                      {sortedRates.fastestDelivery &&
                        renderRateRow(sortedRates.fastestDelivery, "Fastest")}
                      {sortedRates.bestValue?.map((rate) =>
                        renderRateRow(rate, "Best Value"),
                      )}
                      {sortedRates.restRates?.map((rate) =>
                        renderRateRow(rate, "Other"),
                      )}
                    </>
                  );
                })()}
              </tbody>
            </table>
          </div>
        </div>
      ) : shipmentRatesQuery.loading ? (
        <RateShoppingLoader />
      ) : !ratesApiError ? (
        <div className="flex w-full flex-col items-center justify-center py-20">
          <img
            src={noResultsImage}
            alt="No results"
            className="mb-4"
            style={{ width: "40%" }}
          />
          <p className="text-center text-base font-semibold text-gray-600">
            No results found.
          </p>
        </div>
      ) : null}

      {hasValidRates(formattedRates) && (
        <div className="sticky bottom-0 z-30 flex h-full w-full items-end justify-end bg-white py-4">
          <PrimaryButton
            height="3rem"
            width="7rem"
            className="mt-2 text-lg font-medium"
            onClick={() => {
              setShowRateShoppingSlideOver(false);
            }}>
            Cancel
          </PrimaryButton>
          <PrimaryButton
            height="3rem"
            minWidth="7rem"
            maxWidth="15rem"
            variant="primary"
            className="ml-6 mt-2 text-lg font-medium"
            onClick={() => {
              onRateSelect(selectedRate);
              setShowRateShoppingSlideOver(false);
            }}>
            Confirm
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

NewRateShopping.propTypes = {
  customer: PropTypes.string.isRequired,
  warehouse: PropTypes.string.isRequired,
  shippingAddress: PropTypes.object.isRequired,
  boxes: PropTypes.array.isRequired,
  pallets: PropTypes.array,
  orderSource: PropTypes.string.isRequired,
  storedTransportMode: PropTypes.string.isRequired,
  shipmentReference: PropTypes.string.isRequired,
  onPalletSubmit: PropTypes.func,
  onRateSelect: PropTypes.func.isRequired,
  rate: PropTypes.object,
  validateAddress: PropTypes.bool,
  transportMode: PropTypes.string,
  carrierIntegration: PropTypes.object,
  selectedInsurance: PropTypes.string,
  setShowRateShoppingSlideOver: PropTypes.func,
};

export default NewRateShopping;
