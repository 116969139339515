import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import NewModal from "#newUiComponents/commons/NewModal";
import SlideOverPanel from "#components/common/SlideOverPanel";
import {
  fbaPackingTabsEnums,
  fbaPackingLtlFlowTabsEnums,
  POLLING_INTERVAL,
} from "../FbaUtils";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import CustomTabSelection from "#newUiComponents/commons/CustomTabSelection";
import {
  startAmazonInboundPlanCreation,
  getPackingOptions,
  confirmPackingOptions,
  cancelAmazonInboundPlan,
  setPackingInformation,
  getPlacementsOptions,
  getTransportationOptions,
  confirmPlacementOption,
  confirmTransportationOptions,
  getDeliveryWindowOptions,
  confirmDeliveryWindowOptions,
  listShipments,
  confirmPackingCompleted,
  getErrorsInInboundPlan,
  updateShipmentTrackingInformation,
  setPackerBoxes,
  dropItems,
} from "#redux/FbaPacking/fbaPackingActions";
import { listFbaInboundPlans } from "#redux/FbaInbound/fbaInboundActions";
import { resetFbaInboundState } from "#redux/FbaInbound/fbaInboundSlice";
import {
  useWeightUnitAbbreviation,
  useDimensionUnitAbbreviation,
} from "#utils/Metrics";

import { AppStateContext } from "#contexts/appState";
import SelectPackingOptions from "./SelectPackingOptions";
import SelectPlacementOptions from "./SelectPlacementOptions";
import SelectDeliveryOptions from "./SelectDeliveryOptions";
import FbaShipmentDetails from "./FbaShipmentDetails";
import { resetFbaPackingState } from "#redux/FbaPacking/fbaPackingSlice";
import FbaRateShopping from "./FbaRateShopping";
import { AuthContext } from "#contexts/auth";
import FbaProductTableReadOnly from "../FbaProductTableReadOnly";
import CustomPopover from "#newUiComponents/commons/CustomPopover";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PauseIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import { useQuery } from "#hooks/useQuery";
import { useHistory } from "react-router-dom";
import {
  RESTART_PACK,
  SET_PREP_DETAILS_ON_AMAZON,
  UPDATE_SHIPMENT_TRACKING_INFORMATION,
} from "#mutations/index";
import CustomPopconfirm from "#newUiComponents/commons/CustomPopConfirm";
import PackingBoxInformation from "./PackingBoxInformation";
import ReviewBoxesModal from "./ReviewBoxesModal";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import { LIST_PREP_DETAILS_FROM_AMAZON } from "#queries/index";

const FbaPackingBatch = ({
  order,
  currentBatch,
  pauseActivity,
  boxTypes,
  confirmDropoff,
  cancelItem,
  getCurrentBatch,
  confirmTransportInformation,
  restartActivity,
}) => {
  const dispatch = useDispatch();
  const appState = useContext(AppStateContext);
  const auth = useContext(AuthContext);
  const notify = CustomNotification();
  const history = useHistory();
  const intervalRefs = useRef([]); // Store all active interval IDs
  const shipmentDetailsRef = useRef(null);

  const updateCarrierInfoQuery = useQuery(UPDATE_SHIPMENT_TRACKING_INFORMATION);
  const listPrepDetailsFromAmazonQuery = useQuery(
    LIST_PREP_DETAILS_FROM_AMAZON,
  );
  const setPrepDetailsOnAmazonQuery = useQuery(SET_PREP_DETAILS_ON_AMAZON);

  const preferredWeightUnit = useWeightUnitAbbreviation() || "LB";
  const preferredDimensionUnit = useDimensionUnitAbbreviation() || "IN";

  const { listInboundPlans } = useSelector((state) => state.fbaInbound);

  const {
    amazonInboundPlanCreationResult,
    packingOptionsResult,
    packingInformationResult,
    getPlacementOptionsResult,
    getDeliverWindowOptionsResult,
    getTransportationsOptionsResult,
    listShipmentsResult,
  } = useSelector((state) => state.fbaPacking);

  const [showBoxDetailsConfirmationModal, setShowBoxDetailsConfirmationModal] =
    useState(false);
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [selectedPackingOption, setSelectedPackingOption] = useState(null);
  const [selectedPlacementOption, setSelectedPlacementOption] = useState(null);
  const [selectedTransportationOption, setSelectedTransportationOption] =
    useState({});
  const [selectedDeliveryWindow, setSelectedDeliveryWindow] = useState([]);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  const [transportationRates, setTransportationRates] = useState([]);
  const [shouldConfirmTransportation, setShouldConfirmTransportation] =
    useState(false);

  const [fbaTabs, setFbaTabs] = useState(fbaPackingTabsEnums);

  const [formattedGroups, setFormattedGroups] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);

  const [trackingInfo, setTrackingInfo] = useState({});
  const [shipmentData, setShipmentData] = useState([]);

  const [activeTab, setActiveTab] = useState("");

  const addInterval = (id) => {
    intervalRefs.current.push(id);
  };

  const allCarriersArePartnered = (transportationOptions, shipmentIds) => {
    return shipmentIds.every((shipmentId) => {
      const shipmentOptions = transportationOptions.find(
        (opt) => opt.shipmentId === shipmentId,
      );
      return shipmentOptions?.transportationOptions.every(
        (option) => option.shippingSolution === "AMAZON_PARTNERED_CARRIER",
      );
    });
  };

  const clearAllIntervals = () => {
    intervalRefs.current.forEach((id) => clearInterval(id)); // Clear each interval
    intervalRefs.current = []; // Reset the array
    console.log("All intervals cleared.");
  };

  const loadingOverlayHtmlContent = (
    <div className="mt-8 flex flex-col items-center justify-center gap-4 p-3">
      <div className="flex items-center gap-4">
        <PrimaryButton
          onClick={() => {
            pauseActivity("PAUSE_RIGHT_AWAY");
            appState.removeLoadingOverlay();
            appState.removeLoading();
            clearAllIntervals();
          }}
          variant="secondary"
          className="flex items-center gap-2"
          height="2.5rem"
          minWidth="8rem"
          primaryColor="red-500"
          borderColor="red-500"
          textColor="red-500">
          <PauseIcon className="h-4 w-4" />
          Pause Packing
        </PrimaryButton>
        <PrimaryButton
          onClick={() => {
            handleCancelInboundPlan(true);
            appState.removeLoadingOverlay();
            appState.removeLoading();
            clearAllIntervals();
          }}
          variant="primary"
          className="flex items-center gap-2"
          height="2.5rem"
          minWidth="8rem"
          primaryColor="primaryAccent">
          <RefreshIcon className="h-4 w-4" />
          Restart Packing
        </PrimaryButton>
      </div>
      <div className="text-xs text-gray-500">
        Note: Restarting will clear current progress and begin from the start
      </div>
    </div>
  );

  const pollPackingOptions = () => {
    appState.setLoadingOverlay(
      "Packing options are being Generated.",
      "",
      loadingOverlayHtmlContent,
    );
    const intervalId = setInterval(() => {
      fetchPackingOptions(
        (data) => {
          if (data?.errors?.length) {
            notify.error(data.errors[0].message, data.errors[0]?.details || "");
            appState.removeLoadingOverlay();
            clearInterval(intervalId); // Stop polling on error
          } else if (data?.packingOptionsWithGroupItems?.length) {
            setSelectedPackingOption(
              data.packingOptionsWithGroupItems[0].packingOptionId,
            );
            setActiveTab(fbaPackingTabsEnums.PACKING_OPTIONS);
            // restartActivity(() => {}, true);
            setSlideOverOpen(true);
            appState.removeLoadingOverlay();
            clearInterval(intervalId); // Stop polling when data is received
          } else {
            appState.setLoadingOverlay(
              "Packing options are being Generated.",
              "",
              loadingOverlayHtmlContent,
            );
            console.log("Polling for packing options...");
          }
        },
        (error) => {
          notify.error("Failed to fetch packing options", error);
          clearInterval(intervalId); // Stop polling on API error
          appState.removeLoadingOverlay();
        },
      );
    }, POLLING_INTERVAL);

    addInterval(intervalId); // Add interval ID to the reference
  };

  const pollPlacementOptions = (
    fetchTransportationOptions,
    onBoxInformation,
  ) => {
    appState.setLoadingOverlay(
      "Placements options are being Generated.",
      "",
      loadingOverlayHtmlContent,
    );
    const intervalId = setInterval(() => {
      fetchPlacementOptions(
        (data) => {
          if (data?.errors?.length) {
            notify.error(data.errors[0].message, data.errors[0]?.details || "");
            appState.removeLoadingOverlay();
            clearInterval(intervalId);
          } else if (data?.placementOptionsWithShipmentBoxesAndItems?.length) {
            appState.removeLoadingOverlay();
            clearInterval(intervalId);

            // Find option with lowest placement fee
            const optionWithLowestFee =
              data.placementOptionsWithShipmentBoxesAndItems.reduce(
                (lowestOption, currentOption) => {
                  const currentFee =
                    currentOption.fees.find(
                      (fee) => fee.target === "Placement Services",
                    )?.value?.amount || 0;
                  const lowestFee =
                    lowestOption.fees.find(
                      (fee) => fee.target === "Placement Services",
                    )?.value?.amount || 0;
                  return currentFee < lowestFee ? currentOption : lowestOption;
                },
                data.placementOptionsWithShipmentBoxesAndItems[0],
              );

            if (!fetchTransportationOptions) {
              if (!onBoxInformation) {
                setFbaTabs(fbaPackingLtlFlowTabsEnums);
                setActiveTab(fbaPackingLtlFlowTabsEnums.PLACEMENT_OPTIONS);
                // restartActivity(() => {}, true);
                setSlideOverOpen(true);
                setSelectedPlacementOption(
                  optionWithLowestFee.placementOptionId,
                );
              }
            } else {
              setSelectedPlacementOption(optionWithLowestFee.placementOptionId);
            }

            const shipmentIdsList =
              data.placementOptionsWithShipmentBoxesAndItems
                .map((grouping) => grouping.shipmentIds)
                .flat()
                .filter(Boolean);

            if (fetchTransportationOptions)
              pollTransportationOptions(shipmentIdsList.length);
          } else {
            appState.setLoadingOverlay(
              "Placements options are being Generated.",
              "",
              loadingOverlayHtmlContent,
            );
            console.log("Polling for placement options...");
          }
        },
        (error) => {
          notify.error("Failed to fetch placement options", error);
          clearInterval(intervalId);
          appState.removeLoadingOverlay();
        },
      );
    }, POLLING_INTERVAL);

    addInterval(intervalId);
  };

  const pollTransportationOptions = (shipmentIdsListLength, isLtlFlow) => {
    if (isLtlFlow)
      appState.setLoadingOverlay(
        "Transportation options are being Generated.",
        "",
        loadingOverlayHtmlContent,
      );

    const intervalId = setInterval(() => {
      fetchTransportationOptions(
        (data) => {
          if (data?.errors?.length) {
            notify.error(data.errors[0].message, data.errors[0]?.details || "");
            clearInterval(intervalId); // Stop polling on error
            appState.removeLoadingOverlay();
          } else if (
            data?.transportationOptionsPerShipment?.length ===
            shipmentIdsListLength
          ) {
            appState.removeLoadingOverlay();
            notify.success(
              "Fetched tranportation options for all shipments successfully",
            );
            clearInterval(intervalId); // Stop polling when data is received
          } else {
            console.log("Polling for transportation options...");
          }
        },
        (error) => {
          notify.error("Failed to fetch transportation options", error);
          clearInterval(intervalId); // Stop polling on API error
          appState.removeLoadingOverlay();
        },
      );
    }, POLLING_INTERVAL);

    addInterval(intervalId); // Add interval ID to the reference
  };

  const pollShipmentLists = () => {
    appState.setLoadingOverlay(
      "Shipment list with labels is being Generated.",
      "",
      loadingOverlayHtmlContent,
    );
    pollAmazonErrorsList();
    const intervalId = setInterval(() => {
      fetchShipmentsList(
        (data) => {
          if (data?.errors?.length) {
            notify.error(data.errors[0].message, data.errors[0]?.details || "");
            appState.removeLoadingOverlay();
            clearInterval(intervalId); // Stop polling on error
          } else if (
            data.entities.length > 0 &&
            validateShipments(data?.entities)
          ) {
            appState.removeLoadingOverlay();
            notify.success("Fetched shipments list successfully");
            appState.removeLoading();
            clearAllIntervals();
            clearInterval(intervalId); // Stop polling when data is received
          } else {
            appState.setLoadingOverlay(
              "Shipment list with labels is being Generated.",
              "",
              loadingOverlayHtmlContent,
            );
            console.log("Polling for shipment list...");
          }
        },
        (error) => {
          notify.error("Failed to fetch shipment list", error);
          appState.removeLoading();
          appState.removeLoadingOverlay();
          clearAllIntervals();
          clearInterval(intervalId); // Stop polling on API error
        },
      );
    }, POLLING_INTERVAL);

    addInterval(intervalId); // Add interval ID to the reference
  };

  const pollAmazonErrorsList = () => {
    // appState.setLoading();
    const intervalId = setInterval(() => {
      fetchAmazonErrorsList(
        (data) => {
          if (data?.errors?.length) {
            notify.error(data.errors[0].message, data.errors[0]?.details || "");
            appState.removeLoading();
            appState.removeLoadingOverlay();
            clearInterval(intervalId); // Stop polling on error
            clearAllIntervals();
          } else {
            console.log("Polling for errors list...");
          }
        },
        (error) => {
          // appState.removeLoading();
          notify.error("Failed to fetch erros list", error);
          clearInterval(intervalId); // Stop polling on API error
        },
      );
    }, POLLING_INTERVAL);

    addInterval(intervalId); // Add interval ID to the reference
  };

  const pollDeliveryWindowOptions = () => {
    appState.setLoadingOverlay(
      "Delivery window options are being Generated.",
      "",
      loadingOverlayHtmlContent,
    );
    const intervalId = setInterval(() => {
      const inboundPlan =
        listInboundPlans?.data?.entities?.[0]?.inboundPlanDetails;

      const selectedPlacementOptionData = inboundPlan?.selectedPlacementOption
        ?.placementOptionId
        ? inboundPlan?.selectedPlacementOption
        : JSON.parse(localStorage.getItem("selectedPlacementOptionData"));

      const shipmentIdsList = selectedPlacementOptionData.shipmentIds || [];

      fetchDeliveryWindowOptions(
        (data) => {
          if (data?.errors?.length) {
            notify.error(data.errors[0].message, data.errors[0]?.details || "");
            appState.removeLoadingOverlay();
            clearInterval(intervalId); // Stop polling on error
          } else if (
            data?.deliveryWindowOptionsPerShipment?.length ===
            shipmentIdsList.length
          ) {
            appState.removeLoadingOverlay();
            clearInterval(intervalId); // Stop polling when data is received
          } else {
            appState.setLoadingOverlay(
              "Delivery window options are being Generated.",
              "",
              loadingOverlayHtmlContent,
            );
            console.log("Polling for delivery window options...");
          }
        },
        (error) => {
          appState.removeLoadingOverlay();
          notify.error("Failed to fetch delivery window options", error);
          clearInterval(intervalId); // Stop polling on API error
        },
      );
    }, POLLING_INTERVAL);

    addInterval(intervalId); // Add interval ID to the reference
  };

  const handleConfirmPlacementOption = () => {
    const isLtlFlow = Boolean(fbaTabs.TRANSPORTATION_OPTIONS);
    const selectedPlacementOptionData =
      getPlacementOptionsResult.data?.placementOptionsWithShipmentBoxesAndItems?.find(
        (option) => option.placementOptionId === selectedPlacementOption,
      );
    const inboundPlan = listInboundPlans?.data?.entities[0];

    if (!selectedPlacementOptionData) {
      notify.error("Selected placement option not found");
      return;
    }

    const allShipmentsHaveRates = selectedPlacementOptionData.shipmentIds.every(
      (shipmentId) =>
        selectedTransportationOption[shipmentId]?.transportationOptionId,
    );

    const areCarriersPartnered = selectedPlacementOptionData.shipmentIds.every(
      (shipmentId) => {
        return selectedTransportationOption[shipmentId]?.isPartnered;
      },
    );

    if (!allShipmentsHaveRates && !isLtlFlow) {
      notify.warning("Please select rates for all locations before confirming");
      return;
    }

    const confirmPlacementVariables = {
      inboundId: inboundPlan?.inboundPlanId,
      placementOptionId: selectedPlacementOption,
      customerId: inboundPlan?.customer,
      warehouseId: inboundPlan?.warehouse,
    };
    if (!isLtlFlow) {
      const areRatesValid = handleConfirmTransportationOptions(
        selectedPlacementOptionData.shipmentIds,
        true,
      );
      if (!areRatesValid) return;
    }

    appState.setLoading();

    const successCallback = async (data) => {
      try {
        if (data?.status === "SUCCESS") {
          // Store data in localStorage first
          localStorage.setItem(
            "selectedPlacementOptionData",
            JSON.stringify(selectedPlacementOptionData),
          );

          appState.removeLoadingOverlay();
          clearAllIntervals();
          notify.success("Placement option confirmed successfully");

          if (fbaTabs.TRANSPORTATION_OPTIONS) {
            setActiveTab(fbaPackingLtlFlowTabsEnums.BOX_INFORMATION);
          } else {
            // if (areCarriersPartnered) {
            //   handleConfirmTransportationOptions(
            //     selectedPlacementOptionData.shipmentIds,
            //     false,
            //     true,
            //   );
            // } else {
            handleConfirmTransportationOptions(
              selectedPlacementOptionData.shipmentIds,
              false,
            );
            //   setActiveTab(fbaTabs.DELIVERY_WINDOW);
            //   // Add a small delay before polling to ensure state updates are complete
            //   setTimeout(() => {
            //     pollDeliveryWindowOptions();
            //   }, 100);
            // // }
          }
        } else if (data?.errors?.length > 0) {
          notify.error(data.errors[0].message, data.errors[0]?.details || "");
        } else {
          notify.error(
            "An unexpected error occurred while confirming placement option",
          );
        }
      } catch (error) {
        notify.error("Error during post-confirmation process");
        console.error("Post-confirmation error:", error);
      } finally {
        appState.removeLoading();
      }
    };

    const errorCallback = (error) => {
      appState.removeLoading();
      notify.error("Failed to confirm placement option", error);
    };

    dispatch(
      confirmPlacementOption({
        variables: confirmPlacementVariables,
        successCallback,
        errorCallback,
      }),
    );
  };
  const validateShipments = (entities) => {
    if (!Array.isArray(entities)) return false;

    const readyShipments = entities.filter((s) => s.status === "READY_TO_SHIP");
    if (readyShipments.length > 0) return false;

    const allShipped = entities.every((s) => s.status === "SHIPPED");
    return allShipped;
  };

  const handleConfirmTransportationOptions = (shipmentIds, toStore = false) => {
    const inboundPlan = listInboundPlans?.data?.entities[0];
    let transportationOptions;

    const validateSameCarrier = (options) => {
      if (options.length <= 1) return true;
      const firstCarrier = options[0].carrier.name;
      return (
        !options.some((opt) => opt.carrier.name !== firstCarrier) ||
        notify.error(
          "Please select rates from the same carrier for all shipments",
        )
      );
    };

    const mapTransportationOption = (shipmentId) => {
      const selectedOption = selectedTransportationOption[shipmentId];
      const shipmentData =
        getTransportationsOptionsResult?.data?.transportationOptionsPerShipment?.find(
          (opt) => opt.shipmentId === shipmentId,
        );

      const transportationOption = shipmentData?.transportationOptions?.find(
        (opt) =>
          opt.transportationOptionId ===
            selectedOption?.transportationOptionId &&
          opt.carrier.alphaCode ===
            (selectedOption?.alphaCode || selectedOption?.carrier?.alphaCode),
      );

      return {
        transportOptionId: selectedOption.transportationOptionId,
        shipmentId,
        shippingSolution: transportationOption.shippingSolution,
        carrier: transportationOption.carrier,
        carrierAppointment: transportationOption.carrierAppointment,
        preconditions: transportationOption.preconditions,
        quote: transportationOption.quote,
        shippingMode: transportationOption.shippingMode,
      };
    };

    transportationOptions = shipmentIds.map(mapTransportationOption);
    if (!validateSameCarrier(transportationOptions)) return false;

    const areAllPartnered = transportationOptions.every(
      (opt) => opt.shippingSolution === "AMAZON_PARTNERED_CARRIER",
    );

    if (toStore) {
      localStorage.setItem(
        "transportationOptions",
        JSON.stringify(transportationOptions),
      );
      return true;
    }

    const variables = {
      inboundId: inboundPlan?.inboundPlanId,
      customerId: inboundPlan?.customer,
      warehouseId: inboundPlan?.warehouse,
      transportationOptions: transportationOptions.map((opt) => ({
        transportationOptionId:
          opt.transportOptionId || opt.transportationOptionId,
        shipmentId: opt.shipmentId,
        carrier: opt.carrier,
      })),
    };

    appState.setLoading();

    const successCallback = (data) => {
      appState.removeLoading();
      if (data?.status === "SUCCESS") {
        notify.success("Transportation options confirmed successfully");

        if (areAllPartnered) {
          setActiveTab(fbaTabs.LABEL_GENERATION);
          pollShipmentLists();
        } else {
          setActiveTab(fbaTabs.DELIVERY_WINDOW);
          pollDeliveryWindowOptions();
        }
        setSlideOverOpen(true);
      } else {
        notify.error(
          data?.errors?.[0]?.message ||
            "Failed to confirm transportation options",
          data?.errors?.[0]?.details || "",
        );
      }
    };

    dispatch(
      confirmTransportationOptions({
        variables,
        successCallback,
        errorCallback: (error) => {
          appState.removeLoading();
          notify.error("Failed to confirm transportation options", error);
        },
      }),
    );
  };
  const handleConfirmationForTransportationOptionsLtl = () => {
    const getShipmentIds = () => {
      if (placementOptionForLtl?.shipmentIds) {
        return placementOptionForLtl.shipmentIds;
      }

      if (
        getPlacementOptionsResult?.data
          ?.placementOptionsWithShipmentBoxesAndItems?.length
      ) {
        const selectedOption =
          getPlacementOptionsResult.data.placementOptionsWithShipmentBoxesAndItems.find(
            (option) => option.placementOptionId === selectedPlacementOption,
          );

        if (selectedOption?.shipmentIds) {
          return selectedOption.shipmentIds;
        }
      }
      const inboundPlanShipmentIds =
        listInboundPlans?.data?.entities?.[0]?.inboundPlanDetails
          ?.selectedPlacementOption?.shipmentIds;

      if (inboundPlanShipmentIds) {
        return inboundPlanShipmentIds;
      }
      try {
        const storedData = localStorage.getItem("selectedPlacementOptionData");
        return storedData ? JSON.parse(storedData)?.shipmentIds : null;
      } catch (error) {
        console.error("Error parsing stored placement data:", error);
        return null;
      }
    };
    const handleConfirm = () => {
      const shipmentIds = getShipmentIds();

      if (!shipmentIds) {
        notify.error("No shipment IDs found");
        return;
      }

      handleConfirmTransportationOptions(shipmentIds);
    };

    appState.showNewConfirmation(
      "Confirm Transportation Options",
      "Are you sure you want to confirm the transportation options for LTL shipments?",
      handleConfirm,
      appState.hideConfirmation,
    );
  };

  const handleRateSelection = (
    shipmentId,
    transportationOptionId,
    uniqueId,
    carrierName,
    isPartnered,
  ) => {
    setSelectedTransportationOption((prev) => ({
      ...prev,
      [shipmentId]: {
        transportationOptionId,
        alphaCode: uniqueId?.split("_")?.[1],
        uniqueId:
          uniqueId ||
          `${transportationOptionId}_${
            getTransportationsOptionsResult?.data?.transportationOptionsPerShipment
              .find((opt) => opt.shipmentId === shipmentId)
              ?.transportationOptions.find(
                (rate) =>
                  rate.transportationOptionId === transportationOptionId,
              )?.carrier.alphaCode
          }`,
        carrierName,
        isPartnered,
      },
    }));
  };

  const handleConfirmDeliveryWindowOptions = (selectedDeliveryWindow) => {
    const inboundPlan = listInboundPlans?.data?.entities[0];

    // Create the payload based on selectedDeliveryWindow
    const deliveryWindowOptions = Object.keys(selectedDeliveryWindow).map(
      (shipmentId) => ({
        deliveryWindowOptionId: selectedDeliveryWindow[shipmentId],
        shipmentId,
      }),
    );

    // Check if all delivery windows are selected
    const hasUnselectedWindows = deliveryWindowOptions.some(
      (option) => !option.deliveryWindowOptionId,
    );

    if (hasUnselectedWindows) {
      notify.error("Please select a delivery window for all shipments.");
      return;
    }

    // Prepare variables for the action
    const variables = {
      inboundId: inboundPlan.inboundPlanId,
      customerId: inboundPlan.customer,
      warehouseId: inboundPlan.warehouse,
      deliveryWindowOptions,
    };

    if (deliveryWindowOptions.length === 0)
      return notify.error("Please select a delivery window for all shipments.");
    appState.setLoading();
    // Dispatch the action
    dispatch(
      confirmDeliveryWindowOptions({
        variables,
        successCallback: (data) => {
          appState.removeLoading();
          if (data?.status === "SUCCESS") {
            notify.success("Delivery windows confirmed successfully!");

            const selectedPlacementOptionData =
              getPlacementOptionsResult.data?.placementOptionsWithShipmentBoxesAndItems?.find(
                (option) =>
                  option.placementOptionId === selectedPlacementOption,
              );
            // const shipmentIdsStoredInLS = JSON.parse(
            //   localStorage.getItem("transportationOptions"),
            // ).map((option) => option.shipmentId);

            // const shipmentIds =
            //   selectedPlacementOptionData?.shipmentIds || shipmentIdsStoredInLS;
            // if (fbaTabs.TRANSPORTATION_OPTIONS) {
            setActiveTab(fbaPackingLtlFlowTabsEnums.LABEL_GENERATION);
            pollShipmentLists();
            // } else if (!fbaTabs.TRANSPORTATION_OPTIONS)
            //   handleConfirmTransportationOptions(shipmentIds);
          } else {
            appState.removeLoading();
            notify.error(
              data?.errors?.[0]?.message ||
                "Failed to confirm delivery windows.",
              data?.errors?.[0]?.details || "",
            );
          }
        },
        errorCallback: (error) => {
          appState.removeLoading();
          notify.error("Error confirming delivery windows", error);
        },
      }),
    );
  };

  // Add confirmation for the button click
  const handleConfirmationForPlacementOptions = () => {
    const title = Boolean(fbaTabs?.TRANSPORTATION_OPTIONS)
      ? "Confirm Placement Option"
      : "Confirm Placement and Transportation Options";
    const description = Boolean(fbaTabs?.TRANSPORTATION_OPTIONS)
      ? "Are you sure you want to confirm the selected placement option?"
      : "Are you sure you want to confirm the selected placement and transportation options?";
    appState.showNewConfirmation(
      title,
      description,
      handleConfirmPlacementOption,
      appState.hideConfirmation,
    );
  };

  const handleConfirmationForDeliveryWindowOptions = (
    selectedDeliveryWindow,
  ) => {
    appState.showNewConfirmation(
      "Confirm Delivery window Option",
      "Are you sure you want to confirm the selected delivery window option?",
      () => handleConfirmDeliveryWindowOptions(selectedDeliveryWindow),
      appState.hideConfirmation,
    );
  };

  const handleDisableBoxConfirmationCta = (formattedGroups) => {
    const areAllGroupItemsAssigned = (group) => {
      return group.items.every((item) => item.quantity === 0);
    };

    // Validate the formatted groups
    const areAllGroupsAssigned = formattedGroups.every(
      areAllGroupItemsAssigned,
    );
    if (areAllGroupsAssigned) return true;
    else return false;
  };

  const handleConfirmBoxContents = async (
    successCallback = () => {},
    errorCallback = () => {},
  ) => {
    // Get all existing boxes but update weights from formatted groups
    const boxesWithUpdatedWeights = currentBatch.boxes.map((box) => {
      const groupName = box.name.split("_")[0];
      const boxName = box.name.split("_")[1];
      const group = formattedGroups.find((g) => g.groupName === groupName);
      const updatedBox = group?.boxes.find((b) => b.boxName === boxName);

      return {
        name: box.name,
        height: box.height,
        width: box.width,
        length: box.length,
        weight: updatedBox?.weight || box.weight || box.maxWeight || 0,
      };
    });

    const variables = {
      id: currentBatch.id,
      boxes: boxesWithUpdatedWeights,
      confirmedBoxContents: true,
      orderId: order.id,
      commodityContent: [],
    };

    try {
      await dispatch(
        setPackerBoxes({
          variables,
          successCallback,
          errorCallback: (error) => {
            notify.error(error?.message || "Failed to confirm box contents");
          },
        }),
      );
    } catch (error) {
      notify.error("Failed to confirm box contents");
    }
  };
  const handleSetPackingInformation = (formattedGroups) => {
    if (!handleDisableBoxConfirmationCta(formattedGroups)) {
      notify.error(
        "Not all items in the groups have been assigned to boxes. Please ensure all items are packed before confirming.",
      );
      return;
    }

    appState.showNewConfirmation(
      "Confirm",
      "Are you sure you want to set the packing information for this plan?",
      () => {
        const variables = {
          ...handleFormatDataForSetPackingInformation(formattedGroups),
        };

        const successCallback = (data) => {
          if (data?.status === "SUCCESS") {
            handleConfirmBoxContents(() => {
              notify.success("Packing information set successfully");
              getCurrentBatch();
              if (fbaTabs.TRANSPORTATION_OPTIONS) {
                setActiveTab(fbaPackingLtlFlowTabsEnums.TRANSPORTATION_OPTIONS);
              } else if (!fbaTabs.TRANSPORTATION_OPTIONS) {
                setActiveTab(fbaPackingTabsEnums.PLACEMENT_OPTIONS);
                pollPlacementOptions(true);
              }
            });
          } else if (data?.errors) {
            // Format error message by replacing packingGroupId with GroupName
            const errorMessage = data.errors[0].message;
            const formattedError = formattedGroups.reduce((message, group) => {
              return message.replace(
                new RegExp(group.packingGroupId, "g"),
                group.groupName,
              );
            }, errorMessage);

            notify.error(formattedError, data.errors[0]?.details || "");
          } else {
            notify.error("Failed to set packing information");
          }
        };

        const errorCallback = (error) => {
          // Format error message if it contains packingGroupId
          const formattedError = formattedGroups.reduce((message, group) => {
            return message.replace(
              new RegExp(group.packingGroupId, "g"),
              group.groupName,
            );
          }, error);

          notify.error(formattedError);
        };

        dispatch(
          setPackingInformation({ variables, successCallback, errorCallback }),
        );
      },
      appState.hideConfirmation,
    );
  };

  const handleFormatDataForSetPackingInformation = (formattedGroups) => {
    const inboundPlan = listInboundPlans?.data?.entities[0];
    const packageGroupings = formattedGroups.map((group) => ({
      ...(fbaTabs.TRANSPORTATION_OPTIONS
        ? { shipmentId: group.packingGroupId }
        : !fbaTabs.TRANSPORTATION_OPTIONS
          ? { packingGroupId: group.packingGroupId }
          : {}),
      boxes: group.boxes.map((box) => {
        // Group items by SKU or unique identifier
        const groupedItems = box.items.reduce((acc, item) => {
          const key = item.msku || item.asin;
          if (!acc[key]) {
            acc[key] = {
              asin: item.itemInPackingGroup.asin,
              msku: item.itemInPackingGroup.msku,
              labelOwner: item.itemInPackingGroup.labelOwner || "SELLER",
              prepOwner: item.itemInPackingGroup.prepOwner || "SELLER",
              expiration:
                item.bestByDate ||
                item.itemInPackingGroup.expiryDate ||
                item.expiryDate ||
                item.expiration,
              quantity: 0,
            };
          }
          acc[key].quantity++;
          return acc;
        }, {});

        // Convert the grouped items to an array
        const items = Object.values(groupedItems);

        return {
          weight: {
            unit: preferredWeightUnit.toUpperCase() || "LB",
            value: box.weight,
          },
          quantity: 1,
          items,
          dimensions: {
            height: parseFloat(box.dimensions.height),
            length: parseFloat(box.dimensions.length),
            unitOfMeasurement: preferredDimensionUnit.toUpperCase() || "IN",
            width: parseFloat(box.dimensions.width),
          },
          contentInformationSource: box.source || group.currentSource,
        };
      }),
    }));

    return {
      inboundId: inboundPlan?.inboundPlanId,
      warehouseId: inboundPlan?.warehouse,
      customerId: inboundPlan?.customer,
      packageGroupings,
    };
  };

  const fetchPackingOptions = (
    successCallback = () => {},
    errorCallback = () => {},
  ) => {
    const packingOptionsVariables = {
      inboundId: order?.inboundPlanId,
    };

    dispatch(
      getPackingOptions({
        variables: packingOptionsVariables,
        successCallback,
        errorCallback,
      }),
    );
  };

  const fetchPlacementOptions = (
    successCallback = () => {},
    errorCallback = () => {},
  ) => {
    const placementOptionsVariables = {
      inboundId: order?.inboundPlanId,
    };
    dispatch(
      getPlacementsOptions({
        variables: placementOptionsVariables,
        successCallback,
        errorCallback,
      }),
    );
  };

  const fetchTransportationOptions = (
    successCallback = () => {},
    errorCallback = () => {},
  ) => {
    const transportationOptionsVariables = {
      inboundId: order?.inboundPlanId,
    };
    dispatch(
      getTransportationOptions({
        variables: transportationOptionsVariables,
        successCallback,
        errorCallback,
      }),
    );
  };

  const fetchDeliveryWindowOptions = (
    successCallback = () => {},
    errorCallback = () => {},
  ) => {
    const deliveryOptionsVariables = {
      inboundId: order?.inboundPlanId,
    };
    dispatch(
      getDeliveryWindowOptions({
        variables: deliveryOptionsVariables,
        successCallback,
        errorCallback,
      }),
    );
  };

  const fetchListInboundPlans = (
    successCallback = () => {},
    errorCallback = () => {},
  ) => {
    const listInboundPlansVariables = {
      pageNumber: 1,
      perPage: 10,
      sort: "-updatedAt",
      filters: {
        customerIds: null,
        inboundPlanIds: [order?.inboundPlanId],
        warehouseIds: null,
      },
    };
    dispatch(
      listFbaInboundPlans({
        variables: { listInboundPlansInput: listInboundPlansVariables },
        successCallback,
        errorCallback,
      }),
    );
  };

  const fetchShipmentsList = (
    successCallback = () => {},
    errorCallback = () => {},
  ) => {
    const inboundPlan = listInboundPlans?.data?.entities[0];

    const shipmentListVariables = {
      input: {
        customerId: inboundPlan?.customer,
        warehouseId: inboundPlan?.warehouse,
        inboundReferenceId: order?.inboundPlanId,
      },
    };
    dispatch(
      listShipments({
        variables: shipmentListVariables,
        successCallback,
        errorCallback,
      }),
    );
  };

  const fetchAmazonErrorsList = (
    successCallback = () => {},
    errorCallback = () => {},
  ) => {
    const inboundPlan = listInboundPlans?.data?.entities[0];

    const amazonErrorsListVariables = {
      inboundId: inboundPlan?.inboundPlanId,
    };
    dispatch(
      getErrorsInInboundPlan({
        variables: amazonErrorsListVariables,
        successCallback,
        errorCallback,
      }),
    );
  };

  const handleDropItems = (items = [], dropReason = "") => {
    const successCallback = (data) => {
      console.log(data, "data......");
      notify.success("Items dropped successfully");
      getCurrentBatch();
    };
    const errorCallback = (error) => {
      notify.error("Failed to drop items", error);
    };
    const dropItemsVariables = {
      batchId: currentBatch.id,
      itemIds: items.map((item) => item.id),
      dropReason: dropReason,
    };
    dispatch(
      dropItems({
        variables: dropItemsVariables,
        successCallback,
        errorCallback,
      }),
    );
  };
  const handleTransformShipmentData = (data) => {
    return data.map((shipment) => {
      const transformedShipment = { ...shipment };

      // Ensure trackingNumbers array is populated based on boxes
      if (
        !transformedShipment.trackingNumbers ||
        transformedShipment.trackingNumbers.length === 0
      ) {
        transformedShipment.trackingNumbers = transformedShipment.boxes.map(
          (box) => ({
            boxId: box.boxId,
            trackingId: "",
            isTrackingEditDisabled: false, // Allow editing since there's no trackingId initially
          }),
        );
      } else {
        transformedShipment.trackingNumbers =
          transformedShipment.trackingNumbers.map((tracking) => {
            const correspondingBox = transformedShipment.boxes.find(
              (box) => box.boxId === tracking.boxId,
            );

            if (correspondingBox) {
              // If trackingId exists, mark it as disabled
              return {
                ...tracking,
                trackingId: tracking.trackingId || "", // Ensure trackingId is empty if missing
                isTrackingEditDisabled: !!tracking.trackingId, // Disable if trackingId is already present
              };
            }

            return {
              boxId: tracking.boxId,
              trackingId: "",
              isTrackingEditDisabled: false, // Enable editing if no trackingId
            };
          });
      }

      // Ensure carrier is available at the shipment level
      if (!transformedShipment.carrier) {
        transformedShipment.carrier = "";
      }

      // Add `isCarrierEditDisabled` if the carrier already exists
      transformedShipment.isCarrierEditDisabled = !!transformedShipment.carrier;

      return transformedShipment;
    });
  };

  const handleTrackingInfoChange = (shipmentId, boxId, field, value) => {
    const updatedShipmentData = shipmentData.map((shipment) => {
      if (shipment.shipmentId === shipmentId) {
        const updatedTrackingNumbers = shipment.trackingNumbers.map(
          (tracking) => {
            if (tracking.boxId === boxId) {
              return {
                ...tracking,
                [field]: value,
              };
            }
            return tracking;
          },
        );

        return {
          ...shipment,
          trackingNumbers: updatedTrackingNumbers,
          carrier: field === "carrier" ? value : shipment.carrier,
        };
      }
      return shipment;
    });
    setShipmentData(updatedShipmentData); // Update the state with new shipment data
  };

  const handleConfirmPackingCompleted = async (
    successCallback = () => {},
    errorCallback = () => {},
  ) => {
    const inboundPlan = listInboundPlans?.data?.entities[0];
    const confirmPackingCompletedVariables = {
      inboundId: order?.inboundPlanId,
      customerId: inboundPlan?.customer,
      warehouseId: inboundPlan?.warehouse,
      isPackingCompleted: true,
    };

    appState.showNewConfirmation(
      "Confirm Packing & Drop off",
      "Are you sure you want to mark the packing as completed?",
      async () => {
        try {
          // Collect tracking data from shipmentData where tracking number or carrier is not disabled
          const updatedTrackingData = [];
          shipmentData.forEach((shipment) => {
            shipment.trackingNumbers.forEach((tracking) => {
              const { isCarrierEditDisabled, carrier } = shipment;
              const { trackingId, boxId, isTrackingEditDisabled } = tracking;
              // If the edit state is false, it means the user has entered a new value
              const hasCarrierChanged = !isCarrierEditDisabled && carrier;
              const hasTrackingChanged = !isTrackingEditDisabled && trackingId;

              // Only include data where trackingId or carrier is editable or changed
              if (hasCarrierChanged || hasTrackingChanged) {
                updatedTrackingData.push({
                  // Only send the carrier if it's editable (i.e., if isCarrierEditDisabled is false)
                  carrier,
                  // Only send the tracking numbers if it's editable (i.e., if isTrackingEditDisabled is false)
                  trackingNumbers: [{ boxId, trackingId }],
                  shipmentId: shipment.shipmentId, // Assuming shipmentId is part of the shipment object
                  customer: inboundPlan?.customer,
                  shippingLabel: shipment.shippingLabel || "",
                });
              }
            });
          });

          if (updatedTrackingData.length > 0) {
            // Send the updated tracking info using the mutation
            const updateTrackingPromises = updatedTrackingData.map(
              (trackingInfo) =>
                updateCarrierInfoQuery.fetchData({
                  carrier: trackingInfo.carrier,
                  trackingNumbers: trackingInfo.trackingNumbers,
                  shippingLabel: trackingInfo.shippingLabel,
                  customer: trackingInfo.customer,
                  shipmentId: trackingInfo.shipmentId,
                }),
            );

            const updateTrackingResponses = await Promise.all(
              updateTrackingPromises,
            );

            // Check for failed updates
            const failedUpdates = updateTrackingResponses.filter(
              (response) => response.error !== null,
            );

            if (failedUpdates.length > 0) {
              // Show errors for failed updates
              failedUpdates.forEach((failedResponse) => {
                notify.error(
                  `Failed to update tracking for shipment ${failedResponse.variables.shipmentId}`,
                );
              });
            } else {
              // All updates successful
              notify.success(
                "Successfully updated tracking information for all shipments",
              );
            }
          }

          const confirmTransportInformationResponse =
            await confirmTransportInformation();
          if (
            confirmTransportInformationResponse.data.confirmTransportInformation
              .message
          ) {
            confirmDropoff();
            dispatch(
              confirmPackingCompleted({
                variables: confirmPackingCompletedVariables,
                successCallback,
                errorCallback,
              }),
            );
          }
        } catch (error) {
          notify.error("Failed to complete packing process");
        }
      },
      appState.hideConfirmation,
    );
  };

  const handleGetDescriptions = () => {
    if (activeTab === fbaTabs.BOX_INFORMATION) {
      return "Enter the required details for each box, including dimensions, weight, and barcode information. Ensure the total items match the assigned packing group to maintain accuracy and completeness.";
    } else if (activeTab === fbaTabs.PLACEMENT_OPTIONS) {
      return "Compare shipping rates across different locations. Select your preferred rate to see the total cost update instantly, enabling quick and informed decision-making.";
    } else if (activeTab === fbaTabs.PACKING_OPTIONS) {
      return "This screen displays multiple packing options generated by Amazon, optimized for shipping efficiency and cost. Users are required to select one of these options to proceed.";
    } else if (activeTab === fbaTabs.LABEL_GENERATION) {
      return "This screen generates labels for each box. Users can choose the label size, orientation, and color. Ensure the labels are properly aligned and secure before printing.";
    } else if (activeTab === fbaTabs.TRANSPORTATION_OPTIONS) {
      return "Review the transportation options selected by Amazon. Ensure they align with your business requirements and cost-effectiveness before proceeding.";
    } else if (activeTab === fbaTabs.DELIVERY_WINDOW) {
      return "Select the delivery window for each shipment. Ensure the selected window aligns with your business requirements and cost-effectiveness before proceeding.";
    }
  };

  const handleEnterTrackingManually = () => {
    if (!placementOptionForLtl?.shipmentIds) {
      notify.error("No shipment IDs found");
      return;
    }

    // Create new rates object with "other" carrier for each shipment
    const newRates = {};
    placementOptionForLtl.shipmentIds.forEach((shipmentId) => {
      const shipmentOptions = transportationRates.find(
        (option) => option.shipmentId === shipmentId,
      );

      if (shipmentOptions) {
        const otherOption = shipmentOptions.transportationOptions.find(
          (option) => option.carrier.name.toLowerCase() === "other",
        );

        if (otherOption) {
          newRates[shipmentId] = {
            transportationOptionId: otherOption.transportationOptionId,
            uniqueId: `${otherOption.transportationOptionId}_${otherOption.carrier.alphaCode}`,
            alphaCode: otherOption.carrier.alphaCode,
            carrierName: otherOption.carrier.name,
          };
        }
      }
    });

    // Set the flag to true before updating state
    setShouldConfirmTransportation(true);

    // Update the state
    setSelectedTransportationOption(newRates);
  };

  const handleMoreActions = (activeTab) => {
    const inboundPlanDetails =
      listInboundPlans?.data?.entities?.[0]?.inboundPlanDetails;

    return [
      {
        name: "Pause Packing",
        icon: "",
        onClick: () => {
          pauseActivity();
        },
      },
      inboundPlanDetails?.amazonInboundPlanId ||
      [
        ...Object.values(fbaPackingTabsEnums || {}),
        ...Object.values(fbaPackingLtlFlowTabsEnums || {}),
      ].includes(activeTab) //
        ? {
            name: "Restart Packing",
            icon: "",
            onClick: () => {
              handleCancelInboundPlan();
            },
          }
        : null,
    ].filter(Boolean);
  };

  // Updated handleStartAmazonInboundCreation to integrate polling
  const handleStartAmazonInboundCreation = (shouldGeneratePackingOptions) => {
    if (listInboundPlans?.data?.entities?.length) {
      const inboundPlan = listInboundPlans?.data?.entities[0];

      // First group by bundle if present, then by productId
      const groupedProducts = currentBatch?.workingList?.reduce((acc, item) => {
        const key = item.sku;
        const itemFromInboundPlan = inboundPlan?.items?.find(
          (planItem) => planItem.sku === item.sku,
        );
        if (!acc[key]) {
          acc[key] = {
            asin: item.asin,
            msku: item.sku,
            quantity: 0,
            id: item.bundle ? item.bundle : item.productId,
            name: item.productName,
            expiration: item.bestByDate,
            image: itemFromInboundPlan?.images[0] || "",
          };
        }

        if (["UNPROCESSED", "CONFIRMED"].includes(item.status)) {
          // 1 item in workinglist corresponds to 1 quantity
          acc[key].quantity += 1;
        }
        return acc;
      }, {});

      const amazonInboundCreationVariables = {
        input: {
          customerId: inboundPlan?.customer,
          marketPlaceCountryCodes: [inboundPlan?.marketplace],
          inboundId: inboundPlan?.inboundPlanId,
          sellerId: inboundPlan?.sellerId,
          products: (Object.values(groupedProducts) || []).filter(
            (item) => item.quantity, // once dropped then quantity can be 0
          ),
          warehouseId: order?.warehouse,
          shouldGeneratePackingOptions,
          rateShoppingEnabled: true,
        },
      };

      const successCallback = (data) => {
        if (data?.status === "SUCCESS") {
          if (shouldGeneratePackingOptions) {
            notify.success(
              "Amazon Inbound Plan Started Successfully",
              "Polling for packing options...",
            );
            pollPackingOptions();
          } else {
            notify.success(
              "Amazon Inbound Plan Started Successfully",
              "Polling for placement options...",
            );
            pollPlacementOptions(false);
          }
        } else {
          notify.error(
            "Failed to start Amazon Inbound Plan Creation",
            "No WorkFlowId found",
          );
        }
      };

      dispatch(
        startAmazonInboundPlanCreation({
          variables: amazonInboundCreationVariables,
          successCallback,
          errorCallback: (error) =>
            notify.error("Failed to start Amazon Inbound Plan Creation", error),
        }),
      );
    }
  };

  const handleCancelInboundPlan = (callWithoutConfirmation = false) => {
    restartActivity(() => {
      const inboundPlan = listInboundPlans?.data?.entities[0];
      const cancelAmazonInboundPlanVariables = {
        inboundId: order?.inboundPlanId || order?.orderId,
        customerId: inboundPlan?.customer,
      };
      const successCallback = (data) => {
        if (data?.status === "SUCCESS") {
          // notify.success("Inbound Plan Cancelled Successfully");
          // dispatch(resetFbaInboundState());
          // fetchListInboundPlans();
          window.location.reload();
        } else if (data?.errors && data?.errors.length) {
          notify.error(data?.errors[0].message, data?.errors[0]?.details);
        }
      };
      const errorCallback = (error) => {
        notify.error(error);
      };

      dispatch(
        cancelAmazonInboundPlan({
          variables: cancelAmazonInboundPlanVariables,
          successCallback,
          errorCallback,
        }),
      );
    }, callWithoutConfirmation);
  };

  const handleGetConfirmCtaText = () => {
    if (activeTab === fbaPackingTabsEnums.PACKING_OPTIONS) {
      return "Proceed To Add Box Details";
    } else if (activeTab === fbaPackingTabsEnums.PLACEMENT_OPTIONS) {
      return "Confirm Placement Option";
    } else if (activeTab === fbaPackingTabsEnums.BOX_INFORMATION) {
      if (fbaTabs.TRANSPORTATION_OPTIONS) {
        return "Proceed to choose Transportation Options";
      } else if (!fbaTabs.TRANSPORTATION_OPTIONS) {
        return "Proceed to Choose Placement Options";
      }
    } else if (
      activeTab === fbaPackingLtlFlowTabsEnums.TRANSPORTATION_OPTIONS
    ) {
      return "Confirm Transportation Options";
    } else if (activeTab === fbaPackingTabsEnums.DELIVERY_WINDOW) {
      return "Confirm Delivery Window Options";
    } else if (activeTab === fbaPackingTabsEnums.LABEL_GENERATION) {
      return "Save";
    } else return "Confirm";
  };

  const handleConfirmPackingOptions = () => {
    const inboundPlan = listInboundPlans?.data?.entities[0];
    appState.showNewConfirmation(
      "Confirm",
      "Are you sure you want to confirm the selected packing options?",
      () => {
        const confirmPackingOptionsVariables = {
          inboundId: order?.inboundPlanId,
          packingOptionId: selectedPackingOption,
          customerId: inboundPlan?.customer,
          warehouseId: inboundPlan?.warehouse,
        };
        const successCallback = (data) => {
          appState.removeLoading();
          if (data?.status === "SUCCESS") {
            notify.success("Packing options confirmed successfully");
            setSlideOverOpen(true);
            setActiveTab(fbaPackingTabsEnums.BOX_INFORMATION);
          } else if (data?.errors && data?.errors?.length > 0) {
            notify.error(data.errors[0].message, data.errors[0].details);
          }
        };
        const errorCallback = (error) => {
          appState.removeLoading();
          notify.error("Failed to confirm packing options", error);
        };
        appState.setLoading();
        dispatch(
          confirmPackingOptions({
            variables: confirmPackingOptionsVariables,
            successCallback,
            errorCallback,
          }),
        );
      },
      appState.hideConfirmation,
    );
  };

  const validateLtlFlow = (inboundDetails) => {
    if (
      (!inboundDetails.packingOptions ||
        !inboundDetails.packingOptions.length) &&
      inboundDetails.placementOptions &&
      inboundDetails.placementOptions.length
    ) {
      return true;
    }
    return false;
  };

  const hasLabelGenerationSetup = (inboundDetails) => {
    const transportationOptions =
      inboundDetails?.selectedTransportationOption || [];
    const allPartnered =
      transportationOptions.length > 0 &&
      transportationOptions.every(
        (opt) => opt.shippingSolution === "AMAZON_PARTNERED_CARRIER",
      );

    return allPartnered;
  };

  const handleSetPrepDetailsInAmazon = async (prepDetails = {}) => {
    const inboundPlan = listInboundPlans?.data?.entities?.[0];
    const setPrepDetailsOnAmazonVariables = {
      customerId: inboundPlan?.customer,
      marketplaceCountryCode: inboundPlan?.marketplace,
      sku: prepDetails.sku,
      prepCategory: prepDetails.prepCategory,
      prepTypes: prepDetails.prepTypes,
    };
    try {
      appState.setLoading();
      const setPrepDetailsOnAmazonQueryResponse =
        await setPrepDetailsOnAmazonQuery.fetchData({
          ...setPrepDetailsOnAmazonVariables,
        });
      appState.removeLoading();

      if (setPrepDetailsOnAmazonQueryResponse.error) {
        notify.error(
          `Failed to update prep details for sku, try different combinations`,
          setPrepDetailsOnAmazonQueryResponse?.error?.message,
        );
      } else if (setPrepDetailsOnAmazonQueryResponse.data) {
        getCurrentBatch();
        fetchListInboundPlans();
        notify.success("Successfully updated prep details skus");
      }
    } catch (error) {
      notify.error("Failed to update prep details", error);
    }
  };

  const packingOptions = fbaTabs.TRANSPORTATION_OPTIONS
    ? getPlacementOptionsResult?.data
        ?.placementOptionsWithShipmentBoxesAndItems || []
    : !fbaTabs.TRANSPORTATION_OPTIONS
      ? packingOptionsResult?.data?.packingOptionsWithGroupItems || []
      : [];

  const placementOptionForLtl = useMemo(() => {
    if (
      selectedPlacementOption &&
      getPlacementOptionsResult.data?.placementOptionsWithShipmentBoxesAndItems
        ?.length
    ) {
      const option =
        getPlacementOptionsResult.data.placementOptionsWithShipmentBoxesAndItems.find(
          (option) => option.placementOptionId === selectedPlacementOption,
        );
      if (option) return option;
    }

    try {
      const storedData = localStorage.getItem("selectedPlacementOptionData");
      return storedData ? JSON.parse(storedData) : null;
    } catch (error) {
      console.error("Error parsing stored placement data:", error);
      return null;
    }
  }, [selectedPlacementOption, getPlacementOptionsResult.data]);

  const clientName =
    auth?.user?.customersList?.find(
      (customer) =>
        customer.id === listInboundPlans?.data?.entities?.[0]?.customer,
    )?.name || "";

  useEffect(() => {
    if (listInboundPlans?.data?.entities?.length) {
      const fetchInventoryData = async () => {
        const formattedInventory = Object.values(
          currentBatch?.workingList?.reduce((acc, item) => {
            // Skip DROPPED items but return accumulator
            if (item.status === "DROPPED") return acc;

            const key = item.sku;
            const itemFromInboundPlan =
              listInboundPlans?.data?.entities?.[0]?.items.find(
                (inboundItem) => inboundItem.sku === item.sku,
              );

            if (!acc[key]) {
              acc[key] = {
                id: item.id,
                sku: item.sku,
                asin: item.asin,
                name: item.productName,
                fnsku: item.fnSku,
                images: itemFromInboundPlan?.images || [FALLBACK_IMAGE_URL],
                quantity: 1,
                expiryDate:
                  itemFromInboundPlan?.expiryDate || item.bestByDate || "",
                bundle: item.bundle || null,
              };
            } else {
              acc[key].quantity += 1;
            }

            return acc;
          }, {}) || {},
        );

        const inboundPlan = listInboundPlans?.data?.entities?.[0];
        const listPrepDetailsFromAmazonVariables = {
          customerId: inboundPlan?.customer,
          marketplaceCountryCode: inboundPlan?.marketplace,
          skus: formattedInventory?.map((item) => item.sku),
        };

        appState.setLoading();
        const listPrepDetailsFromAmazonQueryResponse =
          await listPrepDetailsFromAmazonQuery.fetchData({
            ...listPrepDetailsFromAmazonVariables,
          });
        appState.removeLoading();

        // Merge prep details with inventory data
        const inventoryWithPrepDetails = formattedInventory.map((item) => {
          const prepDetails =
            listPrepDetailsFromAmazonQueryResponse?.data?.listPrepDetailsFromAmazon?.mskuPrepDetails?.find(
              (detail) => detail.msku === item.sku,
            );

          // Add prep details and category descriptions
          return {
            ...item,
            prepDetails: prepDetails ? prepDetails : null,
            prepCategoryDescription:
              listPrepDetailsFromAmazonQueryResponse?.data
                ?.listPrepDetailsFromAmazon?.prepCategoryAndDescription || {},
            prepTypeAndDescription:
              listPrepDetailsFromAmazonQueryResponse?.data
                ?.listPrepDetailsFromAmazon?.prepTypeAndDescription || {},
          };
        });

        setInventoryData(inventoryWithPrepDetails);
      };

      fetchInventoryData();
    }
  }, [currentBatch?.workingList, listInboundPlans?.data?.entities]);

  useEffect(() => {
    if (listInboundPlans.isPending) {
      appState.setLoading();
    } else if (listInboundPlans.isFulfilled) {
      appState.removeLoading();
    }

    const inboundPlan = listInboundPlans?.data?.entities?.[0] || {};
    if (!inboundPlan?.inboundPlanDetails?.amazonInboundPlanId) return;
    const inboundDetails = inboundPlan.inboundPlanDetails;

    if (validateLtlFlow(inboundDetails)) {
      setFbaTabs(fbaPackingLtlFlowTabsEnums);
    } else {
      setFbaTabs(fbaPackingTabsEnums);
    }

    const labelGenerationReady = hasLabelGenerationSetup(inboundDetails);

    if (labelGenerationReady) {
      setActiveTab(fbaPackingTabsEnums.LABEL_GENERATION);
      pollShipmentLists();
      setSlideOverOpen(true);
      return;
    }

    let hasDeliveryWindowSetup;
    if (
      inboundDetails?.selectedTransportationOption?.[0]?.transportationOptionId
    ) {
      const areCarriersPartnered =
        inboundDetails?.selectedTransportationOption?.every((option) => {
          return option.shippingSolution === "AMAZON_PARTNERED_CARRIER";
        });
      hasDeliveryWindowSetup = areCarriersPartnered === false;
    } else hasDeliveryWindowSetup = false;

    if (hasDeliveryWindowSetup) {
      setActiveTab(fbaPackingTabsEnums.DELIVERY_WINDOW);
      pollDeliveryWindowOptions();
      setSelectedPlacementOption(
        inboundDetails.selectedPlacementOption.placementOptionId,
      );
      setSlideOverOpen(true);
      return;
    }
    const hasBoxInformationSetupForLtl =
      inboundDetails?.packageGroupings?.length > 0 &&
      validateLtlFlow(inboundDetails);

    if (hasBoxInformationSetupForLtl) {
      const placementOptionId =
        inboundDetails?.selectedPlacementOption?.placementOptionId;
      setSelectedPlacementOption(placementOptionId);
      setActiveTab(fbaPackingLtlFlowTabsEnums.TRANSPORTATION_OPTIONS);
      setSlideOverOpen(true);
      return;
    }

    const hasPlacementOptionSelectedForLtl =
      inboundDetails?.selectedPlacementOption?.placementOptionId &&
      validateLtlFlow(inboundDetails);

    if (hasPlacementOptionSelectedForLtl) {
      setActiveTab(fbaPackingLtlFlowTabsEnums.BOX_INFORMATION);
      setSelectedPlacementOption(
        inboundDetails?.selectedPlacementOption?.placementOptionId,
      );
      pollPlacementOptions(false, true);
      setSlideOverOpen(true);
      return;
    }

    const hasPackingOrPlacement =
      inboundDetails.packageGroupings?.length > 0 ||
      inboundDetails.placementOptions?.length > 0;

    if (hasPackingOrPlacement) {
      setActiveTab(fbaPackingTabsEnums.PLACEMENT_OPTIONS);
      const shouldGenerateTransportationOptions =
        validateLtlFlow(inboundDetails) === false;

      // if (!shouldGenerateTransportationOptions) restartActivity(() => {}, true);

      pollPlacementOptions(shouldGenerateTransportationOptions);
      setSlideOverOpen(true);
      return;
    }

    const handlePackingOptionsSuccess = (data) => {
      appState.removeLoading();

      if (data?.errors?.length) {
        notify.error(data.errors[0].message, data.errors[0]?.details);
        return;
      }

      if (!data?.packingOptionsWithGroupItems?.length) {
        notify.error("Failed to fetch packing options");
        return;
      }

      if (inboundDetails?.selectedPackingOption?.packingOptionId) {
        setActiveTab(fbaPackingTabsEnums.BOX_INFORMATION);
        setSelectedPackingOption(
          inboundDetails.selectedPackingOption.packingOptionId,
        );
      } else {
        setSelectedPackingOption(
          data.packingOptionsWithGroupItems[0].packingOptionId,
        );
      }
      setSlideOverOpen(true);
    };

    const handlePackingOptionsError = (error) => {
      appState.removeLoading();
      notify.error("Failed to fetch packing options", error);
    };

    if (inboundDetails?.selectedPackingOption?.packingOptionId) {
      appState.setLoading();
      fetchPackingOptions(
        handlePackingOptionsSuccess,
        handlePackingOptionsError,
      );
    } else if (
      inboundDetails?.amazonInboundPlanId &&
      !validateLtlFlow(inboundDetails)
    ) {
      pollPackingOptions();
    } else if (
      inboundDetails?.amazonInboundPlanId &&
      validateLtlFlow(inboundDetails)
    ) {
      // restartActivity(() => {}, true);
      setActiveTab(fbaPackingTabsEnums.PLACEMENT_OPTIONS);
      setSlideOverOpen(true);
      pollPlacementOptions(false);
    }
  }, [listInboundPlans]);

  useEffect(() => {
    if (packingInformationResult.isPending) appState.setLoading();
    else appState.removeLoading();
  }, [packingInformationResult]);

  useEffect(() => {
    if (
      shouldConfirmTransportation &&
      Object.keys(selectedTransportationOption).length > 0
    ) {
      handleConfirmTransportationOptions(placementOptionForLtl.shipmentIds);
      setShouldConfirmTransportation(false); // Reset the flag
    }
  }, [selectedTransportationOption, shouldConfirmTransportation]);

  useEffect(() => {
    if (
      getTransportationsOptionsResult?.data?.transportationOptionsPerShipment &&
      fbaTabs.TRANSPORTATION_OPTIONS
    ) {
      const newOptions =
        getTransportationsOptionsResult.data.transportationOptionsPerShipment;

      setTransportationRates((prev) => {
        const updatedOptions = [...prev];

        newOptions.forEach((newOption) => {
          const existingIndex = updatedOptions.findIndex(
            (option) => option.shipmentId === newOption.shipmentId,
          );

          if (existingIndex > -1) {
            // Replace existing option
            updatedOptions[existingIndex] = newOption;
          } else {
            // Add new option
            updatedOptions.push(newOption);
          }
        });

        return updatedOptions;
      });
    }
  }, [getTransportationsOptionsResult]);

  useEffect(() => {
    if (listShipmentsResult?.data?.entities?.length) {
      const shipmentData = handleTransformShipmentData(
        listShipmentsResult.data.entities,
      );
      setShipmentData(shipmentData);
    } else {
      setShipmentData([]);
    }
  }, [listShipmentsResult]);

  useEffect(() => {
    // Initial setup
    dispatch(resetFbaPackingState());
    fetchListInboundPlans();
    clearAllIntervals();

    // Cleanup function
    return () => {
      appState.removeLoadingOverlay();
      dispatch(resetFbaInboundState());
      dispatch(resetFbaPackingState());
      clearAllIntervals(); // Add this to cleanup
    };
  }, []);

  useEffect(() => {
    if (
      selectedPlacementOption &&
      !placementOptionForLtl &&
      !getPlacementOptionsResult.isPending
    ) {
      fetchPlacementOptions(
        (data) => {
          if (data?.placementOptionsWithShipmentBoxesAndItems?.length) {
            const option = data.placementOptionsWithShipmentBoxesAndItems.find(
              (opt) => opt.placementOptionId === selectedPlacementOption,
            );

            if (option) {
              localStorage.setItem(
                "selectedPlacementOptionData",
                JSON.stringify(option),
              );
            }
          }
        },
        (error) => {
          console.error("Error fetching placement options:", error);
        },
      );
    }
  }, [
    selectedPlacementOption,
    placementOptionForLtl,
    getPlacementOptionsResult.isPending,
  ]);

  return (
    <div className="p-8 font-inter">
      <div className="flex h-full flex-col p-8">
        <HeaderWithArrow
          headerTitle={
            <p className="flex items-center gap-2">
              <span className="text-gray-400">Order ID</span>
              <span>{order?.orderId}</span>
              <span>{">"}</span>
              <span>Order Details</span>
            </p>
          }
          description={
            <p className="flex items-center gap-2">
              <span className="text-gray-400">Inbound Plan ID:</span>
              <span
                onClick={() => {
                  window.open(`/fbaInbound/${order?.inboundPlanId}`, "_blank");
                }}
                className="cursor-pointer underline">
                {order?.inboundPlanId}
              </span>
            </p>
          }
          isLearnMore={false}
          isArrow={false}
          mainClasses="mb-3"
        />{" "}
        {/* Wrapper for content */}
        <div className="flex-1 overflow-auto">
          {clientName && (
            <div className="mb-2">
              <p className="text-base font-semibold">
                Client Name: {clientName}
              </p>
            </div>
          )}
          <FbaProductTableReadOnly
            inventoryData={inventoryData}
            fromPackingScreen={true}
            workingList={currentBatch?.workingList || []}
            onDropItem={(items, dropReason) => {
              handleDropItems(items, dropReason);
            }}
            handleSetPrepDetailsInAmazon={handleSetPrepDetailsInAmazon}
          />
        </div>
        <div className="fixed bottom-0 left-0 right-0 border-t bg-white p-4 shadow-lg">
          <div className="flex justify-end gap-6">
            <CustomPopover
              actions={handleMoreActions(activeTab)}
              defaultClasses=""
              styles={{ bottom: "3rem" }}
              bgColorVisible={false}
              triggerElement={(open) => {
                return (
                  <PrimaryButton
                    height="3rem"
                    minWidth="9rem"
                    // maxWidth="15rem"
                    variant="secondary"
                    className="border-none text-base font-medium"
                    onClick={() => {}}>
                    More Actions{" "}
                    {open ? (
                      <ChevronDownIcon className="ml-1 h-6 w-6" />
                    ) : (
                      <ChevronUpIcon className="ml-1 h-6 w-6" />
                    )}
                  </PrimaryButton>
                );
              }}
            />
            {/* <PrimaryButton
            height="3rem"
            minWidth="13rem"
            maxWidth="20rem"
            className="text-base font-medium"
            onClick={() => {
              handleCancelInboundPlan();
            }}>
            Pause Packing
          </PrimaryButton> */}
            <PrimaryButton
              height="3rem"
              minWidth="13rem"
              maxWidth="20rem"
              variant="primary"
              className="text-base font-medium"
              onClick={() => setShowBoxDetailsConfirmationModal(true)}>
              Proceed To Packing
            </PrimaryButton>
          </div>
        </div>
      </div>
      <SlideOverPanel
        open={slideOverOpen}
        isCrossIconVisible={false}
        title={
          <div className="flex justify-between p-2">
            <HeaderWithArrow
              isArrow={false}
              headerTitle={activeTab}
              description={
                <div className="flex flex-col justify-center">
                  <p>
                    OrderId: {order?.orderId} | Inbound Plan ID:{" "}
                    <span
                      className="cursor-pointer underline"
                      onClick={() => {
                        window.open(
                          `/fbaInbound/${order?.inboundPlanId}`,
                          "_blank",
                        );
                      }}>
                      {order?.inboundPlanId}
                    </span>
                  </p>
                  <p>{handleGetDescriptions()}</p>
                </div>
              }
            />
          </div>
        }
        // setOpen={setSlideOverOpen}
        containerStyle={"max-w-10xl"}>
        {/* Set height and overflow for the container */}
        <div className="flex h-full flex-col gap-6 px-8 font-inter">
          <CustomTabSelection
            tabs={Object.values(fbaTabs).map((tab) => ({
              label: tab,
              value: tab,
              disabled: tab !== activeTab,
            }))}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />
          <div
            className="flex-grow overflow-y-auto"
            style={{ height: "calc(100vh - 10rem)" }} // Adjust height as needed
          >
            {activeTab === fbaTabs.PACKING_OPTIONS && (
              <SelectPackingOptions
                packingOptions={
                  packingOptionsResult.data?.packingOptionsWithGroupItems || []
                }
                selectedPackingOption={selectedPackingOption}
                setSelectedPackingOption={setSelectedPackingOption}
              />
            )}
            {activeTab === fbaTabs.BOX_INFORMATION && (
              <PackingBoxInformation
                order={order}
                packingOptions={packingOptions}
                cancelItem={cancelItem}
                selectedPackingOption={
                  fbaTabs.TRANSPORTATION_OPTIONS
                    ? selectedPlacementOption
                    : !fbaTabs.TRANSPORTATION_OPTIONS
                      ? selectedPackingOption
                      : ""
                }
                boxTypes={boxTypes}
                formattedGroups={formattedGroups}
                setFormattedGroups={setFormattedGroups}
                isLtlFlow={Boolean(fbaTabs.TRANSPORTATION_OPTIONS)}
                currentBatch={currentBatch}
                getCurrentBatch={getCurrentBatch}
              />
            )}
            {activeTab === fbaTabs.PLACEMENT_OPTIONS && (
              <SelectPlacementOptions
                placementOptions={
                  getPlacementOptionsResult.data
                    ?.placementOptionsWithShipmentBoxesAndItems || []
                }
                selectedPlacementOption={selectedPlacementOption}
                setSelectedPlacementOption={setSelectedPlacementOption}
                setSelectedRates={setSelectedTransportationOption}
                selectedRates={selectedTransportationOption}
                pollTransportationOptions={() => {
                  const shipmentIdsList =
                    getPlacementOptionsResult?.data?.placementOptionsWithShipmentBoxesAndItems
                      .map((grouping) => grouping.shipmentIds)
                      .flat()
                      .filter(Boolean);

                  pollTransportationOptions(
                    shipmentIdsList?.length,
                    fbaTabs.TRANSPORTATION_OPTIONS,
                  );
                }}
                isLtlFlow={Boolean(fbaTabs.TRANSPORTATION_OPTIONS)}
              />
            )}
            {activeTab === fbaTabs.DELIVERY_WINDOW && (
              <SelectDeliveryOptions
                data={
                  getDeliverWindowOptionsResult.data
                    ?.deliveryWindowOptionsPerShipment || []
                }
                selectedDeliveryWindow={selectedDeliveryWindow}
                setSelectedDeliveryWindow={setSelectedDeliveryWindow}
              />
            )}
            {activeTab === fbaTabs.LABEL_GENERATION && (
              <FbaShipmentDetails
                data={shipmentData}
                onChangeData={handleTrackingInfoChange}
              />
            )}
            {activeTab === fbaTabs.TRANSPORTATION_OPTIONS && (
              <div>
                <FbaRateShopping
                  pollTransportationOptions={() => {
                    const inboundPlan = listInboundPlans?.data?.entities[0];
                    const shipmentIdsList =
                      inboundPlan?.inboundPlanDetails?.selectedPlacementOption
                        ?.shipmentIds ||
                      JSON.parse(
                        localStorage.getItem("selectedPlacementOptionData"),
                      )?.shipmentIds;
                    pollTransportationOptions(shipmentIdsList?.length, true);
                  }}
                  placementOption={placementOptionForLtl}
                  isLtlFlow={Boolean(fbaTabs.TRANSPORTATION_OPTIONS)}
                  selectedRates={selectedTransportationOption}
                  setSelectedRates={setSelectedTransportationOption}
                  transportationOptions={transportationRates}
                  onRateSelect={handleRateSelection}
                  onCancel={() => {}}
                  onConfirm={() => {}}
                />
              </div>
            )}
          </div>
          {/* Sticky footer */}
          <div className="sticky bottom-0 z-30 flex w-full items-center justify-between bg-white p-4">
            <div className="flex w-full justify-end gap-6">
              {/* <PrimaryButton
                height="3rem"
                minWidth="13rem"
                maxWidth="20rem"
                className="text-base font-medium"
                onClick={() => {
                  handleCancelInboundPlan();
                }}>
                Pause Packing
              </PrimaryButton> */}
              <CustomPopover
                actions={handleMoreActions(activeTab)}
                defaultClasses=""
                styles={{ bottom: "3rem" }}
                bgColorVisible={false}
                triggerElement={(open) => {
                  return (
                    <PrimaryButton
                      height="3rem"
                      minWidth="9rem"
                      // maxWidth="15rem"
                      variant="secondary"
                      className="border-none text-base font-medium"
                      onClick={() => {}}>
                      More Actions{" "}
                      {open ? (
                        <ChevronDownIcon className="ml-1 h-6 w-6" />
                      ) : (
                        <ChevronUpIcon className="ml-1 h-6 w-6" />
                      )}
                    </PrimaryButton>
                  );
                }}
              />
              {activeTab ===
                fbaPackingLtlFlowTabsEnums.TRANSPORTATION_OPTIONS && (
                <PrimaryButton
                  height="3rem"
                  minWidth="10rem"
                  maxWidth="15rem"
                  variant="primary"
                  className="text-base font-medium"
                  danger
                  onClick={() => {
                    appState.showNewConfirmation(
                      "Enter Tracking Manually",
                      "Are you sure you want to enter tracking manually?",
                      handleEnterTrackingManually,
                      appState.hideConfirmation,
                    );
                  }}>
                  Enter Tracking Manually
                </PrimaryButton>
              )}
              <PrimaryButton
                height="3rem"
                minWidth="13rem"
                maxWidth="20rem"
                variant="primary"
                className="text-base font-medium"
                disabled={
                  handleGetConfirmCtaText() === "Confirm" ||
                  (activeTab === fbaPackingTabsEnums.BOX_INFORMATION &&
                    !handleDisableBoxConfirmationCta(formattedGroups))
                }
                onClick={() => {
                  if (fbaPackingTabsEnums.PACKING_OPTIONS === activeTab) {
                    handleConfirmPackingOptions();
                  } else if (
                    fbaPackingTabsEnums.BOX_INFORMATION === activeTab
                  ) {
                    setIsReviewModalOpen(true);
                  } else if (
                    fbaPackingTabsEnums.PLACEMENT_OPTIONS === activeTab
                  ) {
                    handleConfirmationForPlacementOptions();
                  } else if (
                    fbaPackingLtlFlowTabsEnums.TRANSPORTATION_OPTIONS ===
                    activeTab
                  ) {
                    handleConfirmationForTransportationOptionsLtl();
                  } else if (
                    fbaPackingTabsEnums.DELIVERY_WINDOW === activeTab
                  ) {
                    handleConfirmationForDeliveryWindowOptions(
                      selectedDeliveryWindow,
                    );
                  } else if (
                    fbaPackingTabsEnums.LABEL_GENERATION === activeTab
                  ) {
                    handleConfirmPackingCompleted();
                  }
                }}>
                {handleGetConfirmCtaText()}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </SlideOverPanel>
      <ReviewBoxesModal
        isOpen={isReviewModalOpen}
        groups={formattedGroups}
        onClose={() => setIsReviewModalOpen(false)}
        onConfirm={() => {
          handleSetPackingInformation(formattedGroups);
          setIsReviewModalOpen(false);
        }}
      />
      <NewModal
        isOpen={showBoxDetailsConfirmationModal}
        max
        onClose={() => setShowBoxDetailsConfirmationModal(false)}
        maxWidth="740px"
        title={"Do You Know the Box Details for Your Shipment?"}>
        <div className="font-inter">
          <p>
            If you already know the dimensions and weight of the boxes, we'll
            move ahead with LTL shipping. If not, we'll suggest the best
            shipping option based on your items (either small parcel or LTL).
          </p>
          <div className="mt-5 flex w-full items-center justify-end gap-5">
            <PrimaryButton
              height="3rem"
              minWidth="13rem"
              maxWidth="20rem"
              className="text-base font-medium"
              onClick={() => {
                setShowBoxDetailsConfirmationModal(false);
                handleStartAmazonInboundCreation(false);
              }}>
              Yes, I have Box Details for LTL
            </PrimaryButton>
            <PrimaryButton
              height="3rem"
              minWidth="13rem"
              maxWidth="20rem"
              variant="primary"
              className="text-base font-medium"
              onClick={() => {
                setShowBoxDetailsConfirmationModal(false);
                handleStartAmazonInboundCreation(true);
              }}>
              No, Generate SP/LTL Options for me
            </PrimaryButton>
          </div>
        </div>
      </NewModal>
    </div>
  );
};

export default FbaPackingBatch;
