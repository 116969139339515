import React, { useContext, useEffect, useState } from "react";
import CheckBox from "#newUiComponents/commons/CheckBox";
import { PlusIcon, TrashIcon, ChevronDownIcon } from "@heroicons/react/outline";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import NewModal from "#newUiComponents/commons/NewModal";
import { AppStateContext } from "#contexts/appState";
import SlideOverPanel from "#components/common/SlideOverPanel";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import BoxTypePopover from "./BoxTypePopover";
import BoxItemsTable from "./BoxItemsTable";
import BoxDuplicationModal from "./BoxDuplicationModal";
import PlannedOrder from "./PlannedOrder";

const PackPlan = ({
  detailedOrdersForBatching = [],
  customers,
  warehouses,
  boxTypes,
  orderBoxes,
  setOrderBoxes,
  packedQuantities,
  setPackedQuantities,
  assignedQuantities,
  setAssignedQuantities,
}) => {
  const notify = CustomNotification();
  const appState = useContext(AppStateContext);

  const [selectedOrders, setSelectedOrders] = useState(new Set());
  const [expandedOrderIds, setExpandedOrderIds] = useState([]);
  const [expandedBoxId, setExpandedBoxId] = useState(null);
  const [isBoxDimensionsModalOpen, setIsBoxDimensionsModalOpen] =
    useState(false);
  const [boxDimensionsDetails, setBoxDimensionsDetails] = useState({
    length: "",
    width: "",
    height: "",
  });
  const [dimensionErrors, setDimensionErrors] = useState({
    length: "",
    width: "",
    height: "",
  });
  const [selectedOrderForCustomBox, setSelectedOrderForCustomBox] =
    useState(null);
  const [boxCounts, setBoxCounts] = useState({});

  const [selectedBoxForItems, setSelectedBoxForItems] = useState(null);
  const [boxItems, setBoxItems] = useState({});
  const [
    openManualAddItemsSlideOverPanel,
    setOpenManualAddItemsSlideOverPanel,
  ] = useState(false);
  const [isBoxPopoverOpen, setIsBoxPopoverOpen] = useState(false);

  const [duplicationModalOpen, setDuplicationModalOpen] = useState(false);
  const [duplicationDetails, setDuplicationDetails] = useState({
    orderId: null,
    boxIndex: null,
    quantity: 1,
  });

  const handleEditDimensions = (orderId, boxIndex) => {
    const box = orderBoxes[orderId][boxIndex];
    setSelectedOrderForCustomBox(`${orderId}::${boxIndex}`);
    setBoxDimensionsDetails({
      length: box.length,
      width: box.width,
      height: box.height,
      weight: box?.weight || 0,
    });
    setIsBoxDimensionsModalOpen(true);
  };

  const handleUpdateWeight = (
    orderId,
    boxIndex,
    newWeight,
    isTotal = false,
  ) => {
    setOrderBoxes((prev) => ({
      ...prev,
      [orderId]: prev[orderId].map((box, idx) => {
        if (idx === boxIndex) {
          const itemsWeight = box.items.reduce(
            (sum, item) =>
              sum + (item?.attributes?.weight || 0) * item.packQuantity,
            0,
          );

          if (isTotal) {
            // If updating total weight, adjust box weight
            // const newBoxWeight = Math.max(0, newWeight - itemsWeight);
            return {
              ...box,
              totalWeight: newWeight,
            };
          } else {
            // If updating box weight, adjust total weight
            return {
              ...box,
              weight: newWeight,
              totalWeight: newWeight + box.totalWeight,
            };
          }
        }
        return box;
      }),
    }));
  };
  const renumberBoxes = (orderId) => {
    setOrderBoxes((prev) => {
      const boxes = prev[orderId] || [];
      const updatedBoxes = boxes.map((box, index) => {
        const newDisplayName = `Box-${index + 1}`;
        const newName = `${orderId}_${newDisplayName}`;
        return { ...box, name: newName, displayName: newDisplayName };
      });
      return { ...prev, [orderId]: updatedBoxes };
    });
  };

  const getNextBoxName = (orderId) => {
    const currentBoxes = orderBoxes[orderId] || [];
    const nextBoxNumber = currentBoxes.length + 1;
    const displayName = `Box-${nextBoxNumber}`;
    const name = `${orderId}_Box-${nextBoxNumber}`;

    return { name, displayName };
  };

  const handleOrderToggle = (orderId) => {
    const isOrderOpen = expandedOrderIds.includes(orderId);

    if (isOrderOpen) {
      setExpandedOrderIds((prev) => prev.filter((id) => id !== orderId));
    } else {
      setExpandedOrderIds((prev) => [...prev, orderId]);
    }
    setExpandedBoxId(null); // Close any open boxes when toggling orders
  };

  const handleBoxToggle = (boxId) => {
    setExpandedBoxId(expandedBoxId === boxId ? null : boxId);
  };

  const handleSelectAll = () => {
    if (selectedOrders.size === detailedOrdersForBatching.length) {
      setSelectedOrders(new Set());
    } else {
      setSelectedOrders(
        new Set(detailedOrdersForBatching.map((order) => order.orderId)),
      );
    }
  };

  const handleSelectOrder = (orderId) => {
    const newSelected = new Set(selectedOrders);
    if (newSelected.has(orderId)) {
      newSelected.delete(orderId);
    } else {
      newSelected.add(orderId);
    }
    setSelectedOrders(newSelected);
  };

  const handleAddItemsToBox = (orderId, boxIndex) => {
    const box = orderBoxes[orderId][boxIndex];
    setSelectedBoxForItems({ orderId, boxIndex, box });
    setOpenManualAddItemsSlideOverPanel(true);
  };

  const handleUpdateBoxItems = (orderId, boxIndex, updatedItems) => {
    setOrderBoxes((prev) => {
      const updatedBoxes = prev[orderId].map((box, idx) => {
        if (idx === boxIndex) {
          const newItems = updatedItems.map((item) => ({
            ...item,
            packQuantity: item.editQuantity,
          }));

          const itemsWeight = newItems.reduce(
            (sum, item) =>
              sum + (item?.attributes?.weight || 0) * item.packQuantity,
            0,
          );

          return {
            ...box,
            items: newItems,
            totalWeight: (box?.weight || 0) + itemsWeight,
          };
        }
        return box;
      });

      // Rest of the existing code...
      const newPackedQuantities = {};
      updatedBoxes.forEach((box) => {
        box.items.forEach((item) => {
          newPackedQuantities[item.uniqueId] =
            (newPackedQuantities[item.uniqueId] || 0) + item.packQuantity;
        });
      });

      setPackedQuantities((prevPacked) => ({
        ...prevPacked,
        [orderId]: newPackedQuantities,
      }));

      setAssignedQuantities((prevAssigned) => ({
        ...prevAssigned,
        [orderId]: newPackedQuantities,
      }));

      return { ...prev, [orderId]: updatedBoxes };
    });

    notify.success(
      "Box Items Updated",
      `Successfully updated items in Box #${boxIndex + 1} for Order #${orderId}`,
    );
  };

  const handleConfirmAddItems = (quantities) => {
    if (!quantities) {
      setOpenManualAddItemsSlideOverPanel(false);
      setSelectedBoxForItems(null);
      return;
    }

    const { orderId, box, boxIndex } = selectedBoxForItems;
    const order = detailedOrdersForBatching.find((o) => o.orderId === orderId);

    setOrderBoxes((prev) => {
      return {
        ...prev,
        [orderId]: prev[orderId].map((b, idx) => {
          if (idx === boxIndex) {
            // Create a map of existing items in the box using uniqueId
            const existingItemsMap = new Map();
            b.items.forEach((item) => {
              existingItemsMap.set(item.uniqueId, { ...item });
            });

            // Iterate over the new quantities to add/update items in the box
            Object.keys(quantities).forEach((uniqueId) => {
              const [productId, index] = uniqueId.split("_");
              const item = order.orderDetails.find(
                (i, idx) =>
                  i.productId === productId && idx === parseInt(index),
              );
              if (!item) return;

              const packQuantity = Math.min(
                quantities[uniqueId] || 0,
                item.quantity - (assignedQuantities[orderId]?.[uniqueId] || 0),
              );

              if (packQuantity > 0) {
                if (existingItemsMap.has(uniqueId)) {
                  existingItemsMap.get(uniqueId).packQuantity += packQuantity;
                } else {
                  existingItemsMap.set(uniqueId, {
                    ...item,
                    uniqueId,
                    packQuantity,
                  });
                }
              }
            });

            // Convert map back to array
            const updatedItems = Array.from(existingItemsMap.values());

            // Recalculate the total weight
            const itemsWeight = updatedItems.reduce(
              (sum, item) =>
                sum + (item?.attributes?.weight || 0) * item.packQuantity,
              0,
            );

            return {
              ...b,
              items: updatedItems,
              totalWeight: (b.totalWeight || 0) + itemsWeight,
            };
          }
          return b;
        }),
      };
    });

    // Update assignedQuantities and packedQuantities using uniqueId
    const newAssignedQuantities = { ...(assignedQuantities[orderId] || {}) };
    Object.keys(quantities).forEach((uniqueId) => {
      newAssignedQuantities[uniqueId] =
        (newAssignedQuantities[uniqueId] || 0) + quantities[uniqueId];
    });

    setAssignedQuantities((prev) => ({
      ...prev,
      [orderId]: newAssignedQuantities,
    }));

    setPackedQuantities((prev) => ({
      ...prev,
      [orderId]: newAssignedQuantities,
    }));

    setOpenManualAddItemsSlideOverPanel(false);
    setSelectedBoxForItems(null);

    notify.success(
      "Items Added to Box",
      `Successfully added items to ${box.displayName} in Order #${orderId}`,
    );
  };

  const addBoxWithAllItems = (orderId, boxType) => {
    const { name, displayName } = getNextBoxName(
      orderId,
      boxType.name.replace(" ", ""),
    );
    const order = detailedOrdersForBatching.find((o) => o.orderId === orderId);

    const items = order.orderDetails.map((item, index) => ({
      ...item,
      uniqueId: `${item.productId}_${index}`,
      packQuantity: item.quantity,
    }));

    const itemsWeight = items.reduce(
      (sum, item) => sum + (item?.attributes?.weight || 0) * item.packQuantity,
      0,
    );

    const boxWithItems = {
      ...boxType,
      name,
      type: boxType.name || "",
      displayName,
      items,
      weight: boxType?.weight || 0,
      totalWeight: (boxType?.weight || 0) + itemsWeight,
    };

    setOrderBoxes((prev) => ({
      ...prev,
      [orderId]: [...(prev[orderId] || []), boxWithItems],
    }));

    // Update assignedQuantities and packedQuantities using uniqueId
    const newAssignedQuantities = {};
    order.orderDetails.forEach((item, index) => {
      const uniqueId = `${item.productId}_${index}`;
      newAssignedQuantities[uniqueId] = item.quantity;
    });

    setAssignedQuantities((prev) => ({
      ...prev,
      [orderId]: newAssignedQuantities,
    }));

    setPackedQuantities((prev) => ({
      ...prev,
      [orderId]: newAssignedQuantities,
    }));

    notify.success(
      "Box Added Successfully",
      `Added ${boxType.name} with all items to Order #${orderId}`,
    );
  };

  const addEmptyBox = (orderId, boxType) => {
    const { name, displayName } = getNextBoxName(
      orderId,
      boxType.name.replace(" ", ""),
    );
    const boxWithName = {
      ...boxType,
      name,
      displayName,
      type: boxType.name || "",
      items: [],
      weight: boxType?.weight || 0,
      totalWeight: boxType?.weight || 0,
    };

    setOrderBoxes((prev) => ({
      ...prev,
      [orderId]: [...(prev[orderId] || []), boxWithName],
    }));

    notify.success(
      "Box Added Successfully",
      `Added empty ${boxType.name} to Order #${orderId}`,
    );
  };

  // Expand orders after adding box(s)
  const expandOrderOnAddingBox = (orderId) => {
    const orderIdExists = expandedOrderIds.includes(orderId);

    if (!orderIdExists) {
      setExpandedOrderIds((prev) => [...prev, orderId]);
    }
  };

  const handleAddBox = (orderId, boxType) => {
    const order = detailedOrdersForBatching.find((o) => o.orderId === orderId);

    // Check if all items in the order are already packed
    if (isOrderFullyPacked(order, packedQuantities)) {
      notify.info(
        "All Items Packed",
        "All items in this order have already been packed. No more boxes needed.",
      );
      return;
    }
    // Check if this is the first box for this order
    if (!orderBoxes[orderId] || orderBoxes[orderId].length === 0) {
      appState.showNewConfirmation(
        "Will all Items inside the order fit into 1 box?",
        "",
        () => {
          // If no, just add an empty box
          addEmptyBox(orderId, boxType);
        },
        () => {
          // If yes, add the box and all items
          addBoxWithAllItems(orderId, boxType);
        },
        "Yes, all items can be added into one box",
        "No, Multiple Boxes will be needed",
      );
    } else {
      addEmptyBox(orderId, boxType);
    }

    expandOrderOnAddingBox(orderId);
  };

  const handleAddBoxToSelected = (boxType) => {
    const allSelectedOrdersFullyPacked = Array.from(selectedOrders).every(
      (orderId) =>
        isOrderFullyPacked(
          detailedOrdersForBatching.find((order) => order.orderId === orderId),
          packedQuantities,
        ),
    );

    if (allSelectedOrdersFullyPacked) {
      notify.info(
        "All Items Packed",
        "All items in selected orders have already been packed. No more boxes needed.",
      );
      return;
    }

    if (boxType.isCustom) {
      setSelectedOrderForCustomBox(null);
      setBoxDimensionsDetails({
        length: "",
        width: "",
        height: "",
      });
      setIsBoxDimensionsModalOpen(true);
      return;
    }

    const ordersToProcess = Array.from(selectedOrders).filter(
      (orderId) =>
        !isOrderFullyPacked(
          detailedOrdersForBatching.find((order) => order.orderId === orderId),
          packedQuantities,
        ),
    );

    if (ordersToProcess.length === 0) {
      return;
    }

    // Check if any selected order already has boxes
    const hasExistingBoxes = Array.from(selectedOrders).some(
      (orderId) => orderBoxes[orderId]?.length > 0,
    );

    if (!hasExistingBoxes) {
      appState.showNewConfirmation(
        "Will all Items inside the order fit into 1 box?",
        "",
        () => {
          // If no, add empty boxes
          ordersToProcess.forEach((orderId) => addEmptyBox(orderId, boxType));
        },
        () => {
          // If yes, add boxes with all items
          ordersToProcess.forEach((orderId) =>
            addBoxWithAllItems(orderId, boxType),
          );
        },
        "Yes, all items can be added into one box",
        "No, Multiple Boxes will be needed",
      );
    } else {
      // If any order already has boxes, just add empty boxes to all selected orders
      ordersToProcess.forEach((orderId) => addEmptyBox(orderId, boxType));
    }

    ordersToProcess.forEach((orderId) => expandOrderOnAddingBox(orderId));
  };

  const performDuplication = (quantity) => {
    const { orderId, boxIndex, items, maxDuplicates } = duplicationDetails;
    const originalBox = orderBoxes[orderId][boxIndex];

    const actualQuantity = Math.min(quantity, maxDuplicates);

    const newBoxes = Array.from({ length: actualQuantity }, (_, index) => {
      const { name, displayName } = getNextBoxName(orderId);
      return { ...originalBox, name, displayName, items };
    });

    setOrderBoxes((prev) => ({
      ...prev,
      [orderId]: [...prev[orderId], ...newBoxes],
    }));

    // Update assignedQuantities and packedQuantities
    const newAssignedQuantities = { ...(assignedQuantities[orderId] || {}) };
    newBoxes.forEach((box) => {
      box.items.forEach((item) => {
        newAssignedQuantities[item.uniqueId] =
          (newAssignedQuantities[item.uniqueId] || 0) + item.packQuantity;
      });
    });

    setAssignedQuantities((prev) => ({
      ...prev,
      [orderId]: newAssignedQuantities,
    }));

    setPackedQuantities((prev) => ({
      ...prev,
      [orderId]: newAssignedQuantities,
    }));

    renumberBoxes(orderId);

    notify.success(
      "Boxes Duplicated",
      `Duplicated ${actualQuantity} box${actualQuantity > 1 ? "es" : ""} in Order #${orderId}.`,
    );

    setDuplicationModalOpen(false);
  };

  const handleDuplicateBox = (orderId, boxIndex) => {
    const originalBox = orderBoxes[orderId][boxIndex];
    const maxDuplicates = calculateMaxDuplicates(orderId, originalBox.items);

    if (maxDuplicates === 0) {
      notify.info(
        "Box Duplication Not Possible",
        "There are not enough available quantities to duplicate this box.",
      );
      return;
    }

    setDuplicationDetails({
      orderId,
      boxIndex,
      quantity: 1,
      items: originalBox.items,
      maxDuplicates,
    });
    setDuplicationModalOpen(true);
  };
  const handleBoxTypeSelection = (orderId, boxIndex, boxType) => {
    if (boxType.isCustom) {
      handleEditDimensions(orderId, boxIndex);
    } else {
      setOrderBoxes((prev) => ({
        ...prev,
        [orderId]: prev[orderId].map((box, index) =>
          index === boxIndex
            ? { ...box, ...boxType, type: boxType.name || "", name: box.name }
            : box,
        ),
      }));
      notify.success(
        "Box Updated",
        `Updated box type for Order #${orderId}, Box #${boxIndex + 1} to ${boxType.name}`,
      );
    }
  };

  const isOrderFullyPacked = (order, packedQuantities) => {
    if (!order || !packedQuantities) return false;

    const orderPackedQuantities = packedQuantities[order.orderId] || {};

    return order.orderDetails.every((item) => {
      const uniqueId =
        item.uniqueId || `${item.productId}_${item.orderDetailsId || 0}`;
      return (orderPackedQuantities[uniqueId] || 0) >= item.quantity;
    });
  };

  const handleDimensionsSubmit = () => {
    const errors = {};
    let hasErrors = false;

    Object.entries(boxDimensionsDetails).forEach(([dim, value]) => {
      if (value === "" || value < 0) {
        errors[dim] =
          `${dim.charAt(0).toUpperCase() + dim.slice(1)} must be greater than or equal to 0`;
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setDimensionErrors(errors);
      return;
    }

    const customBox = {
      ...boxDimensionsDetails,
      name: "Custom Box",
      isCustom: true,
      type: "Custom Box",
    };

    if (selectedOrderForCustomBox?.includes("::")) {
      const [orderId, boxIndex] = selectedOrderForCustomBox.split("::");
      setOrderBoxes((prev) => ({
        ...prev,
        [orderId]: prev[orderId]?.map((box, index) => {
          if (index === parseInt(boxIndex)) {
            const itemsWeight = box.items.reduce(
              (sum, item) =>
                sum + (item?.attributes?.weight || 0) * item.packQuantity,
              0,
            );
            return {
              ...box,
              ...customBox,
              totalWeight: (customBox?.weight || 0) + itemsWeight,
            };
          }
          return box;
        }),
      }));

      notify.success(
        "Box Updated",
        `Updated box dimensions for Order #${orderId}, Box #${parseInt(boxIndex) + 1}`,
      );
    } else if (selectedOrderForCustomBox) {
      addEmptyBox(selectedOrderForCustomBox, customBox);
    } else {
      selectedOrders.forEach((orderId) => {
        addEmptyBox(orderId, customBox);
      });
    }

    // Reset and close modal
    setIsBoxDimensionsModalOpen(false);
    setBoxDimensionsDetails({
      length: "",
      width: "",
      height: "",
      weight: 0,
    });
    setSelectedOrderForCustomBox(null);
  };

  const calculateMaxDuplicates = (orderId, boxItems) => {
    const order = detailedOrdersForBatching.find((o) => o.orderId === orderId);
    const currentAssignedQuantities = assignedQuantities[orderId] || {};

    return Math.min(
      ...boxItems.map((item) => {
        const orderItem = order.orderDetails.find(
          (i) => i.uniqueId === item.uniqueId,
        );
        const availableQuantity =
          orderItem.quantity - (currentAssignedQuantities[item.uniqueId] || 0);
        return Math.floor(availableQuantity / item.packQuantity);
      }),
    );
  };

  const handleDeleteBox = (orderId, boxIndex) => {
    setOrderBoxes((prev) => {
      const boxToDelete = prev[orderId][boxIndex];
      const updatedBoxes = prev[orderId].filter(
        (_, index) => index !== boxIndex,
      );

      // Update assignedQuantities and packedQuantities
      const newAssignedQuantities = { ...assignedQuantities[orderId] };
      boxToDelete.items.forEach((item) => {
        newAssignedQuantities[item.uniqueId] = Math.max(
          0,
          (newAssignedQuantities[item.uniqueId] || 0) - item.packQuantity,
        );
      });

      setAssignedQuantities((prev) => ({
        ...prev,
        [orderId]: newAssignedQuantities,
      }));

      setPackedQuantities((prev) => ({
        ...prev,
        [orderId]: newAssignedQuantities,
      }));

      notify.warning(
        "Box Removed",
        `Removed ${boxToDelete.isPrePackaged ? "Pre-packaged " : ""}Box ${boxIndex + 1} from Order #${orderId}`,
      );

      return {
        ...prev,
        [orderId]: updatedBoxes,
      };
    });

    // Renumber the boxes after deletion
    renumberBoxes(orderId);
  };

  const isAllItemsPacked = () => {
    return detailedOrdersForBatching.every((order) => {
      const orderPackedQuantities = packedQuantities[order.orderId] || {};
      return order.orderDetails.every(
        (item) => (orderPackedQuantities[item.uniqueId] || 0) === item.quantity,
      );
    });
  };

  const handleErasePlan = () => {
    // If there's only one order, reset it directly without checking selection
    if (detailedOrdersForBatching.length === 1) {
      const orderId = detailedOrdersForBatching[0].orderId;

      appState.showNewConfirmation(
        "Reset Packing Plan",
        "Are you sure you want to reset the packing plan for this order? This action cannot be undone.",
        () => {
          // Reset the single order
          setOrderBoxes((prev) => {
            const newOrderBoxes = { ...prev };
            delete newOrderBoxes[orderId];
            return newOrderBoxes;
          });

          setAssignedQuantities((prev) => {
            const newAssignedQuantities = { ...prev };
            delete newAssignedQuantities[orderId];
            return newAssignedQuantities;
          });

          setPackedQuantities((prev) => {
            const newPackedQuantities = { ...prev };
            delete newPackedQuantities[orderId];
            return newPackedQuantities;
          });

          setBoxCounts((prev) => {
            const newBoxCounts = { ...prev };
            delete newBoxCounts[orderId];
            return newBoxCounts;
          });

          notify.success(
            "Plan Erased",
            "All boxes have been removed from the order.",
          );
        },
        () => {
          // This function will be called if the user clicks "No"
          // We don't need to do anything in this case
        },
        "No",
        "Yes, Reset Plan",
      );
      return;
    }

    // Original logic for multiple orders
    if (selectedOrders.size === 0) {
      notify.warning(
        "No Orders Selected",
        "Please select at least one order to reset the plan.",
      );
      return;
    }

    const orderText =
      selectedOrders.size === 1
        ? "this order"
        : `these ${selectedOrders.size} orders`;

    appState.showNewConfirmation(
      "Reset Packing Plan",
      `Are you sure you want to reset the packing plan for ${orderText}? This action cannot be undone.`,
      confirmErasePlan,
      () => {
        // This function will be called if the user clicks "No"
        // We don't need to do anything in this case
      },
      "No",
      "Yes, Reset Plan",
    );
  };

  const confirmErasePlan = () => {
    setOrderBoxes((prev) => {
      const newOrderBoxes = { ...prev };
      selectedOrders.forEach((orderId) => {
        delete newOrderBoxes[orderId];
      });
      return newOrderBoxes;
    });

    setAssignedQuantities((prev) => {
      const newAssignedQuantities = { ...prev };
      selectedOrders.forEach((orderId) => {
        delete newAssignedQuantities[orderId];
      });
      return newAssignedQuantities;
    });

    setPackedQuantities((prev) => {
      const newPackedQuantities = { ...prev };
      selectedOrders.forEach((orderId) => {
        delete newPackedQuantities[orderId];
      });
      return newPackedQuantities;
    });

    setBoxCounts((prev) => {
      const newBoxCounts = { ...prev };
      selectedOrders.forEach((orderId) => {
        delete newBoxCounts[orderId];
      });
      return newBoxCounts;
    });

    const messageText =
      selectedOrders.size === 1
        ? "the selected order"
        : `${selectedOrders.size} selected orders`;

    notify.success(
      "Plan Erased",
      `All boxes have been removed from ${messageText}.`,
    );
  };

  const handleCancelPlan = () => {
    // Here you might want to clear the current packing plan or navigate away
    notify.info(
      "Packing Plan Cancelled",
      "The packing plan has been cancelled.",
    );
  };

  // Helper function to find prepackaged items in an order
  const findPrePackagedItems = (order, currentPackedQuantities) => {
    if (!order.orderDetails || !order.orderId) return [];

    return order.orderDetails.filter((item) => {
      if (!item?.attributes?.prePackaged) return false;

      const uniqueId =
        item.uniqueId || `${item.productId}_${item.orderDetailsId || 0}`;
      const packedQty = currentPackedQuantities[uniqueId] || 0;

      return packedQty < item.quantity; // Only count items that need packing
    });
  };

  // Helper function to count unpacked items
  const countUnpackedItems = (prePackagedItems, currentPackedQuantities) => {
    return prePackagedItems.reduce((total, item) => {
      const uniqueId =
        item.uniqueId || `${item.productId}_${item.orderDetailsId || 0}`;
      const packedQty = currentPackedQuantities[uniqueId] || 0;
      const remaining = item.quantity - packedQty;
      return total + remaining;
    }, 0);
  };

  // Function to create a box for a prepackaged item
  const createPrePackagedBox = (orderId, item, boxNum, packedQty) => {
    const uniqueId =
      item.uniqueId || `${item.productId}_${item.orderDetailsId || 0}`;
    const displayName = `${item.sku}_${boxNum}`;
    const name = `${orderId}_${displayName}`;

    return {
      name,
      displayName,
      type: item.sku,
      length: Number(item?.attributes?.length || 0),
      width: Number(item?.attributes?.width || 0),
      height: Number(item?.attributes?.height || 0),
      weight: Number(item?.attributes?.weight || 0),
      totalWeight: Number(item?.attributes?.weight || 0),
      items: [
        {
          ...item,
          packQuantity: 1,
        },
      ],
      isPrePackaged: true,
    };
  };

  // Function to process and create boxes for prepackaged items
  const processPrePackagedOrders = (ordersWithPrePackaged) => {
    const allPrePackagedBoxes = {};
    const allNewQuantities = {};

    // First, collect all changes we need to make
    ordersWithPrePackaged.forEach((orderId) => {
      const order = detailedOrdersForBatching.find(
        (o) => o.orderId === orderId,
      );
      if (!order) return;

      const currentPackedQuantities = packedQuantities[orderId] || {};
      const prePackagedItems = findPrePackagedItems(
        order,
        currentPackedQuantities,
      );
      const prePackagedBoxes = [];
      const newQuantities = {};

      // Create boxes for each prepackaged item
      prePackagedItems.forEach((item) => {
        const uniqueId =
          item.uniqueId || `${item.productId}_${item.orderDetailsId || 0}`;
        const packedQty = currentPackedQuantities[uniqueId] || 0;
        const remainingQty = item.quantity - packedQty;

        // Create a box for each remaining quantity
        for (let i = 0; i < remainingQty; i++) {
          const boxNum = packedQty + i + 1;
          const newBox = createPrePackagedBox(orderId, item, boxNum, packedQty);

          prePackagedBoxes.push(newBox);

          // Track quantities
          newQuantities[uniqueId] = (newQuantities[uniqueId] || 0) + 1;
        }
      });

      if (prePackagedBoxes.length > 0) {
        allPrePackagedBoxes[orderId] = prePackagedBoxes;
        allNewQuantities[orderId] = newQuantities;
      }
    });

    return { allPrePackagedBoxes, allNewQuantities };
  };

  // Function to apply changes to state
  const applyPrePackagedBoxes = (allPrePackagedBoxes, allNewQuantities) => {
    if (Object.keys(allPrePackagedBoxes).length === 0) return;

    // Update orderBoxes
    setOrderBoxes((prev) => {
      const newOrderBoxes = { ...prev };

      Object.entries(allPrePackagedBoxes).forEach(([orderId, boxes]) => {
        newOrderBoxes[orderId] = [...(newOrderBoxes[orderId] || []), ...boxes];
      });

      return newOrderBoxes;
    });

    // Update assignedQuantities and packedQuantities
    setAssignedQuantities((prev) => {
      const newAssignedQuantities = { ...prev };

      Object.entries(allNewQuantities).forEach(([orderId, quantities]) => {
        newAssignedQuantities[orderId] = {
          ...(newAssignedQuantities[orderId] || {}),
          ...quantities,
        };
      });

      return newAssignedQuantities;
    });

    setPackedQuantities((prev) => {
      const newPackedQuantities = { ...prev };

      Object.entries(allNewQuantities).forEach(([orderId, quantities]) => {
        newPackedQuantities[orderId] = {
          ...(newPackedQuantities[orderId] || {}),
          ...quantities,
        };
      });

      return newPackedQuantities;
    });

    // Auto-expand orders that had changes
    setExpandedOrderIds((prev) => {
      const newExpandedIds = [...prev];
      Object.keys(allPrePackagedBoxes).forEach((orderId) => {
        if (!newExpandedIds.includes(orderId)) {
          newExpandedIds.push(orderId);
        }
      });
      return newExpandedIds;
    });

    // Show success notification
    notify.success(
      "Pre-packaged Boxes Created",
      `Successfully created boxes for pre-packaged items in ${Object.keys(allPrePackagedBoxes).length} order${Object.keys(allPrePackagedBoxes).length > 1 ? "s" : ""}.`,
    );
  };

  // Main function to analyze and handle prepackaged items
  const analyzePrePackagedItems = () => {
    const ordersWithPrePackaged = [];
    const prePackagedItemCounts = {};
    let totalPrePackagedItemCount = 0;

    detailedOrdersForBatching.forEach((order) => {
      if (!order.orderDetails || !order.orderId) return;

      // Skip orders that already have boxes assigned
      if (orderBoxes[order.orderId] && orderBoxes[order.orderId].length > 0) {
        return;
      }

      const currentPackedQuantities = packedQuantities[order.orderId] || {};
      const prePackagedItems = findPrePackagedItems(
        order,
        currentPackedQuantities,
      );

      if (prePackagedItems && prePackagedItems.length > 0) {
        const unpacked = countUnpackedItems(
          prePackagedItems,
          currentPackedQuantities,
        );

        if (unpacked > 0) {
          ordersWithPrePackaged.push(order.orderId);
          prePackagedItemCounts[order.orderId] = unpacked;
          totalPrePackagedItemCount += unpacked;
        }
      }
    });

    return {
      ordersWithPrePackaged,
      prePackagedItemCounts,
      totalPrePackagedItemCount,
    };
  };

  // Main useEffect for prepackaged items detection
  useEffect(() => {
    const {
      ordersWithPrePackaged,
      prePackagedItemCounts,
      totalPrePackagedItemCount,
    } = analyzePrePackagedItems();

    // If there are prepackaged items, ask for confirmation
    if (totalPrePackagedItemCount > 0) {
      let confirmationMessage = "";

      if (ordersWithPrePackaged.length === 1) {
        const count = prePackagedItemCounts[ordersWithPrePackaged[0]];
        const orderNumber = ordersWithPrePackaged[0];
        confirmationMessage = `Order #${orderNumber} contains ${count} pre-packaged item${count > 1 ? "s" : ""}. Would you like to automatically create separate boxes for these pre-packaged items?`;
      } else {
        confirmationMessage = `${ordersWithPrePackaged.length} orders contain a total of ${totalPrePackagedItemCount} pre-packaged items. Would you like to automatically create separate boxes for these pre-packaged items?`;
      }

      // Show confirmation dialog
      appState.showNewConfirmation(
        "Pre-packaged Items Detected",
        <div className="font-inter">{confirmationMessage}</div>,
        () => {
          // User clicked "No" - do nothing
          const { allPrePackagedBoxes, allNewQuantities } =
            processPrePackagedOrders(ordersWithPrePackaged);
          applyPrePackagedBoxes(allPrePackagedBoxes, allNewQuantities);
        },
        () => {
          // User clicked "Yes" - create prepackaged boxes
        },
        "No",
        "Yes, Create Boxes",
      );
    }
  }, [detailedOrdersForBatching]);

  return (
    <div className="w-full pb-20 font-inter">
      {detailedOrdersForBatching?.length === 1 ? (
        <div className="mb-6 flex items-center justify-end">
          <PrimaryButton
            height="2.5rem"
            minWidth="7rem"
            maxWidth="20rem"
            variant="primary"
            className="text-base font-medium"
            danger
            onClick={handleErasePlan}>
            <TrashIcon className="mr-2 h-4 w-4" />
            <span>Reset Plan</span>
          </PrimaryButton>
        </div>
      ) : (
        <div className="mb-6 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="text-gray-500">
              {selectedOrders.size} of {detailedOrdersForBatching.length} orders
              selected
            </span>
          </div>

          <div className="flex gap-3">
            <PrimaryButton
              height="2.5rem"
              minWidth="13rem"
              maxWidth="20rem"
              variant="secondary"
              className="mt-2 text-base font-medium"
              onClick={handleSelectAll}>
              Select All Orders
            </PrimaryButton>

            <BoxTypePopover
              onSelectBox={handleAddBoxToSelected}
              isOpen={isBoxPopoverOpen}
              setIsOpen={setIsBoxPopoverOpen}
              boxTypes={boxTypes}
              trigger={
                <PrimaryButton
                  height="2.5rem"
                  minWidth="13rem"
                  maxWidth="20rem"
                  variant="primary"
                  className={`mt-2 border-none text-base font-medium ${
                    selectedOrders.size === 0
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  }`}
                  disabled={
                    selectedOrders.size === 0 ||
                    Array.from(selectedOrders).every((orderId) =>
                      isOrderFullyPacked(
                        detailedOrdersForBatching.find(
                          (order) => order.orderId === orderId,
                        ),
                        packedQuantities,
                      ),
                    )
                  }>
                  <PlusIcon className="mr-2 h-4 w-4" />
                  Add Box to Selected
                  <ChevronDownIcon className="ml-1 h-4 w-4" />
                </PrimaryButton>
              }
            />

            <PrimaryButton
              height="2.5rem"
              minWidth="7rem"
              maxWidth="20rem"
              variant="primary"
              className="mt-2 text-base font-medium"
              danger
              onClick={handleErasePlan}>
              <TrashIcon className="mr-2 h-4 w-4" />
              <span>Reset Plan</span>
            </PrimaryButton>
          </div>
        </div>
      )}

      <SlideOverPanel
        open={openManualAddItemsSlideOverPanel}
        containerStyle="max-w-6xl"
        setOpen={setOpenManualAddItemsSlideOverPanel}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle={
              "Add Items to " +
              selectedBoxForItems?.box?.displayName +
              " from order #" +
              selectedBoxForItems?.orderId
            }
            description="Select the required items and enter their quantities to pack into this box. Ensure the quantities match the available stock to maintain accuracy and avoid discrepancies."
            isLearnMore={false}
            arrowAction={() => setOpenManualAddItemsSlideOverPanel(false)}
            isArrow
            mainClasses="mb-0"
          />
        }>
        {selectedBoxForItems && (
          <BoxItemsTable
            items={
              detailedOrdersForBatching.find(
                (o) => o.orderId === selectedBoxForItems.orderId,
              ).orderDetails
            }
            assignedQuantities={
              assignedQuantities[selectedBoxForItems.orderId] || {}
            }
            onConfirm={handleConfirmAddItems}
          />
        )}
      </SlideOverPanel>
      <NewModal
        isOpen={isBoxDimensionsModalOpen}
        onClose={() => {
          setIsBoxDimensionsModalOpen(false);
          setDimensionErrors({
            length: "",
            width: "",
            height: "",
          });
        }}
        title="Enter Custom Box Dimensions"
        maxHeight="600px">
        <div className="py-4 font-inter">
          <div className="flex flex-col gap-4">
            {["length", "width", "height", "weight"].map((dim) => (
              <div key={dim} className="flex flex-col">
                <div className="flex items-center">
                  <label className="w-24 font-medium">
                    {dim.charAt(0).toUpperCase() + dim.slice(1)}{" "}
                    {dim === "weight" ? "(lbs):" : "(in):"}
                  </label>
                  <input
                    type="number"
                    min="0"
                    step={dim === "weight" ? "0.1" : "1"}
                    className={`${dimensionErrors[dim] ? "border-red-500" : "border-gray-300"} ml-2 w-24 rounded-md border px-2 py-1`}
                    value={boxDimensionsDetails[dim]}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value < 0) {
                        setDimensionErrors((prev) => ({
                          ...prev,
                          [dim]: `${dim.charAt(0).toUpperCase() + dim.slice(1)} cannot be negative`,
                        }));
                        return;
                      }
                      setDimensionErrors((prev) => ({
                        ...prev,
                        [dim]: "",
                      }));
                      setBoxDimensionsDetails((prev) => ({
                        ...prev,
                        [dim]: value,
                      }));
                    }}
                  />
                </div>
                {dimensionErrors[dim] && (
                  <span className="ml-26 mt-1 text-sm text-red-500">
                    {dimensionErrors[dim]}
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className="mt-6 flex justify-end gap-4">
            <PrimaryButton
              height="2.5rem"
              width="7rem"
              variant="secondary"
              onClick={() => {
                setIsBoxDimensionsModalOpen(false);
                setDimensionErrors({
                  length: "",
                  width: "",
                  height: "",
                });
              }}>
              Cancel
            </PrimaryButton>
            <PrimaryButton
              height="2.5rem"
              width="7rem"
              variant="primary"
              onClick={handleDimensionsSubmit}>
              Confirm
            </PrimaryButton>
          </div>
        </div>
      </NewModal>

      <BoxDuplicationModal
        isOpen={duplicationModalOpen}
        onClose={() => setDuplicationModalOpen(false)}
        onConfirm={performDuplication}
        maxDuplicates={duplicationDetails.maxDuplicates}
      />

      <div className="space-y-4">
        {detailedOrdersForBatching.map((order) => (
          <PlannedOrder
            key={order.orderId}
            order={order}
            showCheckBox={detailedOrdersForBatching.length > 1}
            isSelected={selectedOrders.has(order.orderId)}
            onSelect={handleSelectOrder}
            onAddBox={handleAddBox}
            boxes={orderBoxes[order.orderId] || []}
            onDuplicateBox={handleDuplicateBox}
            onDeleteBox={handleDeleteBox}
            isExpanded={expandedOrderIds.includes(order.orderId)}
            onToggle={() => handleOrderToggle(order.orderId)}
            expandedBoxId={expandedBoxId}
            onBoxToggle={handleBoxToggle}
            setBoxDimensionsDetails={setBoxDimensionsDetails}
            setDimensionErrors={setDimensionErrors}
            setIsBoxDimensionsModalOpen={setIsBoxDimensionsModalOpen}
            setSelectedOrderForCustomBox={setSelectedOrderForCustomBox}
            handleAddBoxToSelected={handleAddBoxToSelected}
            onAddItems={handleAddItemsToBox}
            packedQuantities={packedQuantities[order.orderId] || {}}
            onEditDimensions={handleEditDimensions}
            onUpdateWeight={handleUpdateWeight}
            onSelectBoxType={handleBoxTypeSelection}
            onUpdateItems={handleUpdateBoxItems}
            customers={customers}
            warehouses={warehouses}
            boxTypes={boxTypes}
            isFullyPacked={isOrderFullyPacked(order, packedQuantities)}
          />
        ))}
      </div>

      {/* <div className="fixed bottom-0 left-0 right-0 border-t border-gray-200 bg-white p-4">
        <div className="mx-auto flex max-w-4xl justify-end gap-4">
          <PrimaryButton
            height="2.5rem"
            width="7rem"
            variant="secondary"
            onClick={handleCancelPlan}>
            Cancel
          </PrimaryButton>
          <PrimaryButton
            height="2.5rem"
            width="7rem"
            variant="primary"
            onClick={handleConfirmPlan}
            disabled={!isAllItemsPacked()}>
            Confirm Plan
          </PrimaryButton>
        </div>
      </div> */}
    </div>
  );
};

export default PackPlan;
